import React, { useContext, useEffect, useRef, useState } from "react";
import MapTopBooked from "./Map/Map";
import axiosInstance from "../../../axiosInstance";
import { useSelector } from "react-redux";
import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";
import TopBookedProgressBars from "./TopBookedProgressBars/TopBookedProgressBars";
import DateRangeContext from "../../../contexts/Dashboard/DateRange";

const TopBookedCountries = ({open}) => {
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateRangeContext);
  const [loading, setLoading] = useState(false);
  const [locationDatas, setLocationDatas] = useState([]);
  const [hoveredLocation, setHoveredLocation] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [bookingDetails, setBookingDetails] = useState({
    display: false,
    details: null,
  });
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const mapRef = useRef(null);

  const getLocations = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    setLoading(true);
    try {
      const formattedStartDate = startDate.format("YYYY-MM-DD");
      const formattedEndDate = endDate.format("YYYY-MM-DD");
      const response = await axiosInstance.get(
        `dashboard/top_booked_countries?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor,
            Accounts: selectedBranchesString,
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        }
      );
      // setLocationDatas(response.data.nationalities);
      const filteredData = response.data.nationalities.filter(
        (nationality) => Object.keys(nationality.location).length !== 0
      );
      setLocationDatas(filteredData);
      
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error sending data:", error.response);
    }
  };

  useEffect(() => {
    getLocations();
  }, [startDate, endDate, selectedBranchesString]);

  useEffect(() => {
    setHoveredLocation(null);
    setSelectedLocation(null);
  }, [lightMode]);
  return (
    <Box sx={{ width: "100%" }}>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "1.5rem 1rem",
        }}
      >
        {!loading ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "start",
                  position: "sticky",
                  top: "0",
                  left: "0",
                  zIndex: "99",
                  background: lightMode ? "#ffffff" : "#343434",
                }}
                variant="h6"
                color="primary"
              >
                Sales Based on Countries
              </Typography>
            </Grid>

            <Grid item xs={12} lg={7}>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MapTopBooked
                  mapRef={mapRef}
                  locationDatas={locationDatas}
                  hoveredLocation={hoveredLocation}
                  setHoveredLocation={setHoveredLocation}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                  open={open}
                />
              </Card>
            </Grid>

            <Grid item xs={12} lg={5}>
              <Box
                sx={{ display: "flex", alignItems: "center", height: "100%" }}
              >
                <Card
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1rem",
                    background: lightMode ? "#fafafa" : "#292929",
                    height: locationDatas?.length ? "auto" : "100%",
                  }}
                >
                  <TopBookedProgressBars
                    locationDatas={locationDatas}
                    hoveredLocation={hoveredLocation}
                    setHoveredLocation={setHoveredLocation}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    bookingDetails={bookingDetails}
                    setBookingDetails={setBookingDetails}
                  />
                </Card>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress sx={{ margin: "auto" }} />
        )}
      </Card>
    </Box>
  );
};

export default TopBookedCountries;
