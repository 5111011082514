import { Box, Button, Link, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SilverBadge from "../../../assets/images/badges/silver.svg";
import PlatinumBadge from "../../../assets/images/badges/platinum.svg";
import GoldBadge from "../../../assets/images/badges/gold.svg";
import BronzeBadge from "../../../assets/images/badges/bronze.svg";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../axiosInstance";
import axios from "axios";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function ProgressBarProfile({ barType, response, setResponse }) {
  const [currentpercentage, setCurrentPercentage] = useState(0);
  const [currentBadge, setCurrentBadge] = useState(null);
  const [targetBadge, setTargetBadge] = useState(null);
  const [spesificIndex, setSpesificIndex] = useState(null);
  const [spesificIndex2, setSpesificIndex2] = useState(null);

  const profile = useSelector((state) => state.profile);
  const [dataShow, setDataShow] = useState({
    control: false,
    item: null,
  });
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const [loading, setLoading] = useState(false);
  const getBadgeDetails = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}dashboard/badges`;

    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor,
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      setCurrentPercentage(response.data.target_badges[0].percentage);
      setCurrentBadge(response.data.current_badge);
      setTargetBadge(response.data.target_badges);
      setResponse(response.data);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled:", error.message);
      } else {
        console.error("API isteği sırasında hata oluştu:", error);
      }
    }
  };

  useEffect(() => {
    getBadgeDetails();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (response && response.target_badges.length) {
      const mybadge = badges?.find((badge, idx) => {
        return (
          badge?.name?.toLocaleLowerCase() ===
          response?.current_badge?.toLocaleLowerCase()
        );
      });
      const index = badges[mybadge?.id - 1]?.id - 1;

      setSpesificIndex(index);

      const mybadge2 = badges?.find((badge, idx) => {
        return (
          badge?.name?.toLocaleLowerCase() ===
          response?.target_badges[0].badge?.toLocaleLowerCase()
        );
      });
      const index2 = mybadge2?.id - 1;
      setSpesificIndex2(index2);
    }
  }, [response]);
  const badges = [
    {
      id: 1,
      name: "Bronze",
      percentage: "0%",
      bg: "green",
      icon: BronzeBadge,
      control: true,
    },
    {
      id: 2,
      name: "Silver",
      percentage: "33%",
      bg: "red",
      icon: SilverBadge,
      control: true,
    },
    {
      id: 3,
      name: "Gold",
      percentage: "66%",
      bg: "blue",
      icon: GoldBadge,
      control: true,
    },
    {
      id: 4,
      name: "Platinum",
      percentage: "100%",
      bg: "yellow",
      icon: PlatinumBadge,
      control: false,
    },
  ];
  return response ? (
    <Box
      sx={{
        width: "100%",
        cursor: "pointer",
        minHeight: "80px",
        maxWidth: "calc(100% - 72px)",
        left: "24px",
        position: "relative",
      }}
    >
      {currentBadge && targetBadge ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "15px",
                position: "relative",
                background: lightMode ? "#efefef" : "#000000",
                borderRadius: "3rem",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                transition: "width .4s",
                display: "flex",
                flexDirection: "row",
                boxShadow: "inset 0 1px 2px rgba(0,0,0,.1)",
              }}
            >
              {targetBadge
                ? badges?.map((badgeItem, idx) => {
                    return (
                      <>
                        {badgeItem.control && (
                          <Box
                            onMouseEnter={() =>
                              !dataShow.control &&
                              setDataShow({ control: true, item: idx })
                            }
                            onMouseLeave={() =>
                              dataShow.control &&
                              setDataShow({ control: false, item: null })
                            }
                            sx={{
                              width:
                                idx === 0 || idx === 1
                                  ? "calc(33% - 40px)"
                                  : "calc(33% - 30px)",
                              left:
                                idx === 0
                                  ? "40px"
                                  : idx === 1
                                  ? "80px"
                                  : "120px",
                              minHeight: "15px",
                              zIndex: "9",
                              position: "relative",
                              "&::before": {
                                content: '""',
                                boxShadow: "inset 0 1px 2px rgb(0 0 0 / 23%)",
                                position: "absolute",
                                top: 0,
                                left: "0",
                                height: "15px",
                                borderTopLeftRadius: "0",
                                borderBottomLeftRadius: "0",
                                borderTopRightRadius:
                                  badgeItem?.id === spesificIndex2 &&
                                  targetBadge[0].percentage < 100
                                    ? "1rem"
                                    : 0,
                                borderBottomRightRadius:
                                  badgeItem?.id === spesificIndex2 &&
                                  targetBadge[0].percentage < 100
                                    ? "1rem"
                                    : 0,
                                transition: "width .4s",
                                width: `${
                                  idx < spesificIndex
                                    ? "100%"
                                    : badgeItem?.id === spesificIndex2
                                    ? targetBadge[0].percentage > 100
                                      ? 100 + `%`
                                      : targetBadge[0]?.percentage + `%`
                                    : "0%"
                                }`,
                                background:
                                  "linear-gradient(90deg, rgb(108 0 0) 0%, rgb(128 9 9) 35%, rgb(148 0 0) 100%)",
                              },
                            }}
                          >
                            {dataShow.control && dataShow.item === idx ? (
                              <Box
                                sx={{
                                  width: "61px",
                                  height: "40px",
                                  background: "#800000",
                                  borderRadius: "3rem",
                                  zIndex: 9,
                                  left:
                                    idx < spesificIndex
                                      ? `calc(50% - 31px)`
                                      : `calc(${
                                          targetBadge[0]?.percentage > 100
                                            ? 100 / 2
                                            : targetBadge[0]?.percentage / 2
                                        }% - 31px)`,
                                  position: "relative",
                                  bottom: "80px",
                                  display: "flex",
                                  alignItems: "center",
                                  boxShadow: "0 1px 2px black",
                                  zIndex: 9,

                                  "&:before": {
                                    content: '""',
                                    position: "absolute",
                                    bottom: "-30px",
                                    zIndex: 0,
                                    left: "calc(50% - 1px)",
                                    width: "2px",
                                    height: "30px",
                                    borderBottomLeftRadius: "1rem",
                                    borderBottomRightRadius: "1rem",
                                    boxShadow: "0 1px 2px black",
                                    backgroundColor: "#800000",
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    width: "fit-content",
                                    textAlign: "center",
                                    margin: "auto",
                                    color: "#ffffff",
                                  }}
                                >
                                  {idx < spesificIndex
                                    ? "100%"
                                    : badgeItem?.id === spesificIndex2
                                    ? targetBadge[0]?.percentage > 100
                                      ? 100 + `%`
                                      : targetBadge[0]?.percentage + `%`
                                    : "0%"}
                                </Typography>
                              </Box>
                            ) : null}
                          </Box>
                        )}
                        <Tooltip
                          title={badgeItem?.name}
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          {(idx <= spesificIndex && idx !== 3) ||
                          (targetBadge[0].percentage >= 100 &&
                            idx <= spesificIndex + 1) ? (
                            <Box
                              sx={{
                                width: "40px",
                                height: "40px",
                                boxSizing: "content-box",
                                position: "absolute",
                                left:
                                  idx !== 3
                                    ? `calc(${badgeItem?.percentage})`
                                    : `calc(${badgeItem?.percentage} - 10px)`,
                                top: "calc(50% - 20px)",
                                cursor: "pointer",
                                borderRadius: "10rem",
                                boxShadow: "0 0 5px green",
                                zIndex: "9",
                                background: lightMode ? "#ffffff" : "#000000",
                              }}
                            >
                              <img
                                style={{}}
                                width={"40px"}
                                src={badgeItem.icon}
                              />
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "-20px",
                                  right: "-20px",
                                  filter: "drop-shadow(0 0 1px #007e00ab)",
                                }}
                              >
                                <CheckCircleOutlineIcon
                                  sx={{ color: "#007e00ab" }}
                                />
                              </Box>
                            </Box>
                          ) : (
                            <img
                              style={{
                                position: "absolute",
                                left:
                                  idx !== 3
                                    ? `calc(${badgeItem?.percentage})`
                                    : `calc(${badgeItem?.percentage} - 10px)`,
                                top: "calc(50% - 20px)",
                                cursor: "pointer",
                                borderRadius: "10rem",
                                boxShadow:
                                  idx <= spesificIndex ||
                                  (currentBadge === "gold" &&
                                    targetBadge[0].percentage >= 100) ||
                                  currentBadge === "platinum"
                                    ? "0 0 5px green"
                                    : "0 0 5px #919191",
                                zIndex: "9",
                                background: lightMode ? "#ffffff" : "#000000",
                              }}
                              width={"40px"}
                              src={badgeItem.icon}
                            />
                          )}
                        </Tooltip>
                        {(spesificIndex2 === 3 &&
                          spesificIndex2 === badgeItem.id &&
                          targetBadge[0]?.percentage >= 100) ||
                        targetBadge[0].badge === "" ? (
                          <Box
                            sx={{
                              position: "absolute",
                              right: "-30px",
                              bottom: "34px",
                            }}
                          >
                            <img
                              src="https://i.pinimg.com/originals/41/36/16/41361625cc44df07f81a620eac766468.png"
                              width={40}
                              height={40}
                              alt=""
                            />
                          </Box>
                        ) : null}
                      </>
                    );
                  })
                : null}
            </Box>
          </Box>
          <Box sx={{ display: "flex", marginTop: "1rem" }}></Box>
        </>
      ) : null}
    </Box>
  ) : null;
}
