import React, { useContext, useEffect, useRef, useState } from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axiosInstance from "../../../../axiosInstance";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ResidencesContext from "../../../../contexts/Residences/ResidencesContext";

export default function Locations({ moveToLocation }) {
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const {
    locations,
    searchedResidence,
    selectedLocation,
    setSelectedLocation,
    nearByUniversities,
    universityTabControl,
    disabledControl,
    setDisabledControl,
    selectedLocationNearBy,
    setSelectedLocationNearBy,
    publicType,
  } = useContext(ResidencesContext);

  const handleChange = (event, location, type) => {
    if (type === "residence") {
      setSelectedLocation(location);
    } else if (type === "university") {
      setSelectedLocation(location);
    } else {
      setSelectedLocationNearBy(location);
      // if (publicType === null) {
      //   moveToLocation(
      //     universityTabControl
      //       ? location?.campus_coordinates.lng
      //       : location?.university_longitude,
      //     universityTabControl
      //       ? location?.campus_coordinates.lat
      //       : location?.university_latitude,
      //     17
      //   );
      // }
      setTimeout(() => {
        setDisabledControl(false);
      }, 1000);
    }
  };

  const locationRefs = useRef({});

  useEffect(() => {
    if (searchedResidence !== "") {
      const matchingLocation = locations.find((item) =>
        universityTabControl
          ? item?.university_title
              ?.toLocaleLowerCase()
              ?.includes(searchedResidence?.toLocaleLowerCase())
          : item?.campus_name
              ?.toLocaleLowerCase()
              ?.includes(searchedResidence?.toLocaleLowerCase())
      );
      if (
        matchingLocation &&
        locationRefs.current[
          universityTabControl
            ? matchingLocation?.university_title
            : matchingLocation.campus_name
        ]
      ) {
        setSelectedLocation(matchingLocation);
      }
    }
  }, [searchedResidence, universityTabControl, locations]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: locations?.length ? "auto" : "100%",
          maxHeight: "calc(500px - 64px)",
          overflow: "auto",
        }}
      >
        <>
          {nearByUniversities?.length ? (
            <>
              {nearByUniversities?.map((item, idx) => {
                return item?.tags?.name ||
                  item?.campus_name ||
                  item?.university_title ? (
                  <Box
                    key={
                      universityTabControl
                        ? item?.campus_name
                        : item.university_title
                    }
                    ref={(el) =>
                      (locationRefs.current[
                        universityTabControl
                          ? item?.campus_name
                          : item.university_title
                      ] = el)
                    }
                    onClick={(e) => {
                      handleChange(e, item, "nearbyuniversities");
                    }}
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      padding: ".5rem",
                      borderBottom: universityTabControl
                        ? selectedLocation?.university_title ===
                          item?.university_title
                          ? "none"
                          : "1px solid #cecece"
                        : selectedLocation?.campus_name === item?.campus_name
                        ? "none"
                        : "1px solid #cecece",
                      borderRadius: "0",
                      background: lightMode
                        ? universityTabControl
                          ? publicType === null
                            ? selectedLocationNearBy?.campus_name ===
                              item?.campus_name
                              ? `#f9f9f9`
                              : "#fefefe"
                            : selectedLocationNearBy?.tags?.name ===
                                item?.tags?.name && publicType === "bus"
                            ? selectedLocationNearBy?.tags[
                                "naptan:Indicator"
                              ] &&
                              item?.tags["naptan:Indicator"] &&
                              selectedLocationNearBy?.tags[
                                "naptan:Indicator"
                              ] === item?.tags["naptan:Indicator"]
                              ? `#f9f9f9`
                              : "#fefefe"
                            : selectedLocationNearBy?.tags?.name ===
                              item?.tags?.name
                            ? `#f9f9f9`
                            : "#fefefe"
                          : publicType === null
                          ? selectedLocationNearBy?.university_title ===
                            item?.university_title
                            ? `#f9f9f9`
                            : "#fefefe"
                          : selectedLocationNearBy?.tags?.name ===
                              item?.tags?.name && publicType === "bus"
                          ? selectedLocationNearBy?.tags["naptan:Indicator"] &&
                            item?.tags["naptan:Indicator"] &&
                            selectedLocationNearBy?.tags["naptan:Indicator"] ===
                              item?.tags["naptan:Indicator"]
                            ? `#f9f9f9`
                            : "#fefefe"
                          : selectedLocationNearBy?.tags?.name ===
                            item?.tags?.name
                          ? `#f9f9f9`
                          : "#fefefe"
                        : "#242424",
                      marginBottom:
                        locations.length !== idx + 1 ? ".5rem" : "0",
                      "&:hover": {
                        borderRadius: ".5rem",
                        background: universityTabControl
                          ? selectedLocationNearBy?.campus_name ===
                            item?.campus_name
                            ? `#f1f1f1`
                            : "#f9f9f9"
                          : selectedLocationNearBy?.university_title ===
                            item?.university_title
                          ? `#f1f1f1`
                          : "#f9f9f9",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {universityTabControl &&
                      nearByUniversities?.length &&
                      publicType === null ? (
                        <img
                          src={item?.logo}
                          width={70}
                          height={70}
                          style={{
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: "1rem",
                          }}
                          alt=""
                        />
                      ) : null}

                      <Typography
                        sx={{
                          marginRight: "1rem",
                          marginLeft: universityTabControl ? "1rem" : "0",
                        }}
                      >
                        {publicType === "bus" ||
                        publicType === "train" ||
                        publicType === "market" ||
                        publicType === "pharmacie" ||
                        publicType === "restaurant"
                          ? item?.tags?.name
                            ? item?.tags?.name
                            : null
                          : universityTabControl
                          ? item?.campus_name
                          : item?.university_title}{" "}
                        {publicType === "bus" ||
                        publicType === "train" ||
                        publicType === "market" ||
                        publicType === "pharmacie" ||
                        publicType === "restaurant"
                          ? item?.tags["naptan:Indicator"]
                            ? `- ` + item?.tags["naptan:Indicator"]
                            : item?.tags?.network
                            ? `- ` + item?.tags?.network
                            : null
                          : null}
                      </Typography>
                      {publicType === "bus" ||
                      publicType === "train" ||
                      publicType === "market" ||
                      publicType === "pharmacie" ||
                      publicType === "restaurant" ? (
                        <Typography>{item?.distance}</Typography>
                      ) : null}
                      {publicType === null ? (
                        <Typography sx={{ marginLeft: "auto" }}>
                          {item?.distance?.toFixed(2)}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                ) : null;
              })}
            </>
          ) : (
            <>
              {disabledControl ? (
                <Box
                  sx={{ height: "500px", width: "100%", overflow: "hidden" }}
                >
                  <Skeleton
                    width={"100%"}
                    height={"100%"}
                    sx={{ transform: "scale(1,1)", borderRadius: "1rem" }}
                  />
                </Box>
              ) : (
                <>
                  {/* <Box
                    onClick={(e) => {
                      handleChange(
                        e,
                        {
                          campus_name: "Home",
                          campus_id: 6,
                          campus_coordinates: {
                            lng: "29.136548",
                            lat: "40.932571",
                          },
                        },
                        "residence"
                      );
                    }}
                  >
                    Home
                  </Box> */}
                  {locations?.length ? (
                    locations
                      ?.filter((location) => {
                        return universityTabControl
                          ? location?.university_latitude
                          : location?.campus_coordinates?.lat &&
                            universityTabControl
                          ? location?.university_longitude
                          : location?.campus_coordinates?.lng;
                      })
                      .map((item, idx) => {
                        return (
                          <Box
                            key={
                              universityTabControl
                                ? item?.university_title
                                : item?.campus_name
                            }
                            ref={(el) =>
                              (locationRefs.current[
                                universityTabControl
                                  ? item?.university_title
                                  : item?.campus_name
                              ] = el)
                            }
                            onClick={(e) => {
                              setDisabledControl(true);
                              handleChange(
                                e,
                                item,
                                universityTabControl
                                  ? "university"
                                  : "residence"
                              );
                            }}
                            sx={{
                              cursor: "pointer",
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              padding: ".5rem",
                              borderBottom: universityTabControl
                                ? selectedLocation?.university_title ===
                                  item?.university_title
                                  ? "none"
                                  : "1px solid #cecece"
                                : selectedLocation?.campus_name ===
                                  item?.campus_name
                                ? "none"
                                : "1px solid #cecece",
                              borderRadius: "0",

                              marginBottom:
                                locations.length !== idx + 1 ? ".5rem" : "0",
                              "&:hover": {
                                borderRadius: ".5rem",
                                background: "#f9f9f9",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              ></Box>
                              {!universityTabControl &&
                              !nearByUniversities?.length ? (
                                <img
                                  src={item?.logo}
                                  width={70}
                                  height={70}
                                  style={{
                                    objectFit: "cover",
                                    objectPosition: "center",
                                    borderRadius: ".5rem",
                                  }}
                                  alt=""
                                />
                              ) : null}
                              <Typography
                                sx={{
                                  marginRight: "auto",
                                  marginLeft: ".5rem",
                                }}
                              >
                                {universityTabControl
                                  ? item?.university_title
                                  : item?.campus_name}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })
                  ) : (
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5">
                        No Top Booked Residence Found!
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </>
      </Box>
    </>
  );
}
