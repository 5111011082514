import PhoneInput from "react-phone-input-2";
import "../styles/react-tel-input/react-tel-input.scss";
import { useSelector } from "react-redux";
import parsePhoneNumberFromString, { isValidPhoneNumber } from "libphonenumber-js";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { phonesValidations } from "./phoneFormats";

export default function PhoneCountryInput({
  formValues,
  setFormValues,
  disabled,
  error,
  helperText,
}) {
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const [output, setOutput] = useState(''); // Telefon numarası analiz sonuçlarını saklamak için state

  useEffect(() => {
    console.log(error, "error", helperText, "helperText");
  }, [error, helperText]);
  // const handlePhoneChange = (value) => {
  //   // Telefon numarası için form değerlerini güncelle
  //   setFormValues((prev) => ({
  //     ...prev,
  //     phone: value, // "phone" alanını güncelle
  //   }));
  // };

  // const handlePhoneChange = (value) => {
  //   // Telefon numarası için form değerlerini güncelle ve geçerlilik durumunu kontrol et
  //   const isValid = isValidPhoneNumber(value, "TR"); // 'TR' ülke kodunu kullanarak Türkiye için geçerlilik kontrolü

  //   setFormValues((prev) => ({
  //     ...prev,
  //     phone: value, // "phone" alanını güncelle
  //     phoneIsValid: isValid, // Telefon numarasının geçerlilik durumunu form değerlerine ekle
  //   }));
  // };
  const [phoneControl, setPhoneControl] = useState(false);

  // const handlePhoneChange = (value, country) => {
  //   // Telefon numarasının uzunluğunu ve geçerliliğini kontrol et
  //   const isValid = isValidPhoneNumber(
  //     value,
  //     country?.countryCode.toUpperCase()
  //   );


  //   // Eğer phoneIsValid false ise ve kullanıcı değer eklemeye çalışıyorsa, ekleme yapmasını engelle
  //   if (formValues.phoneIsValid && value.length > formValues.phone.length) {
  //     setFormValues((prev) => ({
  //       ...prev,
  //       phoneIsValid: false,
  //     }));
  //     // Telefon numarası geçerli değilse ve kullanıcı numarayı uzatmaya çalışıyorsa, işlemi burada durdur.
  //     return;
  //   }

  //   // Telefon numarası güncelleme ve geçerlilik durumu
  //   setFormValues((prev) => ({
  //     ...prev,
  //     phone: value,
  //     phoneIsValid: isValid,
  //   }));
  // };

  // const handlePhoneChange = (value, country) => {
  //   // react-phone-input-2'den gelen iki harflik ülke kodunu al
  //   const countryCode = country?.countryCode?.toUpperCase();

  //   // JSON anahtarlarında ülke kodunu bulmak için uygun anahtarı oluştur
  //   // Bu sefer sağdaki ülke kodunu direkt kullanarak eşleşme sağlanacak
  //   const validationKey = Object.keys(phonesValidations).find((key) =>
  //     key.endsWith(`-${countryCode}`)
  //   );

  //   // Eşleşen anahtar ile regex'i JSON'dan al
  //   const regex = validationKey ? phonesValidations[validationKey] : null;
  //   // Telefon numarasının geçerliliğini kontrol et
  //   const isValid = regex ? regex.test(value) : false;

  //   // Form değerlerini güncelle
  //   setFormValues((prev) => ({
  //     ...prev,
  //     phone: value,
  //     phoneIsValid: isValid,
  //   }));
  // };

  const handlePhoneChange = (value, country) => {
    // react-phone-input-2'den gelen iki harflik ülke kodunu al
    const countryCode = country?.countryCode?.toUpperCase();

    // Telefon numarasını ve geçerlilik durumunu yönetmek için state kullanın
    try {
      let parsedNumber = parsePhoneNumberFromString(value, countryCode);
      if (parsedNumber) {
        setOutput(`
          ****Parsing Result:****
          ${JSON.stringify(parsedNumber, null, 2)}
          
          ****Validation Results:****
          Valid: ${parsedNumber.isValid()}
          Possible: ${parsedNumber.isPossible()}
          Type: ${parsedNumber.getType()}
          
          ****Formatting Results:****
          E164 format: ${parsedNumber.format("E.164")}
          International format: ${parsedNumber.formatInternational()}
          National format: ${parsedNumber.formatNational()}
          Original input: ${parsedNumber.input}
        `);

        setFormValues((prev) => ({
          ...prev,
          phone: value,
          phoneIsValid: parsedNumber.isPossible(),
        }));
      } else {
        setOutput("Invalid phone number.");
        setFormValues((prev) => ({
          ...prev,
          phone: value,
          phoneIsValid: false,
        }));
      }
    } catch (error) {
      setOutput(`Error: ${error.message}`);
      setFormValues((prev) => ({
        ...prev,
        phone: value,
        phoneIsValid: false,
      }));
    }
  };

  // useEffect(() => {
  //   if (formValues?.phoneIsValid) {
  //   } else {
  //   }
  // }, [formValues?.phoneIsValid]);

  const phoneInputStyles = {
    inputStyle: {
      width: "100%",
      backgroundColor: lightMode ? "#ffffff" : "#1e1e1e", // Input alanı arka plan rengi
      borderColor: lightMode ? "#c4c4c4" : "#4b4b4b",
      color: lightMode ? "#000000" : "#ffffff", // Input yazı rengi
      "&:focus": {
        borderColor: lightMode ? "#212121" : "#212121",
      },
    },
    containerStyle: {
      // Buraya gerektiğinde container için ek stil özellikleri ekleyin
    },
    buttonStyle: {
      color: lightMode ? "#000000" : "#ffffff", // Dropdown butonu yazı rengi
    },
    dropdownStyle: {
      backgroundColor: lightMode ? "#ffffff" : "#333", // Dropdown menü arka plan rengi
      color: lightMode ? "#000000" : "#ffffff", // Dropdown menü yazı rengi
    },
  };

  return (
    <>
      <PhoneInput
        disabled={disabled}
        containerClass={lightMode ? `light-mode` : `dark-mode`}
        country={"gb"}
        value={formValues.phone}
        onChange={handlePhoneChange}
        inputStyle={phoneInputStyles.inputStyle}
        containerStyle={phoneInputStyles.containerStyle}
        buttonStyle={phoneInputStyles.buttonStyle}
        dropdownStyle={phoneInputStyles.dropdownStyle}
        specialLabel="Phone *"
      />
      {helperText ? (
        <Typography
          sx={{
            marginLeft: "14px",
            marginRight: "14px",
            marginTop: "3px",
            textAlign: "left",
            color: "#bf0000",
          }}
          variant="caption"
        >
          {helperText}
        </Typography>
      ) : null}
    </>
  );
}

// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css"; // Telefon giriş stilini uygulamak için
// import { parsePhoneNumberFromString } from "libphonenumber-js";
// import { Typography } from "@mui/material";

// export default function PhoneCountryInput({
//   formValues,
//   setFormValues,
//   disabled,
//   error,
//   helperText,
// }) {
//   const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
//   const [output, setOutput] = useState(""); // Telefon numarası analiz sonuçlarını saklamak için state

//   useEffect(() => {
//     console.log(error, "error", helperText, "helperText");
//   }, [error, helperText]);

//   const handlePhoneChange = (value, country) => {
//     // react-phone-input-2'den gelen iki harflik ülke kodunu al
//     const countryCode = country?.countryCode?.toUpperCase();

//     // Telefon numarasını ve geçerlilik durumunu yönetmek için state kullanın
//     try {
//       let parsedNumber = parsePhoneNumberFromString(value, countryCode);
//       if (parsedNumber) {
//         setOutput(`
//           ****Parsing Result:****
//           ${JSON.stringify(parsedNumber, null, 2)}

//           ****Validation Results:****
//           Valid: ${parsedNumber.isValid()}
//           Possible: ${parsedNumber.isPossible()}
//           Type: ${parsedNumber.getType()}

//           ****Formatting Results:****
//           E164 format: ${parsedNumber.format("E.164")}
//           International format: ${parsedNumber.formatInternational()}
//           National format: ${parsedNumber.formatNational()}
//           Original input: ${parsedNumber.input}
//         `);

//         setFormValues((prev) => ({
//           ...prev,
//           phone: value,
//           phoneIsValid: parsedNumber.isPossible(),
//         }));
//       } else {
//         setOutput("Invalid phone number.");
//         setFormValues((prev) => ({
//           ...prev,
//           phone: value,
//           phoneIsValid: false,
//         }));
//       }
//     } catch (error) {
//       setOutput(`Error: ${error.message}`);
//       setFormValues((prev) => ({
//         ...prev,
//         phone: value,
//         phoneIsValid: false,
//       }));
//     }
//   };

//   const phoneInputStyles = {
//     inputStyle: {
//       width: "100%",
//       backgroundColor: lightMode ? "#ffffff" : "#1e1e1e", // Input alanı arka plan rengi
//       borderColor: lightMode ? "#c4c4c4" : "#4b4b4b",
//       color: lightMode ? "#000000" : "#ffffff", // Input yazı rengi
//       "&:focus": {
//         borderColor: lightMode ? "#212121" : "#212121",
//       },
//     },
//     containerStyle: {
//       // Gerektiğinde container için ek stil özellikleri
//     },
//     buttonStyle: {
//       color: lightMode ? "#000000" : "#ffffff", // Dropdown butonu yazı rengi
//     },
//     dropdownStyle: {
//       backgroundColor: lightMode ? "#ffffff" : "#333",
//       color: lightMode ? "#000000" : "#ffffff",
//     },
//   };

//   return (
//     <>
//       <PhoneInput
//         disabled={disabled}
//         containerClass={lightMode ? `light-mode` : `dark-mode`}
//         country={"gb"}
//         value={formValues.phone}
//         onChange={handlePhoneChange}
//         inputStyle={phoneInputStyles.inputStyle}
//         containerStyle={phoneInputStyles.containerStyle}
//         buttonStyle={phoneInputStyles.buttonStyle}
//         dropdownStyle={phoneInputStyles.dropdownStyle}
//         specialLabel="Phone *"
//       />
//       {helperText ? (
//         <Typography
//           sx={{
//             marginLeft: "14px",
//             marginRight: "14px",
//             marginTop: "3px",
//             textAlign: "left",
//             color: "#bf0000",
//           }}
//           variant="caption"
//         >
//           {helperText}
//         </Typography>
//       ) : null}
//     </>
//   );
// }
