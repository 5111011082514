import React, { useContext, useEffect } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  Box,
  createTheme,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import AirportServicesContext from "../../../../../../contexts/AirportServices/AirportServices";
import PersonIcon from "@mui/icons-material/Person";

function SelectMenus({ handleNext }) {
  const {
    airports,
    residences,
    selectedAirport,
    setSelectedAirport,
    selectedResidence,
    setSelectedResidence,
    selectedPerson,
    setSelectedPerson,
    travelType,
    bookingDetails,
    setBookingDetails,
    activeStep,
    setActiveStep,
  } = useContext(AirportServicesContext);

  useEffect(() => {
    // Havalimanlarını ve konutları bookingDetails ile eşleştir ve başlangıç değerlerini ayarla
    if (airports?.length) {
      const matchedAirport = airports?.find(
        (airport) => airport.service_id === bookingDetails?.service_id
      );
      if (matchedAirport !== undefined) {
        setSelectedAirport(matchedAirport);
      }
    }
    if (residences?.length) {
      const matchedResidence = residences?.find(
        (residence) => residence.id === bookingDetails?.residence_id
      );
      if (matchedResidence !== undefined) {
        setSelectedResidence(matchedResidence);
      }
    }
  }, [
    airports,
    residences,
    bookingDetails.service_id,
    bookingDetails.residence_id,
  ]);

  // useEffect(() => {
  // }, [selectedAirport, selectedResidence]);
  const handleChangeAirport = (_event, newValue) => {
    setSelectedAirport(newValue);
  };

  const handleChangeResidence = (_event, newValue) => {
    setSelectedResidence(newValue);
  };

  const handleChangePerson = (event) => {
    setSelectedPerson(event.target.value);
  };

  const numbers = Array.from({ length: 10 }, (_, index) => index + 1);
  const personItems = numbers.map((number) => (
    <MenuItem key={number} value={number}>
      {number}
    </MenuItem>
  ));

  const handleBookingDetails = () => {
    setBookingDetails((prev) => ({
      ...prev,
      service_id: selectedAirport?.service_id,
      residence_id: selectedResidence?.id,
      travel_type: travelType,
      people_count: selectedPerson,
    }));
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        marginTop: "1rem",
      }}
    >
      <FormControl fullWidth>
        <Autocomplete
          fullWidth
          value={travelType === "Arrival" ? selectedAirport : selectedResidence}
          onChange={
            travelType === "Arrival"
              ? handleChangeAirport
              : handleChangeResidence
          }
          disablePortal
          id="combo-box-demo"
          options={travelType === "Arrival" ? airports : residences || []}
          disabled={!airports?.length}
          getOptionLabel={(option) =>
            travelType === "Arrival" ? option.service_name : option?.name
          } // 'name' özelliğini label olarak kullan
          renderInput={(params) => (
            <TextField
              {...params}
              label="From"
              placeholder={travelType === "Arrival" ? "Airport" : "Residence"}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>

      <FormControl
        fullWidth
        sx={{
          marginRight: { xs: "0", md: "1rem" },
          marginLeft: { xs: "0", md: "1rem" },
          marginTop: { xs: "1rem", md: "0" },
          marginBottom: { xs: "1rem", md: "0" },
        }}
      >
        <Autocomplete
          fullWidth
          value={travelType === "Arrival" ? selectedResidence : selectedAirport}
          onChange={
            travelType === "Arrival"
              ? handleChangeResidence
              : handleChangeAirport
          }
          disablePortal
          id="combo-box-demo"
          options={travelType === "Arrival" ? residences : airports || []}
          disabled={!residences?.length}
          getOptionLabel={(option) =>
            travelType === "Arrival" ? option?.name : option?.service_name
          } // 'name' özelliğini label olarak kullan
          renderInput={(params) => (
            <TextField
              {...params}
              label="To"
              placeholder={travelType === "Arrival" ? "Residence" : "Airport"}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>

      {/* <FormControl
          fullWidth
          sx={{
            marginRight: { xs: "0", md: "1rem" },
            marginLeft: { xs: "0", md: "1rem" },
          }}
        >
          <InputLabel id="demo-simple-select-label">To</InputLabel>
          <Select
            className="residence"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedResidence}
            onChange={handleChangeResidence}
            startAdornment={
              <InputAdornment position="start">
                <LocationOnIcon />
              </InputAdornment>
            }
          >
            {residences?.map((residence, idx) => {
              return (
                <MenuItem value={residence?.name}>{residence?.name}</MenuItem>
              );
            })}
          </Select>
        </FormControl> */}

      <FormControl
        sx={{
          maxWidth: { xs: "100%", md: "125px" },
          width: "100%",
          marginRight: { xs: "0", md: "1rem" },
        }}
      >
        <InputLabel id="demo-simple-select-label">Person</InputLabel>
        <Select
          className="person"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedPerson}
          onChange={handleChangePerson}
          startAdornment={
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          }
        >
          {personItems}
        </Select>
      </FormControl>
      <Button
        sx={{ borderRadius: "1rem", marginTop: { xs: "1rem", md: "0" } }}
        onClick={() => (handleBookingDetails(), setActiveStep(activeStep + 1))}
        disabled={
          !selectedAirport ||
          !selectedResidence ||
          !selectedPerson ||
          !travelType
        }
        fullWidth
        variant="contained"
        color="primary"
      >
        Book Now
      </Button>
    </Box>
  );
}

export default SelectMenus;
