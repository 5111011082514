import React, { useEffect, useMemo, useRef, useState } from "react";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import BreadCrumbs from "../../components/Breadcrumbs";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import { useSelector } from "react-redux";
import ResidenceSlider from "../../components/ResidenceDetails/Slider/Slider";
import ImageGallery from "../../components/ResidenceDetails/Gallery/ImageGallery";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ResidenceDescription from "../../components/ResidenceDetails/ResidenceDescription";
import PinDropIcon from "@mui/icons-material/PinDrop";
import NoImageFound from "../../assets/images/no-image.jpg";
import ResidenceRoomDetails from "../../components/ResidenceDetails/ResidenceRoomDetails";
import MapResidenceDetails from "../../components/ResidenceDetails/Map/Map";
import AddIcon from "@mui/icons-material/Add";

const ResidenceDetails = () => {
  const { id } = useParams();
  const [residenceDetails, setResidenceDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const mapRef = useRef(null);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const [highlights, setHighlights] = useState(null);

  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [openDescription, setOpenDescription] = useState(false);
  const [openRoomDetails, setOpenRoomDetails] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedGalleryType, setSelectedGalleryType] = useState(null);

  const navigate = useNavigate();

  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const getResidenceDetails = async (id) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}residences/${id}/`;
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          Accounts: selectedBranchesString,
        },
      });

    

      setResidenceDetails(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate("/room-finder");
      console.error("API isteği sırasında hata oluştu:", error);
    }
  };

  useEffect(() => {
    if (id) {
      getResidenceDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (residenceDetails) {
      setHighlights(residenceDetails?.campus_highlights);
    }
  }, [residenceDetails]);

  const handleOpenImageGalery = (galleryType) => {
    setSelectedGalleryType(galleryType);
  };
  const handleOpenDescription = () => setOpenDescription(true);
  // const handleOpenResidenceRoomDetails = () => setOpenRoomDetails(true);

  const handleOpenResidenceRoomDetails = (data) => {
    setOpenRoomDetails(true);
    setSelectedRoom(data);
  };

  // const extractTextFromHtml = (htmlString) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlString, "text/html");
  //   return doc.body.textContent || "";
  // };
  // const extractTextFromHtml = (htmlString) => {
  //   return htmlString
  //     .replace(/<\/?strong>/g, "**") // <strong> etiketlerini ** ile değiştir
  //     .replace(/<\/?ul>/g, "\n") // <ul> etiketini yeni satır ile değiştir
  //     .replace(/<\/?li>/g, "- ") // <li> etiketini liste işareti ile değiştir
  //     .replace(/<\/?[^>]+(>|$)/g, ""); // Diğer tüm HTML etiketlerini kaldır
  // };

  const extractTextFromHtml = (description) => {
    return <div dangerouslySetInnerHTML={{ __html: description }} />;
  };



  useEffect(() => {
    if (selectedGalleryType) {
      setOpenImageGallery(true);
    } else {
      setOpenImageGallery(false);
    }
  }, [selectedGalleryType]);

  const mapComponent = useMemo(() => {
    if (residenceDetails?.campus_coordinates) {
      return (
        <Box
          sx={{
            border: `1px solid ${lightMode ? `#efefef` : `#191919`}`,
            boxShadow: `0 0 3px ${lightMode ? `#efefef` : `#d8d8d8`}`,
            padding: { xs: "1rem", md: "1.75rem 1.75rem" },
            marginBottom: "2rem",
            borderRadius: "2rem",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MapResidenceDetails
                mapRef={mapRef}
                locationDatas={[residenceDetails?.campus_coordinates]}
              />
            </Grid>
          </Grid>
        </Box>
      );
    }
    return null;
  }, [residenceDetails?.campus_coordinates]);
  return (
    <>
      <Typography className="title" variant="h4">
        Residence Details
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Residence Details"]} />
      {loading ? (
        <CircularProgress />
      ) : residenceDetails ? (
        <>
          <Box
            sx={{
              border: `1px solid ${lightMode ? `#efefef` : `#191919`}`,
              boxShadow: `0 0 3px ${lightMode ? `#efefef` : `#d8d8d8`}`,
              padding: { xs: "1rem", md: "1.75rem 1.75rem" },
              marginBottom: "2rem",
              borderRadius: "2rem",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <ResidenceSlider data={residenceDetails} />
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ maxHeight: "400px", height: "100%" }}>
                  {residenceDetails?.campus_videos?.length ? (
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: { xs: "200px", md: "calc(50% - 9px)" },
                        borderRadius: "2rem",
                        overflow: "hidden",
                        marginBottom: "1rem",
                        position: "relative",
                        "&:before": {
                          content: '""',
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          background: "#000000",
                          opacity: ".4",
                          zIndex: 9,
                        },
                      }}
                      onClick={() => handleOpenImageGalery("videos")}
                    >
                      <PlayCircleOutlineIcon
                        sx={{
                          fontSize: "48px",
                          color: "#ffffff",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translateY(-50%) translateX(-50%)",
                          zIndex: 10,
                        }}
                      />
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        src={residenceDetails?.images[0]?.image_file}
                        alt=""
                      />
                    </Box>
                  ) : null}
                  {residenceDetails?.images?.length ? (
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: { xs: "200px", md: "calc(50% - 9px)" },
                        borderRadius: "2rem",
                        overflow: "hidden",
                        position: "relative",
                        "&:before": {
                          content: '""',
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          background: "#000000",
                          opacity: ".4",
                          zIndex: 9,
                        },
                      }}
                      onClick={() => handleOpenImageGalery("images")}
                    >
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        src={
                          residenceDetails?.images?.length > 1
                            ? residenceDetails?.images[1]?.image_file
                            : residenceDetails?.images[0]?.image_file
                        }
                        alt=""
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translateY(-50%) translateX(-50%)",
                          zIndex: 10,
                        }}
                      >
                        <Typography
                          variant="h3"
                          sx={{
                            color: "#ffffff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <AddIcon
                            sx={{ color: "#ffffff", fontSize: "48px" }}
                          />
                          {residenceDetails?.images?.length === 1
                            ? 1
                            : residenceDetails?.images?.length - 1}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              border: `1px solid ${lightMode ? `#efefef` : `#191919`}`,
              boxShadow: `0 0 3px ${lightMode ? `#efefef` : `#d8d8d8`}`,
              padding: { xs: "1rem", md: "1.75rem 1.75rem" },
              marginBottom: "1rem",
              borderRadius: "2rem",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box>
                  <Typography
                    sx={{
                      marginBottom: "1rem",
                      borderBottom: "1px solid #efefef",
                      paddingBottom: ".5rem",
                    }}
                    variant="h5"
                  >
                    {residenceDetails?.campus_name}
                  </Typography>
                  <Typography variant="caption">
                    <PinDropIcon
                      sx={{
                        position: "relative",
                        top: "4px",
                        color: "#bf0000",
                        fontSize: "20px",
                      }}
                    />
                    {residenceDetails?.campus_address}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginTop: "1rem",
                    }}
                  >
                    {JSON.parse(highlights)?.length &&
                      JSON.parse(highlights)?.map((highlight, idx) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #800000",
                              background: "#800000",
                              padding: ".25rem .5rem",
                              marginRight: ".5rem",
                              marginBottom: ".5rem",
                              borderRadius: "2rem",
                              cursor: "pointer",
                              color: "#ffffff",
                            }}
                          >
                            <Typography variant="caption" key={idx}>
                              {highlight}
                            </Typography>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {mapComponent}

          {residenceDetails?.campus_description && (
            <Box
              sx={{
                border: `1px solid ${lightMode ? `#efefef` : `#191919`}`,
                boxShadow: `0 0 3px ${lightMode ? `#efefef` : `#d8d8d8`}`,
                padding: { xs: "1rem", md: "1.75rem 1.75rem" },
                marginBottom: "2rem",
                borderRadius: "2rem",
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <>
                    <Typography
                      sx={{
                        marginBottom: "1rem",
                        borderBottom: "1px solid #efefef",
                        paddingBottom: ".5rem",
                      }}
                      variant="h6"
                    >
                      About the Property
                    </Typography>
                    <Box>
                      <Typography
                        sx={{
                          display: "-webkit-box" /* or inline-block */,
                          textOverflow: "ellipsis",
                          wordWrap: "break-word",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          lineHeight: "1.8em",
                        }}
                      >
                        {extractTextFromHtml(
                          residenceDetails?.campus_description
                        )}
                      </Typography>
                      <Typography
                        onClick={handleOpenDescription}
                        color="primary"
                        sx={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginTop: "1rem",
                        }}
                      >
                        See more
                      </Typography>
                    </Box>
                  </>
                </Grid>
              </Grid>
            </Box>
          )}

          {/* {residenceDetails?.campus_coordinates && (
            <Box
              sx={{
                border: `1px solid ${lightMode ? `#efefef` : `#191919`}`,
                boxShadow: `0 0 3px ${lightMode ? `#efefef` : `#d8d8d8`}`,
                padding: "1rem",
                marginBottom: "2rem",
                borderRadius: "2rem",
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MapResidenceDetails
                    mapRef={mapRef}
                    locationDatas={[residenceDetails?.campus_coordinates]}
                  />
                </Grid>
              </Grid>
            </Box>
          )} */}

          {residenceDetails?.rooms && residenceDetails?.rooms?.length && (
            <Box
              sx={{
                border: `1px solid ${lightMode ? `#efefef` : `#191919`}`,
                boxShadow: `0 0 3px ${lightMode ? `#efefef` : `#d8d8d8`}`,
                padding: { xs: "1rem", md: "1.75rem 1.75rem" },
                marginBottom: "2rem",
                borderRadius: "2rem",
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <>
                    <Typography
                      sx={{
                        marginBottom: "2rem",
                        borderBottom: "1px solid #efefef",
                        paddingBottom: ".5rem",
                      }}
                      variant="h6"
                    >
                      Rooms
                    </Typography>
                    <Box>
                      {residenceDetails?.rooms?.map((room, idx) => {
                        return (
                          <Box
                            sx={{
                              boxShadow: `0 0 3px ${
                                lightMode ? `#d8d8d8` : `#d8d8d8`
                              }`,
                              background: lightMode ? `#fbfbfb` : `#191919`,

                              padding: "1rem",
                              marginBottom: "1rem",
                              borderRadius: "2rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                height: "100px",
                                width: "150px",
                                minWidth: "150px",
                                borderRadius: "1rem",
                                overflow: "hidden",
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                            >
                              <img
                                src={
                                  room?.images && room?.images?.length
                                    ? room?.images[0]
                                    : NoImageFound
                                }
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                marginLeft: "1rem",
                                display: "flex",
                                flexDirection: "column",
                                minHeight: "100px",
                                height: "100%",
                              }}
                            >
                              <Typography variant="h6">
                                {room.course_name}
                              </Typography>
                              <Typography
                                sx={{
                                  display: "-webkit-box" /* or inline-block */,
                                  textOverflow: "ellipsis",
                                  wordWrap: "break-word",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  lineHeight: "1.8em",
                                }}
                                variant="caption"
                              >
                                {extractTextFromHtml(room.course_description)}
                              </Typography>
                              <Typography
                                color="primary"
                                variant="caption"
                                onClick={() =>
                                  handleOpenResidenceRoomDetails(room)
                                }
                                sx={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  marginTop: "auto",
                                }}
                              >
                                View more details
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </>
                </Grid>
              </Grid>
            </Box>
          )}
          <ImageGallery
            openImageGallery={openImageGallery}
            setOpenImageGallery={setOpenImageGallery}
            data={residenceDetails}
            selectedGalleryType={selectedGalleryType}
            setSelectedGalleryType={setSelectedGalleryType}
          />
          <ResidenceDescription
            openDescription={openDescription}
            setOpenDescription={setOpenDescription}
            data={residenceDetails}
          />
          <ResidenceRoomDetails
            openRoomDetails={openRoomDetails}
            setOpenRoomDetails={setOpenRoomDetails}
            data={selectedRoom}
          />
        </>
      ) : null}
    </>
  );
};

export default ResidenceDetails;
