import { Box, Grid, Typography } from "@mui/material";
import ConcertTicketImg from "../../assets/images/tickets/concert.jpg";
import SportTicketImg from "../../assets/images/tickets/sport.jpg";
import FlightTicketImg from "../../assets/images/tickets/flight-ticket.png";
import TrainTicketImg from "../../assets/images/tickets/train.jpg";
import LondonBusTourTicketImg from "../../assets/images/tickets/london-bus.jpg";
import LondonAttractionsTicketImg from "../../assets/images/tickets/london-attractions.jpg";
import MuseumentImg from "../../assets/images/tickets/musement.png";
import LanguageCertImg from "../../assets/images/tickets/languageCert.png";

import { Link } from "react-router-dom";
export default function TicketItems() {
  const ticketItems = [
    // {
    //   id: 8,
    //   title: "Ticket Master",
    //   url: "/tickets/3",
    //   imgUrl: MuseumentImg,
    // },
    {
      id: 8,
      title: "LANGUAGECERT",
      url: "https://www.languagecert.org/en/language-exams/english/languagecert-academic/academic",
      imgUrl: LanguageCertImg,
    },
    {
      id: 7,
      title: "Musement",
      url: "/tickets/2",
      imgUrl: MuseumentImg,
    },

    {
      id: 1,
      title: "Flight Ticket",
      url: "https://www.kayak.co.uk/?utm_source=tradetracker&utm_medium=affiliate&utm_campaign=472424&utm_content=32925",
      imgUrl: FlightTicketImg,
    },

    {
      id: 2,
      title: "London Attractions",
      url: "/tickets/1",
      imgUrl: LondonAttractionsTicketImg,
    },
    {
      id: 3,
      title: "Train Ticket",
      url: "https://rail.londonist.co.uk/",
      imgUrl: TrainTicketImg,
    },
    {
      id: 4,
      title: "London Bus Tours",
      url: "https://dashboard.ventrata.com/checkout/en?checkout=e20355fa-2a14-469b-8f3d-3795b6ec5aff&r=londonistdmc.com",
      imgUrl: LondonBusTourTicketImg,
    },
    // {
    //   id: 5,
    //   title: "Sport Tickets",
    //   url: "",
    //   imgUrl: SportTicketImg,
    // },
    // {
    //   id: 6,
    //   title: "Concert Tickets",
    //   url: "",
    //   imgUrl: ConcertTicketImg,
    // },
  ];
  return (
    <Grid container spacing={3}>
      {ticketItems?.map((item, idx) => {
        return (
          <Grid item xs={12} md={4} key={idx}>
            <Link
              to={item.url ? item.url : "javascript:;"}
              target={
                item.id === 1 || item.id === 3 || item.id === 4
                  ? "_blank"
                  : "_self"
              }
              style={{
                position: "relative",
                overflow: "hidden",
                display: "flex",
                boxShadow: "0 0 5px #efefef",
                borderRadius: "1rem",
                cursor: item.id === 5 || item.id === 6 ? "default" : "pointer",
              }}
            >
              <Box
                sx={{
                  transform: "scale(1)",
                  transition: "transform .35s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    transition: "transform .35s",
                  },
                  overflow: "hidden",
                  position: "relative",

                  padding: "1rem",
                  height: "300px",
                  backgroundImage: `url(${item.imgUrl})`,
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  background: "#0000004f",
                  width: "100%",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "1rem",
                }}
              >
                <Typography sx={{ color: "#ffffff" }}>{item.title}</Typography>
              </Box>
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
}
