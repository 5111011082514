import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "1200px",
  width: "90%",
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  outline: "none",
  boxShadow: 24,
  p: 0,
};

export default function CancellationFormModal({
  setOpen,
  open,
  invoiceId,
  studentId,
}) {
  const handleCloseCancellationFormModal = () => setOpen(false);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleCloseCancellationFormModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {/*   <Box id="transition-modal-title" sx={{padding: '1rem'}}>
                            <Typography variant="h6" component="h2">
                                Deposit Refund Request Form
                            </Typography>
                        </Box>*/}
            <Box
              id="transition-modal-description"
              sx={{
                height: "calc(100% - 128px)",
              }}
            >
              <iframe
                style={{ border: "none" }}
                src={
                  window?.location?.href?.includes("//student.londonist.co.uk")
                    ? `https://management.londonist.co.uk/cancellation-process-of-student?invoice_id=${invoiceId}&student_id=${studentId}`
                    : `https://management.londonist.co.uk/cancellation-process-of-student?invoice_id=${invoiceId}&student_id=${studentId}`
                }
                height={"100%"}
                width={"100%"}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
