import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const getDirections = (
  mapRef,
  selectedLocation,
  selectedLocationNearBy,
  publicType,
  universityTabControl
) => {
  if (selectedLocationNearBy && selectedLocation) {
    // Mevcut Directions kontrolünü bul veya yeni bir tane oluştur
    let directions = mapRef.current._controls.find(
      (control) => control instanceof MapboxDirections
    );

    if (!directions) {
      directions = new MapboxDirections({
        accessToken: mapboxgl.accessToken,
        unit: "metric",
        profile: "mapbox/driving",
        alternatives: true,
        geometries: "geojson",
      });

      mapRef.current.addControl(directions, "top-left");
    }

    // Yeni yön noktalarını ayarla

    const origin = universityTabControl
      ? [
          selectedLocation?.university_longitude,
          selectedLocation?.university_latitude,
        ]
      : [
          selectedLocation?.campus_coordinates?.lng,
          selectedLocation?.campus_coordinates?.lat,
        ];

    const destination =
      publicType !== null
        ? [selectedLocationNearBy?.lon, selectedLocationNearBy?.lat]
        : universityTabControl
        ? [
            selectedLocationNearBy?.campus_coordinates?.lng,
            selectedLocationNearBy?.campus_coordinates?.lat,
          ]
        : [
            selectedLocationNearBy?.university_longitude,
            selectedLocationNearBy?.university_latitude,
          ];

    directions.setOrigin(origin);
    directions.setDestination(destination);

    directions.on("route", (e) => {
      if (e.route.length > 0) {
        // console.log("Rota başarıyla yüklendi:", e.route[0]);
      }
    });
  }
};
