import { Box, Button, Typography } from "@mui/material";
import { light } from "@mui/material/styles/createPalette";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { closeModal } from "../../redux/actions/actions";

export default function TableDetailsModal({ data, columns }) {
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const pageType = useSelector((state) => state.filters.pageType);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const paraFormat = (number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(number);
  };

  return (
    <Box>
      <Typography
        sx={{ borderBottom: "1px solid #000", paddingBottom: ".25rem" }}
        id="modal-modal-title"
        variant="h6"
        component="h2"
      >
        Details
      </Typography>
      {columns.map((col, idx) => (
        <Typography
          key={col.id}
          id="modal-modal-description"
          sx={{
            mt: 2,
            borderBottom:
              idx == columns.length - 1 ? "unset" : "1px solid #dedede",
            paddingBottom: idx == columns.length - 1 ? "0" : ".25rem",
            color:
              data[col.id] === "fullypaid" ||
              (col.id === "commission_paid_status" && data[col.id])
                ? "green"
                : data[col.id] === "partiallypaid"
                ? "orange"
                : data[col.id] === "unpaid" ||
                  (col.id === "commission_paid_status" && !data[col.id])
                ? "#bf0000"
                : data[col.id] === "overpaid"
                ? "blue"
                : lightMode
                ? "black"
                : "#ffffff",
          }}
        >
          <strong
            style={{
              color: lightMode ? "#444444" : "#ffffff",
            }}
          >
            {col.label}:
          </strong>
          {/* {typeof data[col.id] === "boolean"
            ? data[col.id]
              ? "true"
              : "false"
            : data[col.id]} */}
          {
            col.id === "student_name"
              ? data[col.id]
                  ?.split(" ")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")
              : col.id === "commission_type"
              ? data[col.id]
                  ?.split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              : col.id === "quote_status"
              ? data[col.id]
                  ?.split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              : col.id === "commission_amount" ||
                col.id === "invoice_course_price"
              ? paraFormat(data[col?.id])
              : // data[col.id] + ` GBP`
              col.id === "commission_paid_status" && data[col.id]
              ? "Paid"
              : col.id === "commission_paid_status" && !data[col.id]
              ? "Unpaid"
              : data[col.id] === "fullypaid"
              ? "Fully Paid"
              : data[col.id] === "partiallypaid"
              ? "Partially Paid"
              : data[col.id] === "overpaid"
              ? "Over Paid"
              : data[col.id] === "unpaid"
              ? "Unpaid"
              : col?.id === "agent_name"
              ? data?.created_by_agent_name
                ? data?.created_by_agent_name
                : ""
              : col.id === "check_in" || col.id === "check_out"
              ? data.details[col.id] // details içinden check_in veya check_out değerini çek
              : typeof data[col.id] === "boolean"
              ? data[col.id]
                ? "true"
                : "false" // boolean değer için kontrol
              : data[col.id] // Diğer tüm değerler
          }
        </Typography>
      ))}

      {pageType === "quotes" ? (
        <Box sx={{ marginTop: "auto" }}>
          <Button
            fullWidth
            onClick={() => {
              dispatch(closeModal());
              navigate(`/quotes/${data?.quote_id}/details`, {
                state: {
                  quote: data,
                },
              });
            }}
            variant="contained"
            color="primary"
            sx={{ marginTop: "2rem" }}
          >
            See the details
          </Button>
        </Box>
      ) : pageType === "partner_requests" ? (
        <Box sx={{ marginTop: "auto" }}>
          <Button
            fullWidth
            onClick={() => {
              dispatch(closeModal());
              navigate(`/myrequests/${data?.id}/details`, {
                state: {
                  request: data,
                },
              });
            }}
            variant="contained"
            color="primary"
            sx={{ marginTop: "2rem" }}
          >
            See the details
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}
