import { Box, Button, Container, Grid, Typography } from "@mui/material";
import BreadCrumbs from "../../components/Breadcrumbs";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import AirportImg from "../../assets/images/additional-services/airport.jpg";
import CleaningImg from "../../assets/images/additional-services/cleaning.jpg";
import LaundryServiceImg from "../../assets/images/additional-services/laundry_service.jpg";
import StorageServiceImg from "../../assets/images/additional-services/storage_service.jpg";
import KayakImg from "../../assets/images/additional-services/flight-ticket.png";
import MaskImg from "../../assets/images/additional-services/mask.svg";
import Mask2Img from "../../assets/images/additional-services/mask-2.svg";
import Mask3Img from "../../assets/images/additional-services/mask-3.png";
import { useNavigate } from "react-router-dom";
import { htmlParser } from "../../common/utils";
import LanguageCertImg from "../../assets/images/additional-services/languageCert.jpg";

function MaskedImg({ serviceType }) {
  return (
    <>
      <Box
        sx={{
          width: "320px",
          maskImage: `url(${Mask3Img})`,
          maskSize: "100%",
          maskRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            display: "inline-block",
            overflow: "hidden",
            width: "initial",
            margin: "0px",
            padding: "0px",
            height: "initial",
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <span
            style={{
              boxSizing: "border-box",
              display: "block",
              width: "initial",
              height: "initial",
              background: "none",
              opacity: "1",
              border: "0px solid",
              margin: "0px",
              padding: "0px",
              maxWidth: "100%",
              visibility: "hidden",
            }}
          >
            <img
              src={Mask2Img}
              style={{
                display: "block",
                maxWidth: "100%",
                width: "initial",
                height: "initial",
                background: "none",
                opacity: "1",
                border: "0px solid",
                margin: "0px",
                padding: "0px",
              }}
            />
          </span>
          <img
            src={serviceType}
            style={{
              borderRadius: "2rem",
              opacity: "1",
              zIndex: "2",
              position: "absolute",
              inset: "0px",
              padding: "0px",
              margin: "auto",
              display: "block",
              width: "0px",
              height: "0px",
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%",
              objectFit: "cover",
              left: "10px",
              top: "20px",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "0",
          top: "18px",
          width: "270px",
          left: "14px",
        }}
      >
        <img src={MaskImg} width={"100%"} height={"auto"} />
      </Box>
    </>
  );
}
export default function AdditionalServices() {
  return (
    <>
      <Typography className="title" variant="h4">
        Services Categories
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Services"]} />

      <Container sx={{ marginTop: "2rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            marginBottom: "4rem",
            marginTop: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              order: { xs: "2", md: "1" },
            }}
          >
            <Typography sx={{ fontSize: "24px" }}>Flight Tickets </Typography>
            <Typography sx={{ fontSize: "16px" }}>
              Londonist DMC has partnered with KAYAK, a leading travel search
              engine, to bring you a seamless flight booking experience right
              from our portal. Whether planning a trip back home or an adventure
              to a new destination, booking your flights has never been easier.
              One-stop travel solution: Book flights effortlessly alongside your
              other travel plans. Exclusive deals: Access special offers and
              discounts just for our students. User-friendly: Find the best
              flights with a few simple clicks.
            </Typography>
            <Button
              target="_blank"
              href="https://www.kayak.co.uk/?utm_source=tradetracker&utm_medium=affiliate&utm_campaign=472424&utm_content=32925"
              sx={{
                marginTop: { xs: "1.5rem", md: "1.5rem" },
                width: { xs: "100%", md: "200px" },
              }}
              variant="contained"
              color="primary"
            >
              View
            </Button>
          </Box>
          <Box
            sx={{
              marginLeft: { xs: "0", md: "2rem" },
              order: { xs: "1", md: "2" },
              position: "relative",
            }}
          >
            <MaskedImg serviceType={KayakImg} />
          </Box>
        </Box>

        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
          <Box
            sx={{ position: "relative", marginRight: { xs: "0", md: "2rem" } }}
          >
            <MaskedImg serviceType={AirportImg} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontSize: "24px" }}>Airport Transfer</Typography>
            <Typography sx={{ fontSize: "16px" }}>
              For students arriving in or departing from London, our airport
              transfer service offers comfortable and reliable journeys in a
              variety of car options. Book with us for a stress-free start to
              your studies.
            </Typography>
            <Button
              href="/additional-services/airport"
              sx={{
                marginTop: { xs: "1.5rem", md: "1.5rem" },
                width: { xs: "100%", md: "200px" },
              }}
              variant="contained"
              color="primary"
            >
              View
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            marginTop: "4rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              order: { xs: "2", md: "1" },
            }}
          >
            <Typography sx={{ fontSize: "24px" }}>Cleaning Services</Typography>
            <Typography sx={{ fontSize: "16px" }}>
              Our cleaning service offers a range of professional cleaning
              solutions to meet all your home and office needs. Whether you
              require a one-time deep clean or ongoing maintenance, we have a
              service that's right for you.
            </Typography>
            <Button
              href="/additional-services/cleaning-services"
              sx={{
                marginTop: { xs: "1.5rem", md: "1.5rem" },
                width: { xs: "100%", md: "200px" },
              }}
              variant="contained"
              color="primary"
            >
              View
            </Button>
          </Box>
          <Box
            sx={{
              marginLeft: { xs: "0", md: "2rem" },
              order: { xs: "1", md: "2" },
              position: "relative",
            }}
          >
            <MaskedImg serviceType={CleaningImg} />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            marginTop: "4rem",
          }}
        >
          <Box sx={{ marginRight: "2rem", position: "relative" }}>
            <MaskedImg serviceType={LanguageCertImg} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontSize: "24px" }}>
              LANGUAGECERT Academic
            </Typography>
            <Typography sx={{ fontSize: "16px" }}>
              {htmlParser(
                "<strong>LANGUAGECERT Academic</strong> is a high stake, 4-skill multilevel English test with enhanced academic focus for undergraduate and postgraduate students who need to provide evidence of language proficiency for admission to higher education. <br/> <strong>  Use this promo code to receive 10% discount when you book a LANGUAGECERT test. Simply enter code 8127CD in the 'Promo Code' box when you proceed to check out. </strong>"
              )}
            </Typography>
            <Button
              href="https://www.languagecert.org/en/language-exams/english/languagecert-academic/academic"
              target="_blank"
              sx={{
                marginTop: { xs: "1.5rem", md: "1.5rem" },
                width: { xs: "100%", md: "200px" },
              }}
              variant="contained"
              color="primary"
            >
              View
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            marginTop: "4rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              order: { xs: "2", md: "1" },
            }}
          >
            <Typography sx={{ fontSize: "24px" }}>Storage Service</Typography>
            <Typography sx={{ fontSize: "16px" }}>
              Storage services is available for you to provide a convenient
              solution for our students to store their luggage and bags for a
              short period.
            </Typography>
            <Button
              disabled
              href="javascript:;"
              sx={{
                marginTop: { xs: "1.5rem", md: "1.rem" },
                width: { xs: "100%", md: "200px" },
              }}
              variant="contained"
              color="primary"
            >
              View
            </Button>
          </Box>
          <Box
            sx={{
              marginLeft: { xs: "0", md: "2rem" },
              order: { xs: "1", md: "2" },
              position: "relative",
            }}
          >
            <MaskedImg serviceType={StorageServiceImg} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            marginTop: "4rem",
          }}
        >
          <Box sx={{ marginRight: "2rem", position: "relative" }}>
            <MaskedImg serviceType={LaundryServiceImg} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontSize: "24px" }}>Laundry Service</Typography>
            <Typography sx={{ fontSize: "16px" }}>
              Our laundry service offers a convenient and reliable way to take
              care of your laundry needs. Our experienced team uses high-quality
              detergents and equipment to ensure that your clothes are clean,
              fresh, and well cared for.
            </Typography>
            <Button
              disabled
              href="javascript:;"
              sx={{
                marginTop: { xs: "1.5rem", md: "1.5rem" },
                width: { xs: "100%", md: "200px" },
              }}
              variant="contained"
              color="primary"
            >
              View
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}
