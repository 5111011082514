import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Link, Outlet, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SilverBadge from "../assets/images/badges/silver.svg";
import PlatinumBadge from "../assets/images/badges/platinum.svg";
import GoldBadge from "../assets/images/badges/gold.svg";
import BronzeBadge from "../assets/images/badges/bronze.svg";

import {
  MainListItems,
  SecondaryListItems,
  mainListItems,
  secondaryListItems,
} from "./ListItems";
import DashboardMain from "./DashboardMain/DashboardMain";
import MyDocs from "../pages/MyDocs";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { LoginContext } from "../contexts/loginContext";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import {
  Backdrop,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";
import logoMini from "../assets/images/logo-mini.svg";
import CartHeader from "./CartHeader/CartHeader";
import { useDispatch, useSelector } from "react-redux";
import { setTokens } from "../redux/actions/tokenActions";
import logoWhite from "../assets/images/logo-white.svg";
import DarkLightMode from "./common/DarkLightMode/DarkLightMode";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { light } from "@mui/material/styles/createPalette";
import { useRef } from "react";
import { setProfile } from "../redux/actions/cartActions";
import axiosInstance from "../axiosInstance";
import Branches from "./DashboardMain/Branches/Branches";
import {
  setAgencyArray,
  setBranchesArray,
  setSelectedBranchesString,
} from "../redux/actions/branchActions";
import Swal from "sweetalert2";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerWidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, lightMode, drawerWidth }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: lightMode ? theme.palette.customRed.dark : "#292929",
    color: "#ffffff",
    position: "fixed",
    top: 0,
    bottom: 0,
    whiteSpace: "nowrap",
    width: drawerWidth,
    height: "100%",
    zIndex: "99999",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: 9999, // Bu, drawer'dan hemen önce yerleştirir.
  color: "#fff",
}));

export default function Dashboard({ open, setOpen, isMobile, setIsMobile }) {
  const token = useSelector((state) => state.token); // Filtrelenmiş ürünleri al
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const [drawerWidth, setDrawerWidth] = React.useState(280);
  const profile = useSelector((state) => state.profile);
  const branchesArray = useSelector((state) => state?.branches?.branches);

  React.useEffect(() => {
    if (open) {
      setDrawerWidth(280);
    } else {
      if (isMobile) {
        setDrawerWidth(0);
      } else {
        setDrawerWidth(56);
      }
    }
  }, [open, isMobile]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch();
  // Resize event listener
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 999);
    };

    // Event listener'ı ekleyin
    window.addEventListener("resize", handleResize);

    // Cleanup function olarak event listener'ı kaldırın
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Bu useEffect'i sadece bileşen mount olduğunda çalıştırmak için boş bir bağımlılık dizisi verin.

  React.useEffect(() => {
    if (isMobile) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isMobile]);
  const theme = useTheme();

  const loginControl = React.useContext(LoginContext);

  const logOutFunc = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    Swal.fire({
      icon: "info",
      title: "Logging Out...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/logout`,
        {
          refresh: token,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        }
      );
      dispatch(setTokens(null, null));
      dispatch(setProfile(null));
      dispatch(setSelectedBranchesString([]));
      dispatch(setBranchesArray([]));
      dispatch(setAgencyArray([]));
      localStorage.removeItem("profile");
      localStorage.removeItem("agency");
      localStorage.removeItem("branches-string");
      localStorage.removeItem("branches");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("loginUser");
      Swal.close();
      navigate("/login");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // Sunucunun döndürdüğü hata yanıtı
        console.log("Error response:", error.response.data);
        console.log("Error status:", error.response.status);
        console.log("Error headers:", error.response.headers);
      } else {
        // Hata yanıtı alınamazsa
        console.log("Error message:", error.message);
      }
    }
  };

  const navigate = useNavigate();

  const [personShow, setPersonShow] = React.useState(false);
  const profileRef = React.useRef(null);
  // Dış tıklamaları kontrol etmek için bir fonksiyon
  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setPersonShow(false);
    }
  };

  React.useEffect(() => {
    // Dış tıklamaları dinlemek için olay dinleyicisini ekleyin
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Component unmount edildiğinde olay dinleyicisini kaldırın
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        overflow: isMobile && open ? "hidden" : "auto",
        maxHeight: isMobile && open ? "100vh" : "auto",
      }}
    >
      <CssBaseline />
      <AppBar
        sx={{ backgroundColor: "#ffffff" }}
        position="fixed"
        open={open}
        drawerWidth={drawerWidth}
      >
        <Toolbar
          sx={{
            backgroundColor: lightMode ? "#ffffff" : "#292929",

            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={toggleDrawer}
            // sx={{
            //   ...(open && { display: "none" }),
            // }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Agency Portal
                    </Typography> */}
          {/* <Link to={"/payment/shop"}>Cart</Link> */}
          {/* {isMobile ? ( */}
          {profile?.profile ? (
            profile?.profile?.agent_name ? (
              <Box
                sx={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{ color: lightMode ? "#000000" : "#ffffff" }}
                    variant="body2"
                  >
                    Welcome
                  </Typography>
                  <Typography
                    sx={{ color: lightMode ? "#444444" : "#ffffff" }}
                    variant={isMobile ? "caption" : "h6"}
                  >
                    {profile?.profile?.agent_name}
                  </Typography>
                </Box>
                {profile?.profile?.badge ? (
                  <Tooltip
                    title={profile?.profile?.badge}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      width={"64"}
                      src={
                        profile?.profile?.badge
                          ?.toLocaleLowerCase()
                          .includes("gold")
                          ? GoldBadge
                          : profile?.profile?.badge
                              ?.toLocaleLowerCase()
                              .includes("silver")
                          ? SilverBadge
                          : profile?.profile?.badge
                              ?.toLocaleLowerCase()
                              .includes("platinum")
                          ? PlatinumBadge
                          : profile?.profile?.badge
                              ?.toLocaleLowerCase()
                              .includes("bronze")
                          ? BronzeBadge
                          : null
                      }
                    />
                  </Tooltip>
                ) : null}
              </Box>
            ) : null
          ) : (
            <Box sx={{ width: 120, height: 40 }}>
              <Skeleton sx={{ height: "100%" }} animation="wave" />
            </Box>
          )}
          {/* ) : null} */}
          <DarkLightMode />
          <CartHeader />

          {isMobile ? null : (
            <>
              <Box
                ref={profileRef}
                sx={{
                  position: "relative",
                  marginLeft: "2rem",
                  background: "#bf0000",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "100%",
                }}
              >
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => setPersonShow(!personShow)}
                >
                  <PersonIcon sx={{ fontSize: "32px", color: "#ffffff" }} />
                </Box>
                {personShow ? (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "60px",
                      right: "0",
                      width: "200px",
                      height: "auto",
                      borderRadius: ".5rem",
                      background: lightMode ? "#ffffff" : "#121212",
                      border: `1px solid ${lightMode ? `#efefef` : `#525252`}`,
                    }}
                  >
                    <Link
                      onClick={() => setPersonShow(false)}
                      to={!token.accessToken ? "/login" : "/profile"}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        textDecoration: "none",
                        borderBottom: "1px solid #efefef",
                        padding: ".5rem",
                      }}
                    >
                      <IconButton color="inherit">
                        <SettingsIcon />
                      </IconButton>
                      <Typography variant="h6">Profile</Typography>{" "}
                    </Link>
                    <Link
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        textDecoration: "none",
                        padding: ".5rem",
                      }}
                      onClick={() => {
                        if (localStorage.getItem("accessToken")) {
                          logOutFunc();
                        }
                      }}
                      to={!token.accessToken && "/login"}
                    >
                      <IconButton color="inherit">
                        {token.accessToken ? <LogoutIcon /> : <LoginIcon />}
                      </IconButton>
                      <Typography variant="h6">
                        {token.accessToken ? `Log Out` : `Log In`}
                      </Typography>
                    </Link>
                  </Box>
                ) : null}
              </Box>
            </>
            // <Link
            //   onClick={() =>
            //     loginControl.setLoginControl(
            //       !loginControl.loginControl,
            //       loginControl.loginControl && logOutFunc()
            //     )
            //   }
            //   style={{
            //     display: "flex",
            //     alignItems: "center",
            //     color: "#000000",
            //     textDecoration: "none",
            //     marginLeft: "2rem",
            //   }}
            //   to={
            //     localStorage.getItem("loginControl")
            //       ? loginControl.loginControl ||
            //         loginControl.loginControl === undefined
            //         ? `/`
            //         : `/login`
            //       : loginControl.loginControl
            //       ? `/`
            //       : `/login`
            //   }
            // >
            //   <IconButton color="inherit">
            //     {localStorage.getItem("loginControl") ? (
            //       loginControl.loginControl ||
            //       loginControl.loginControl === undefined ? (
            //         <LogoutIcon />
            //       ) : (
            //         <LoginIcon />
            //       )
            //     ) : loginControl.loginControl ? (
            //       <LogoutIcon />
            //     ) : (
            //       <LoginIcon />
            //     )}
            //   </IconButton>
            //   <Typography variant="h6">
            //     {localStorage.getItem("loginControl")
            //       ? loginControl.loginControl ||
            //         loginControl.loginControl === undefined
            //         ? `Log Out`
            //         : `Log In`
            //       : loginControl.loginControl
            //       ? `Log Out`
            //       : `Log In`}
            //   </Typography>
            // </Link>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{ position: "sticky", zIndex: "99999", backgroundColor: "green" }}
        variant="permanent"
        open={open}
        lightMode={lightMode}
        drawerWidth={drawerWidth}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: [1],
          }}
        >
          <a href="/" style={{ with: "fit-content" }}>
            <img
              style={{ marginLeft: "auto", marginRight: "auto" }}
              width={!open ? (isMobile ? "100" : "30") : "100"}
              src={!open ? (isMobile ? logoWhite : logoMini) : logoWhite}
            />
          </a>
          {/* {open ? (
            <img
              style={{ marginLeft: "auto", marginRight: "auto" }}
              width={100}
              src={logoWhite}
            />
          ) : (
            <img
              style={{ marginLeft: "auto", marginRight: "auto" }}
              width={30}
              height={30}
              src={logoMini}
            />
          )} */}

          {/* <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon sx={{ color: "#ffffff" }} />
          </IconButton> */}
        </Toolbar>
        <Divider />
        <List component="nav">
          <Branches open={open} />
          <MainListItems open={open} setOpen={setOpen} />
          <Divider sx={{ my: 1 }} />
        </List>
      </Drawer>

      {isMobile && (
        <CustomBackdrop
          open={open}
          onClick={toggleDrawer} // Bu, backdrop'a tıklanıldığında drawer'ı kapatacaktır.
        />
      )}

      <Box
        component="main"
        sx={{
          // backgroundColor: (theme) =>
          //     theme.palette.mode === 'light'
          //         ? theme.palette.grey[100]
          //         : theme.palette.grey[900],
          background: lightMode ? "#ffffff" : "#1e1e1e",
          flexGrow: 1,
          minHeight: "100vh",
          overflow: isMobile && open ? "hidden" : "auto",
          minWidth: isMobile && open ? "100vw" : "unset",
          // marginLeft: theme.spacing(0),
          // [theme.breakpoints.up("sm")]: {
          //   marginLeft: `${drawerWidth}px`,
          // },
          marginLeft:
            open && !isMobile ? `${drawerWidth}px` : !isMobile ? "56px" : "0px",
          transition: "margin-left .34s",
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 4, mb: 8 }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}
