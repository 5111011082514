import { Box, Skeleton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectTotalPrice,
  selectTotalPriceServices,
} from "../../redux/selectors/selectors";
import { useEffect, useState } from "react";
import axios from "axios";
import { getTheme } from "../../styles/theme";

export default function Basket({
  invoiceId,
  bookingId,
  cartItems,
  loading,
  paymentType,
  totalPrice,
  setTotalPrice,
}) {
  const totalPriceAdditionalServices = useSelector(
    paymentType === "services" ? selectTotalPriceServices : selectTotalPrice
  );

  const [skeleton, setSekeleton] = useState(true);

  useEffect(() => {
    if (invoiceId || bookingId) {
      if (Object.keys(cartItems)?.length) {
        setSekeleton(false);
      }
    }

    if (cartItems?.length) {
      let totalPriceInvoices =
        cartItems?.reduce((total, cartItem) => {
          return total + Number(cartItem.payment_amount);
        }, 0) || 0;

      if (invoiceId) {
        setTotalPrice(totalPriceInvoices);
      } else if (bookingId) {
        setTotalPrice(cartItems.total_price);
      } else {
        if (paymentType === "services") {
        }
        setTotalPrice(totalPriceAdditionalServices);
      }
    }
  }, [invoiceId, bookingId, cartItems]);

  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const paraFormat = (number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(number);
  };

  return (
    <Box
      sx={{
        borderRadius: "1rem",
        height: "100%",
        backgroundColor: lightMode ? "#FCFCFC" : "#525252",
        filter: "drop-shadow(0px 3px 6px #00000029)",
        padding: { xs: "1rem", lg: "2rem" },
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          borderBottom: "1px solid #e6e6e6",
          paddingBottom: ".5rem",
          marginBottom: "2rem",
        }}
      >
        {invoiceId
          ? `
            Invoice Number: ${invoiceId}
        `
          : bookingId
          ? `
        Booking Number: ${bookingId}
    `
          : "Your Basket"}
      </Typography>
      {!invoiceId && !bookingId ? (
        cartItems?.length ? (
          cartItems?.map((cartItem, idx) => {
            return (
              <Box
                key={cartItem.service_id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: idx + 1 !== cartItems?.length ? "1rem" : "0",
                }}
              >
                <Box
                  sx={{
                    marginRight: "0.5rem",
                    borderRadius: "10rem",
                    background: "#ebebeb",
                    minWidth: "56px",
                    minHeight: "56px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    filter: "drop-shadow(0px 3px 6px #00000029)",
                  }}
                >
                  <img
                    src={cartItem.image}
                    width={50}
                    height={50}
                    style={{ borderRadius: "100%" }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { xs: "flex-start", md: "center" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",

                      lineHeight: "20px",
                    }}
                  >
                    {cartItem.service_name}
                    {cartItem?.selectedSize
                      ? `- ` + cartItem?.selectedSize
                      : ``}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      marginLeft: { xs: "unset", md: "auto" },
                      marginTop: { xs: ".5rem", md: "unset" },
                      lineHeight: "20px",
                    }}
                  >
                    £{cartItem.price} x {cartItem.quantity}
                  </Typography>
                </Box>
              </Box>
            );
          })
        ) : (
          `Your basket is empty`
        )
      ) : (
        <>
          {loading ? (
            <Box>
              {Array.from({ length: 5 }, (_, index) => (
                <Skeleton
                  key={index}
                  sx={{
                    height: index === 4 ? "50px" : "30px",
                    marginTop: index === 4 ? "2rem" : "0",
                  }}
                  animation="wave"
                />
              ))}
            </Box>
          ) : cartItems?.payments?.length && !cartItems?.services?.length ? (
            <>
              {Object.keys(cartItems?.campus)?.length ? (
                <Box
                  sx={{
                    marginBottom: "1rem",
                  }}
                >
                  {skeleton ? (
                    <Skeleton>
                      <Typography>{cartItems?.campus?.campus_name}</Typography>
                    </Skeleton>
                  ) : (
                    <Box sx={{ display: "flex" }}>
                      <img
                        style={{ borderRadius: ".5rem", objectFit: "cover" }}
                        src={cartItems?.campus?.logo}
                        width={100}
                        height={100}
                      />
                      <Typography variant="h6" sx={{ marginLeft: "1rem" }}>
                        {cartItems?.campus?.campus_name}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : null}
              {cartItems?.payments?.map((cartItem, idx) => {
                return cartItem.outstanding_amount !== 0 ? (
                  <Box
                    key={`${cartItem?.payment_id} - ${idx}`}
                    sx={{
                      marginBottom: "1rem",
                      borderBottom: `${
                        idx + 1 !==
                        cartItems.payments.filter((cartItem, idx) => {
                          return cartItem.outstanding_amount !== 0;
                        })?.length
                          ? "1px solid #e6e6e6"
                          : "unset"
                      }`,
                      paddingBottom: `${
                        idx + 1 !==
                        cartItems.payments.filter((cartItem, idx) => {
                          return cartItem.outstanding_amount !== 0;
                        })?.length
                          ? "1rem"
                          : "0"
                      }`,
                    }}
                  >
                    {skeleton ? (
                      <Skeleton>
                        <Typography>
                          Payment ID: {cartItem.payment_id}
                        </Typography>
                      </Skeleton>
                    ) : (
                      <>
                        <Typography>
                          Payment ID: {cartItem.payment_id}
                        </Typography>
                        <Typography>
                          Payment Type: {cartItem.payment_type}
                        </Typography>
                        <Typography>Due Date: {cartItem.due_date}</Typography>
                        <Typography>
                          Outstanding Amount: {cartItem.outstanding_amount}
                        </Typography>
                      </>
                    )}
                  </Box>
                ) : null;
              })}
            </>
          ) : cartItems?.room_information ? (
            <Box
              sx={{
                marginBottom: "1rem",
              }}
            >
              {skeleton ? (
                <Skeleton>
                  <Typography>{cartItems?.room_information?.name}</Typography>
                </Skeleton>
              ) : (
                <>
                  <Box sx={{ display: "flex" }}>
                    <img
                      style={{ borderRadius: ".5rem", objectFit: "cover" }}
                      src={cartItems?.room_information?.image}
                      width={100}
                      height={100}
                    />
                    <Typography variant="h6" sx={{ marginLeft: "1rem" }}>
                      {cartItems?.room_information?.name}
                    </Typography>
                  </Box>
                  {Object.keys(cartItems?.services).length ? (
                    <Box
                      sx={{
                        marginTop: "1rem",
                        borderTop: "1px solid #efefef",
                        paddingTop: "1rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Typography variant="h6">
                          {cartItems?.services?.service_name}
                        </Typography>
                        <Typography variant="h6" sx={{ marginLeft: "auto" }}>
                          {paraFormat(cartItems?.services?.service_amount)}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography variant="h6">
                          Accommodation Amount
                        </Typography>
                        <Typography variant="h6" sx={{ marginLeft: "auto" }}>
                          {paraFormat(cartItems?.total_price)}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}

                  {cartItems?.promotion_amount ? (
                    <Box
                      sx={{
                        backgroundColor: "#0080002b",
                        padding: "1rem",
                        marginTop: "1rem",
                        borderRadius: "1rem",
                      }}
                    >
                      {cartItems?.promotion_amount ? (
                        <Box>
                          <Typography>
                            <strong> Promotion Amount: </strong>{" "}
                            {paraFormat(cartItems?.promotion_amount)}
                          </Typography>
                        </Box>
                      ) : null}
                      {cartItems?.promotion_percentage ? (
                        <Box>
                          <Typography>
                            <strong>Promotion Percentage: </strong>%
                            {cartItems?.promotion_percentage}
                          </Typography>
                        </Box>
                      ) : null}
                      {cartItems?.promotion_fixed ? (
                        <Box>
                          <Typography>
                            <strong>Promotion Fixed: </strong>
                            {cartItems?.promotion_fixed}
                          </Typography>
                        </Box>
                      ) : null}
                      {cartItems?.discounted_price ? (
                        <Box>
                          <Typography>
                            <strong>Discounted Price: </strong>
                            {paraFormat(cartItems?.discounted_price)}
                          </Typography>
                        </Box>
                      ) : null}
                      {cartItems?.promotion_name ? (
                        <Box>
                          <Typography>
                            <strong> Promotion Name:</strong>{" "}
                            {cartItems?.promotion_name}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                  ) : null}
                </>
              )}
            </Box>
          ) : cartItems?.services?.length ? (
            cartItems?.services?.map((cartItem, idx) => {
              return (
                <Box
                  key={idx}
                  sx={{
                    marginBottom: "1rem",
                  }}
                >
                  {skeleton ? (
                    <Skeleton>
                      <Typography>{cartItem?.service?.service_name}</Typography>
                    </Skeleton>
                  ) : (
                    <>
                      <Box sx={{ display: "flex" }}>
                        <img
                          style={{ borderRadius: ".5rem", objectFit: "cover" }}
                          src={cartItem?.service?.image}
                          width={100}
                          height={100}
                        />
                        <Typography variant="h6" sx={{ marginLeft: "1rem" }}>
                          {cartItem?.service?.service_name}
                        </Typography>
                      </Box>
                      {cartItem?.details ? (
                        <Box sx={{ marginTop: "1rem" }}>
                          {cartItem?.details &&
                          cartItem?.details?.flight_number ? (
                            <>
                              <Typography variant="h6">
                                <strong>To:</strong> {cartItem?.details?.to}
                              </Typography>
                              <Typography variant="h6">
                                <strong>From:</strong> {cartItem?.details?.from}
                              </Typography>
                              <Typography variant="h6">
                                <strong>People Count:</strong>{" "}
                                {cartItem?.details?.people}
                              </Typography>
                              <Typography variant="h6">
                                <strong>Flight Number</strong>{" "}
                                {cartItem?.details?.flight_number}
                              </Typography>
                              <Typography variant="h6">
                                <strong>Transportation Date Time: </strong>{" "}
                                {cartItem?.details?.transportation_date_time}
                              </Typography>
                              {/* <Typography variant="h6">
                                <strong>Travel Type: </strong>{" "}
                                {cartItem?.details?.travel_type}
                              </Typography> */}
                            </>
                          ) : cartItem?.details?.cleaning_date ? (
                            <Typography variant="h6">
                              <strong>Cleaning Date: </strong>{" "}
                              {cartItem?.details?.cleaning_date}
                            </Typography>
                          ) : null}
                        </Box>
                      ) : null}
                    </>
                  )}
                </Box>
              );
            })
          ) : cartItems?.details ? (
            <Box
              sx={{
                marginBottom: "1rem",
              }}
            >
              {skeleton ? (
                <Skeleton>
                  <Typography></Typography>
                </Skeleton>
              ) : (
                <>
                  {cartItems?.details ? (
                    <Box sx={{ marginTop: "1rem" }}>
                      <Typography variant="h6">
                        <strong>From:</strong>{" "}
                        {cartItems?.details?.travel_type === "Arrival"
                          ? cartItems?.details?.service_name
                          : cartItems?.details?.residence_name}
                      </Typography>
                      <Typography variant="h6">
                        <strong>To:</strong>{" "}
                        {cartItems?.details?.travel_type === "Arrival"
                          ? cartItems?.details?.residence_name
                          : cartItems?.details?.service_name}
                      </Typography>
                      <Typography variant="h6">
                        <strong>People Count:</strong>{" "}
                        {cartItems?.details?.people_count}
                      </Typography>
                      <Typography variant="h6">
                        <strong>Flight Number: </strong>{" "}
                        {cartItems?.details?.flight_number}
                      </Typography>
                      <Typography variant="h6">
                        <strong>Transportation Date Time: </strong>{" "}
                        {cartItems?.details?.transportation_date_time}
                      </Typography>
                      <Typography variant="h6">
                        <strong>Travel Type: </strong>{" "}
                        {cartItems?.details?.travel_type}
                      </Typography>
                    </Box>
                  ) : null}
                </>
              )}
            </Box>
          ) : null}
        </>
      )}
      {!invoiceId && !bookingId ? (
        cartItems?.length ? (
          <Typography
            color="primary"
            sx={{
              fontSize: "24px",
              marginTop: { xs: "1.5rem", lg: "3rem" },
            }}
          >
            Total Price £{totalPrice}
          </Typography>
        ) : null
      ) : cartItems?.payments?.length ? (
        <Typography
          color="primary"
          sx={{
            fontSize: "24px",
            marginTop: { xs: "1.5rem", lg: "3rem" },
          }}
        >
          Total Price £{cartItems.total_amount}
        </Typography>
      ) : cartItems?.details ? (
        <Typography
          color="primary"
          sx={{
            fontSize: "24px",
            marginTop: { xs: "1.5rem", lg: "3rem" },
          }}
        >
          Total Price £{cartItems?.payment?.total_amount}
        </Typography>
      ) : (
        <Typography
          color="primary"
          sx={{
            fontSize: "24px",
            marginTop: { xs: "1.5rem", lg: "3rem" },
          }}
        >
          Total Price
          {cartItems.services
            ? Object.keys(cartItems?.services)?.length
              ? cartItems?.promotion_amount
                ? paraFormat(
                    cartItems.discounted_price +
                      cartItems?.services?.service_amount
                  )
                : paraFormat(
                    cartItems.total_price + cartItems?.services?.service_amount
                  )
              : cartItems?.promotion_amount
              ? paraFormat(cartItems.discounted_price)
              : paraFormat(cartItems.total_price)
            : null}
        </Typography>
      )}
    </Box>
  );
}
