import React, { useContext, useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { LondonData } from "./data/london";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../styles/theme";
import ResidencesContext from "../../../../contexts/Residences/ResidencesContext";
import { useMapAnimation } from "./useMapAnimation";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
import { getAllLocationsOnMap } from "./getAllLocationsOnMap";
import { getDirections } from "./getDirections";
import { getSelectedLocationOnMap } from "./getSelectedLocationOnMap";
import { loadMap } from "./loadMap";
import { ArrowDownward } from "@mui/icons-material"; // Material UI ikonu
import ReactDOM from "react-dom";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function Map({ mapRef, open, setOpen, isMobile, setIsMobile }) {
  const mapContainer = useRef(null);

  const [lng, setLng] = useState(-0.1278);
  const [lat, setLat] = useState(51.5074);
  const [zoom, setZoom] = useState(8.5);

  const {
    locations,
    nearByUniversities,
    selectedLocation,
    universityTabControl,
    disabledControl,
    setDisabledControl,
    setSelectedLocation,
    publicType,
    setPublicType,

    setNearByUniversities,
    selectedLocationNearBy,
  } = useContext(ResidencesContext);

  //LOAD MAP START
  useEffect(() => {
    loadMap(
      mapRef,
      locations,
      mapContainer,
      lng,
      lat,
      zoom,
      setDisabledControl,
      setLng,
      setLat,
      setZoom
    );
  }, [locations]);
  //LOAD MAP END

  const markers = useRef([]);
  const markersResidences = useRef([]);

  useEffect(() => {
    // console.log(open, "open here");
    if (mapRef.current) {
      // Drawer kapandığında haritayı yeniden boyutlandır
      window.setTimeout(() => mapRef.current.resize(), 500);

    }
  }, [open, mapRef]);

  //GET ALL LOCATIONS ON MAP START
  useEffect(() => {
    getAllLocationsOnMap(
      markersResidences,
      mapRef,
      locations,
      selectedLocation,
      universityTabControl
    );
  }, [locations, selectedLocation, universityTabControl]);
  //GET ALL LOCATIONS ON MAP END

  useEffect(() => {
    if (selectedLocationNearBy === null) {
      // MapboxDirections kontrolünü kaldır
      let directions = mapRef.current._controls.find(
        (control) => control instanceof MapboxDirections
      );

      if (directions) {
        mapRef.current.removeControl(directions);
      }
    }
  }, [selectedLocationNearBy]);

  // GET DIRECTIONS START
  useEffect(() => {
    getDirections(
      mapRef,
      selectedLocation,
      selectedLocationNearBy,
      publicType,
      universityTabControl
    );
  }, [
    publicType,
    selectedLocationNearBy,
    selectedLocation,
    mapRef.current,
    universityTabControl,
  ]);
  // GET DIRECTIONS END

  //GET SELECTED LOCATION ON MAP START
  useEffect(() => {
    getSelectedLocationOnMap(
      markers,
      markersResidences,
      mapRef,
      selectedLocation,
      nearByUniversities,
      universityTabControl,
      locations,
      publicType
    );
  }, [
    selectedLocation,
    nearByUniversities,
    universityTabControl,
    locations,
    publicType,
  ]);
  //GET SELECTED LOCATION ON MAP END

  return (
    <Box
      ref={mapContainer}
      className="map-container locations"
      sx={{
        ".mapboxgl-canvas": {
          minWidth: "100%",
        },
        minHeight: "500px",
        height: "100%",
        width: "100%",
        borderRadius: "1rem",
        overflow: "hidden",
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          visibility: "hidden",
          bottom: "0",
          width: "100%",
          height: "30px",
          background: "#000000",
        },
      }}
    ></Box>
  );
}

export default Map;
