import { useTheme } from "@emotion/react";
import {
  Box,
  InputAdornment,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { BorderColor } from "@mui/icons-material";
import ResidencesContext from "../../../../contexts/Residences/ResidencesContext";
import CloseIcon from "@mui/icons-material/Close";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            color: "#444444 !important",
            margin: "0 !important",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: "1rem 1rem 1rem 0 !important",
            color: "#444444 !important",
            "&::placeholder": {
              color: "#444444 !important",
            },
          },
          root: {
            paddingLeft: "1rem !important",
            background: "#ffffff !important",
            borderRadius: "1rem !important",
            overflow: "hidden",
            "&.MuiFilledInput-root:after": {
              border: "1px solid #800000 !important",
            },
          },
        },
      },
    },
  });

const SearchResidences = ({ mapRef, moveToLocation }) => {
  const outerTheme = useTheme();
  const {
    setSearchedResidence,
    setSelectedLocation,
    setNearByUniversities,
    selectedLocation,
    universityTabControl,
    searchTerm,
    setSearchTerm,
    setDisabledControl,
    setSelectedLocationNearBy,
    setPublicType,
  } = useContext(ResidencesContext);

  const handleSearchResidence = () => {
    setSearchedResidence(searchTerm);
  };

  const clearSearchResidence = () => {
    setSearchedResidence("");
    setSearchTerm("");
    setSelectedLocation("");
    setNearByUniversities(null);
    setSelectedLocation("");
    setSelectedLocationNearBy(null);
    setPublicType(null);
    // mapRef.current.flyTo({
    //   center: [-0.1278, 51.5074],
    //   essential: true,
    //   zoom: 8.5,
    // });
    mapRef.current.once("movestart", () => {
      // setDisabledControl(true);
    });
    mapRef.current.once("moveend", () => {
      setDisabledControl(false);
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearchResidence();
    }
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <Box
        component="form"
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          "& > :not(style)": { m: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          placeholder={
            universityTabControl ? "Search University" : "Search Residence"
          }
          variant="filled"
          value={
            searchTerm ||
            selectedLocation?.campus_name ||
            selectedLocation?.university_title ||
            ""
          }
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress} // Enter tuşuna basıldığında arama yap
          InputProps={{
            endAdornment: (
              <InputAdornment
                onClick={() =>
                  selectedLocation
                    ? clearSearchResidence()
                    : handleSearchResidence()
                }
                sx={{
                  cursor: "pointer",
                  visibility:
                    searchTerm || selectedLocation ? "visible" : "hidden",
                }}
                position="start"
              >
                {selectedLocation ? <CloseIcon /> : <SearchIcon />}
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </ThemeProvider>
  );
};

export default SearchResidences;
