import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ImageSingle from "./Gallery/ImageSingle";

const ResidenceRoomDetails = ({
  data,
  openRoomDetails,
  setOpenRoomDetails,
}) => {
  const [style, setStyle] = useState(null);
  const handleCloseResidenceRoomDetails = () => setOpenRoomDetails(false);
  const descriptionRef = useRef(null);
  const [openImageSingle, setOpenImageSingle] = useState(false);
  const [allDescription, setAllDescription] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [descCharSet, setDescCharSet] = useState(null);

  const handleOpenImageSingle = (data) => {
    setOpenImageSingle(true);
    setSelectedImage(data);
  };

  // const extractTextFromHtml = (htmlString) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlString, "text/html");
  //   return doc.body.textContent || "";
  // };

  const extractTextFromHtml = (description) => {
    return <div dangerouslySetInnerHTML={{ __html: description }} />;
  };

  const initialStyle = {
    overflow: "auto !important",
    position: "absolute",
    outline: "none",
    border: "none",
    top: "150%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    maxHeight: "90vh",
    height: "fit-content",
    maxWidth: "700px",
    borderRadius: "2rem",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    transition: "all .4s",
  };

  useEffect(() => {
    if (data?.course_description) {
      setDescCharSet(
        Object.entries(extractTextFromHtml(data?.course_description))?.length
      );
    }
  }, [data]);

  useEffect(() => {
    if (openRoomDetails) {
      setStyle((prev) => ({
        ...prev,
        top: "50%",
      }));
    } else {
      setStyle(initialStyle);
    }
  }, [openRoomDetails]);

  const handleReadMore = () => {
    setAllDescription(!allDescription);
  };

  return (
    style && (
      <>
        <Modal
          open={openRoomDetails}
          onClose={handleCloseResidenceRoomDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CancelIcon
              onClick={handleCloseResidenceRoomDetails}
              sx={{
                fontSize: "36px",
                color: "#ffffff",
                filter: "drop-shadow(0 0 3px #800000)",
                position: "absolute",
                top: "16px",
                right: "16px",
                cursor: "pointer",
                transition: "all .4s",
                "&:hover": {
                  color: "#efefef",
                  transition: "all .4s",
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "700",
                  borderBottom: "1px solid #efefef",
                  paddingBottom: "1rem",
                  marginBottom: "1rem",
                }}
              >
                {data?.course_name}
              </Typography>
              <Grid container spacing={3} sx={{ order: { xs: "3", md: "2" } }}>
                {data?.images?.map((image, idx) => {
                  return (
                    <Grid key={idx} item xs={12} md={3} sx={{}}>
                      <Box
                        onClick={() => handleOpenImageSingle(image)}
                        sx={{
                          cursor: "pointer",
                          borderRadius: "1rem",
                          overflow: "hidden",
                          height: "100px",
                          boxShadow: "0 0 3px #cecece",
                          transition: "all .4s",
                          "&:hover": {
                            img: {
                              transform: "scale(1.1)",
                              transition: "all .4s",
                              overflow: "hidden",
                            },
                          },
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "100px",
                            transition: "all .4s",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                          src={image}
                          alt=""
                        />
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  order: { xs: "2", md: "3" },
                  marginBottom: { xs: "1rem", md: "0" },
                }}
              >
                <Box
                  ref={descriptionRef}
                  sx={{
                    marginTop: "1rem",
                    // display: "-webkit-box" /* or inline-block */,
                    // textOverflow: "ellipsis",
                    // wordWrap: "break-word",
                    // WebkitLineClamp: allDescription ? false : 3,
                    // WebkitBoxOrient: "vertical",
                    // overflow: "hidden",
                    // lineHeight: "1.8em",
                  }}
                >
                  <Typography>
                    {!allDescription && descCharSet > 171
                      ? extractTextFromHtml(data?.course_description).slice(
                          0,
                          171
                        ) + "..."
                      : extractTextFromHtml(data?.course_description)}
                  </Typography>
                </Box>
                {descCharSet > 171 ? (
                  <Typography
                    color="primary"
                    variant="caption"
                    onClick={handleReadMore}
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      margin: "auto",
                      marginTop: "1rem",
                    }}
                  >
                    {allDescription ? `Read Less ` : `Read More`}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Modal>
        <ImageSingle
          // openImageSingle={openImageSingle}
          // setOpenImageSingle={setOpenImageSingle}
          // data={selectedImage}
          // typeOfModal={"images"}

          openImageSingle={openImageSingle}
          setOpenImageSingle={setOpenImageSingle}
          // data={selectedImage}
          data={data}
          selectedImage={selectedImage} //new
          typeOfModal={"images"}
        />
      </>
    )
  );
};

export default ResidenceRoomDetails;
