import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { closeModal } from "../../../../redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axiosInstance from "../../../../axiosInstance";
import { setSelectedBranchesString } from "../../../../redux/actions/branchActions";
import axios from "axios";
import Swal from "sweetalert2";

export default function AddUserModal({ setUsers, setSearchedTerm }) {
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const branchesArray = useSelector((state) => state?.branches?.branches);

  const [loading, setLoading] = useState();
  const [formValues, setFormValues] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const profile = useSelector((state) => state.profile.profile);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const lettersRegex = /^[A-Za-z]+$/;

    if (name === "branch") {
      // name veya surname için sadece harflere izin ver
      if (value.match(lettersRegex) || value === "") {
        // Eğer value harflerden oluşuyorsa veya boş ise, güncelle
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      // Diğer karakterler girildiğinde herhangi bir işlem yapılmaz (böylece numara/özel karakter girişi engellenir)
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    // Basit doğrulama için örnekler
    const errors = {};

    // Email doğrulaması için basit bir regex kullanımı
    if (!/\S+@\S+\.\S+/.test(formValues?.email)) {
      errors.email = "Email is not valid";
    }

    // Daha fazla alan için benzer doğrulamalar eklenebilir

    return errors;
  };
  useEffect(() => {
    if (formValues?.email) {
      const errors = validateForm();
      setFormErrors(errors);
      // Eğer hata varsa, hataları göster ve fonksiyonu sonlandır
      if (Object.keys(errors).length > 0) {
        // Hataları kullanıcıya göstermek için bir mekanizma ekleyebilirsiniz
        // Örneğin, bir state kullanarak form alanlarının altında hata mesajları gösterebilirsiniz
        return; // Hatalar varsa, burada fonksiyonu sonlandır
      }
    }
  }, [formValues]);

  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      agent_id: profile?.agent_id,
    }));
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleAddUser = () => {
    // const appendForm = { ...formValues, agent_id: selectedBranchesString };
    const appendForm = { ...formValues };
    addUser(appendForm);
  };

  // useEffect(()=> {
  //   dispatch(setSelectedBranchesString(formValues?.agent_id));
  // }, [formValues?.agent_id])
  const addUser = async (appendForm) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}auth/users`;

    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    if (window.cancelRequest) {
      window.cancelRequest.cancel(
        "New request made, cancelling previous request."
      );
    }
    window.cancelRequest = axios.CancelToken.source(); // Yeni bir Cancel Token oluştur

    try {
      const response = await axiosInstance.post(url, appendForm, {
        cancelToken: window.cancelRequest.token, // İptal tokenini istek ile gönder
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          Accounts: selectedBranchesString,
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      setSearchedTerm("");

      if (
        !selectedBranchesString?.includes(formValues?.agent_id) &&
        formValues?.agent_id !== profile?.agent_id
      ) {
        dispatch(
          setSelectedBranchesString([profile?.agent_id, formValues?.agent_id])
        );
      } else if (formValues?.agent_id === profile?.agent_id) {
        dispatch(setSelectedBranchesString([profile?.agent_id]));
      }

      setUsers(response?.data?.users);
      setLoading(false);
      handleClose();
      Swal.fire({
        icon: "success",
        title: "User added successfully!",
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled:", error.message);
      } else {
        setLoading(false);
        console.error("API isteği sırasında hata oluştu:", error);
      }
    }
  };

  const customRedPalette = {
    light: "#ff0000", // En açık kırmızı tonu
    main: lightMode ? "#bf0000" : "#ffffff", // Ana kırmızı tonu
    dark: "#800000", // Koyu kırmızı tonu
    extraDark: "#600000", // Koyu kırmızı tonu
    hardDark: "#400000", // Bu kırmızı tonları ile kontrast oluşturacak metin rengi
  };

  return (
    <Box>
      <>
        {branchesArray?.length ? (
          <FormControl
            fullWidth
            sx={{
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <InputLabel id="demo-simple-select-label">Select Branch</InputLabel>
            <Select
              className="select-branch"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formValues?.branch}
              defaultValue={profile?.agent_id}
              name="agent_id"
              label="Branch"
              onChange={handleChange}
            >
              {branchesArray[0].agent_id !== profile?.agent_id ? (
                <MenuItem value={profile?.agent_id}>
                  {profile?.agent_name}
                </MenuItem>
              ) : null}
              {branchesArray?.map((branch, idx) => {
                return (
                  <MenuItem key={idx} value={branch?.agent_id}>
                    {branch?.agent_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : null}

        <TextField
          sx={{
            width: "100%",
            marginBottom: "1rem",
          }}
          value={formValues?.agent_name || ""}
          onChange={handleChange}
          name="agent_name"
          id="outlined-basic"
          label="User Name"
          variant="outlined"
          type="text"
          required
        />

        <TextField
          sx={{
            width: "100%",
            marginBottom: "1rem",
          }}
          value={formValues?.email || ""}
          onChange={handleChange}
          name="email"
          id="outlined-basic"
          label="Email"
          variant="outlined"
          type="email"
          required
          error={!!formErrors.email}
          helperText={formErrors.email || ""}
        />
        <TextField
          sx={{
            width: "100%",
            marginBottom: "1rem",
          }}
          required
          fullWidth
          name="password"
          label="Password"
          type={!showPassword ? "text" : "password"}
          id="password"
          autoComplete="current-password"
          value={formValues?.password || ""}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        
        <Button
          sx={{ marginTop: "1rem", height: "56px" }}
          onClick={handleAddUser}
          disabled={
            !formValues?.agent_name ||
            !formValues?.email ||
            formErrors.email ||
            !formValues?.password ||
            !formValues?.agent_id
          }
          fullWidth
          variant="contained"
          color="primary"
        >
          Add User
        </Button>
      </>
      {loading ? (
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "#ffffff94",
            zIndex: "9",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
    </Box>
  );
}
