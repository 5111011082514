import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import WifiIcon from "@mui/icons-material/Wifi";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import AccessibleIcon from "@mui/icons-material/Accessible";
import CameraIndoorIcon from "@mui/icons-material/CameraIndoor";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import "../BestDeals/swiper.scss";
// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import DealMap from "./DealMap";
import { useSelector } from "react-redux";
import axiosInstance from "../../../axiosInstance";

export default function SliderDeals() {
  const [bestDeals, setBestDeals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seeOnTheMap, setSeeOnTheMap] = useState(false);
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileMd = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileLg = useMediaQuery(theme.breakpoints.down("xl"));
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const getBestDeals = async () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}dashboard/best_deals`;
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          Accounts: selectedBranchesString,
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });
      // const updatedDeals = response.data.map((deal) => ({
      //   ...deal,
      //   logo: deal.logo.replace("devcrm1", "devcrm"),
      // }));
      // setBestDeals(updatedDeals);
      setBestDeals(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("API isteği sırasında hata oluştu:", error);
    }
  };
  useEffect(() => {
    // if (selectedBranchesString?.length) {
    //   getBestDeals();
    // }
    getBestDeals();
  }, [selectedBranchesString]);

  const handleImageError = (dealId) => {
    setBestDeals((currentDeals) =>
      currentDeals.map((deal) =>
        deal.campus_id === dealId
          ? { ...deal, logo: deal.logo.replace("devcrm1", "devcrm") }
          : deal
      )
    );
  };

  const handleSeeOnTheMap = (control, campusId) => {
    setSeeOnTheMap({
      control: control,
      campusId: campusId,
    });
  };

  // useEffect(() => {
  // }, [seeOnTheMap]);

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            maxWidth: "fit-content",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            sx={{ width: "100%", textAlign: "start", marginBottom: "1rem" }}
            variant="h6"
            color="primary"
          >
            Best Deals
          </Typography>
          <Swiper
            style={{
              width: "100%",
              maxWidth: isMobile ? "100%" : "100%",
              padding: isMobileMd ? "0 0 36px 0" : "1rem 0 50px 0",
              left: "0",
            }}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            {bestDeals.map((deal, idx) => {
              return (
                <SwiperSlide
                  key={`${deal?.campus_id}-${idx}`}
                  style={{
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    width: isMobile
                      ? "100%"
                      : isMobileMd
                      ? "50%"
                      : isMobileLg
                      ? "35%"
                      : "25%",
                    height: "auto",
                    position: "relative",
                    backgroundColor: lightMode ? "#ffffff" : "#343434",
                  }}
                >
                  <Box
                    sx={{
                      perspective: "1000px",
                      width: "100%",
                      height: "100%",
                      padding:
                        seeOnTheMap?.campusId === deal?.campus_id
                          ? isMobileMd
                            ? "0"
                            : "1rem"
                          : "0",
                      //boxSizing: "border-box",
                    }}
                  >
                    <Card
                      sx={{
                        borderRadius: "1rem",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: lightMode ? "#ffffff" : "#1e1e1e",
                        backgroundImage: "none",
                        background: !lightMode
                          ? "linear-gradient(90deg, #191919, #292929)"
                          : "unset",
                        transform:
                          seeOnTheMap?.campusId === deal?.campus_id
                            ? "rotateY(180deg)"
                            : "unset",
                        transition: "transform 1s",
                        border: !isMobileMd
                          ? !lightMode
                            ? "1px solid #404040"
                            : "unset"
                          : "unset",
                        boxShadow: !isMobileMd
                          ? lightMode
                            ? "0px 0px 5px #919191"
                            : "0px 0px 2px #191919"
                          : "unset",
                      }}
                    >
                      <>
                        {seeOnTheMap?.campusId !== deal?.campus_id ? (
                          <>
                            <CardMedia>
                              <img
                                style={{
                                  display: "block",
                                  width: "100%",
                                  maxHeight: "200px",
                                }}
                                src={deal.logo}
                                alt={`Slide ${idx + 1}`}
                                onError={() => handleImageError(deal.campus_id)}
                              />
                            </CardMedia>
                            <CardContent
                              sx={{
                                maxHeight: "200px",
                                overflowY: "scroll",
                              }}
                            >
                              <Typography color="primary">
                                {deal.campus_name}
                              </Typography>
                              <Typography>{deal.campus_address}</Typography>
                              <Box
                                sx={{
                                  borderBottom: lightMode
                                    ? "1px solid #efefef"
                                    : "1px solid #515151",
                                  borderTop: lightMode
                                    ? "1px solid #efefef"
                                    : "1px solid #515151",
                                  marginBottom: "1rem",
                                  marginTop: "1rem",
                                  paddingTop: "1rem",
                                  paddingBottom: ".5rem",
                                }}
                              >
                                {deal.near_places.map((place, idx) => {
                                  return (
                                    <Box
                                      key={`${place?.place} - ${place?.icon} -  ${idx}`}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: ".5rem",
                                      }}
                                    >
                                      <Typography sx={{ textAlign: "start" }}>
                                        {place.place}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          textAlign: "start",
                                          minWidth: "max-content",
                                        }}
                                        key={idx}
                                      >
                                        {place.distance}
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              </Box>
                              <Grid container spacing={1}>
                                {Object.keys(deal.cms_features).map(
                                  (feature, idx) => {
                                    return (
                                      <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        key={`#${feature} - ${idx}`}
                                      >
                                        <Box sx={{ display: "flex" }}>
                                          <Typography
                                            key={idx}
                                            sx={{
                                              textAlign: "start",
                                              marginRight: "1rem",
                                            }}
                                          >
                                            {deal.cms_features[feature].name}
                                          </Typography>
                                          {deal.cms_features[
                                            feature
                                          ].icon.toLowerCase() ===
                                            "icon-gym" && <FitnessCenterIcon />}
                                          {deal.cms_features[
                                            feature
                                          ].icon.toLowerCase() ===
                                            "icon-wifi" && <WifiIcon />}
                                          {deal.cms_features[
                                            feature
                                          ].icon.toLowerCase() ===
                                            "icon-washing-machine" && (
                                            <LocalLaundryServiceIcon />
                                          )}
                                          {deal.cms_features[
                                            feature
                                          ].icon.toLowerCase() ===
                                            "icon-hotel-bell" && (
                                            <RoomServiceIcon />
                                          )}
                                          {deal.cms_features[
                                            feature
                                          ].icon.toLowerCase() ===
                                            "icon-disabled" && (
                                            <AccessibleIcon />
                                          )}
                                          {deal.cms_features[
                                            feature
                                          ].icon.toLowerCase() ===
                                            "icon-camera" && (
                                            <CameraIndoorIcon />
                                          )}
                                          {deal.cms_features[
                                            feature
                                          ].icon.toLowerCase() ===
                                            "icon-masks" && (
                                            <TheaterComedyIcon />
                                          )}
                                        </Box>
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            </CardContent>
                            <CardContent
                              sx={{
                                marginTop: "auto",
                                borderTop: lightMode
                                  ? "1px solid #efefef"
                                  : "1px solid #515151",
                              }}
                            >
                              <Box
                                onClick={() =>
                                  handleSeeOnTheMap(
                                    !seeOnTheMap?.control,
                                    deal.campus_id
                                  )
                                }
                                sx={{ marginTop: "auto", cursor: "pointer" }}
                              >
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: lightMode ? "#bf0000" : "#ffffff",
                                  }}
                                >
                                  See on the Map
                                </Link>
                              </Box>
                            </CardContent>
                          </>
                        ) : (
                          <>
                            <CardContent
                              sx={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "calc(100% - 68px)",
                                transform: "rotateY(-180deg)",
                                padding: isMobile ? "0" : "1rem",
                                boxSizing: "border-box",
                                paddingBottom: isMobile ? "0" : "1rem",
                              }}
                            >
                              <Box
                                className="swiper-no-swiping"
                                sx={{ width: "100%", height: "100%" }}
                              >
                                {/* Harita buraya eklenecek */}
                                <DealMap
                                  deal={
                                    seeOnTheMap?.campusId === deal?.campus_id &&
                                    deal
                                  }
                                />
                              </Box>
                              {/* <DealMap
                                deal={
                                  seeOnTheMap?.campusId === deal?.campus_id &&
                                  deal
                                }
                              /> */}
                              {/* <Box
                            onClick={() =>
                              handleSeeOnTheMap(
                                !seeOnTheMap?.control,
                                null
                              )
                            }
                            sx={{ marginTop: "auto", cursor: "pointer" }}
                          >
                            <Link style={{ textDecoration: "none" }}>
                              Back
                            </Link>
                          </Box> */}
                            </CardContent>
                            <CardContent
                              sx={{
                                marginTop: "auto",
                                borderTop: lightMode
                                  ? "1px solid #efefef"
                                  : "1px solid #515151",
                                transform: "rotateY(-180deg)",
                              }}
                            >
                              <Box
                                onClick={() =>
                                  handleSeeOnTheMap(!seeOnTheMap?.control, null)
                                }
                                sx={{ marginTop: "auto", cursor: "pointer" }}
                              >
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: lightMode ? "#bf0000" : "#ffffff",
                                  }}
                                >
                                  Back
                                </Link>
                              </Box>
                            </CardContent>
                          </>
                        )}
                      </>
                    </Card>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </>
      )}
    </ThemeProvider>
  );
}
