import { Grid } from "@mui/material";
import React from "react";

import Residences from ".";
import { ResidencesProvider } from "../../../../contexts/Residences/ResidencesContext";

const ProviderWrapperArea = ({ open, setOpen, isMobile, setIsMobile }) => {
  return (
    <ResidencesProvider>
      <Residences
        open={open}
        setOpen={setOpen}
        isMobile={isMobile}
        setIsMobile={setIsMobile}
      />
    </ResidencesProvider>
  );
};

export default ProviderWrapperArea;
