// AirportServicesContext.js

import React, { createContext, useState } from "react";

const ResidencesContext = createContext();

export const ResidencesProvider = ({ children }) => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchedResidence, setSearchedResidence] = useState("");
  const [nearByUniversities, setNearByUniversities] = useState(null);
  const [universityTabControl, setUniversityTabControl] = useState(false);
  const [nearByControl, setNearByControl] = useState(null);

  const [disabledControl, setDisabledControl] = useState(false);
  const [selectedLocationNearBy, setSelectedLocationNearBy] = useState(null);

  const [publicType, setPublicType] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");

  // Context'e sağlanacak değerler
  const contextValue = {
    locations,
    setLocations,
    selectedLocation,
    setSelectedLocation,
    searchedResidence,
    setSearchedResidence,
    nearByUniversities,
    setNearByUniversities,
    universityTabControl,
    setUniversityTabControl,
    searchTerm,
    setSearchTerm,
    disabledControl,
    setDisabledControl,
    nearByControl,
    setNearByControl,
    selectedLocationNearBy,
    setSelectedLocationNearBy,
    publicType,
    setPublicType,
  };

  return (
    <ResidencesContext.Provider value={contextValue}>
      {children}
    </ResidencesContext.Provider>
  );
};

export default ResidencesContext;
