import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Pagination,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ComingSoonModal from "../Modal/ComingSoonModal/ComingSoonModal";
import {
  closeModal,
  openModal,
  setPageType,
} from "../../redux/actions/actions";
import AddUserModal from "../Modal/Settings/AddUserModal/AddUserModal";
import AddBranchModal from "../Modal/Settings/AddBranchModal/AddBranchModal";
import RemoveModal from "../Modal/Settings/RemoveModal/RemoveModal";
import UpdateBranchModal from "../Modal/Settings/UpdateBranchModal/UpdateBranchModal";
import TuneIcon from "@mui/icons-material/Tune";
import UpdateUserModal from "../Modal/Settings/UpdateUserModal/UpdateUserModal";
import UsersListContext from "../../contexts/UsersList/UsersList";
import axiosInstance from "../../axiosInstance";
import axios from "axios";
import { useTheme } from "@emotion/react";
import { tableItemsLg, tableItemsMd, tableItemsSm } from "./AddUserTableItems";
import TableDetailsModal from "../Modal/TableDetailsModal";
import AddUserTableModal from "../Modal/Settings/AddUserTableModal/AddUserTableModal";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import NoDataFound from "../NoDataFound";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function AddUserComponent() {
  const settingsRef = React.useRef();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchedTerm, setSearchedTerm] = React.useState("");
  const [settingsHandler, setSettingsHandler] = React.useState("");
  const profile = useSelector((state) => state.profile.profile);

  const [totalPages, setTotalPages] = React.useState(0);
  const itemsPerPage = 10; // Her sayfada kaç öğe gösterileceğini belirleyin
  const [currentPage, setCurrentPage] = React.useState(1); // Şu anki sayfayı saklamak için bir değişken
  const [searchControl, setSearchControl] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { users, setUsers } = React.useContext(UsersListContext);
  const [loading, setLoading] = React.useState(true);
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );

  // useEffect(() => {
  //   if (currentPage) {
  //     getUsers(searchedTerm, currentPage);
  //   }
  // }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    // Burada ek olarak, URL'de sayfa numarasını güncelleyebilirsiniz.

    if (value !== 1) {
      navigate(`?page=${value}`);
      getUsers(searchedTerm, value);
    } else {
      navigate(``);
    }
  };

  const getUsers = async (searchedTerm, page) => {
    setLoading(true);
    // let url = `${process.env.REACT_APP_BASE_URL}auth/branches`;
    // let url = "https://new-multi-account-development.up.railway.app/auth/users";
    let url = `${process.env.REACT_APP_BASE_URL}auth/users?page=${page}`;

    if (searchedTerm) {
      url = `${url}&agent_name=${searchedTerm}`;
    } else {
      url = `${url}`;
    }
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    if (window.cancelRequest) {
      window.cancelRequest.cancel(
        "New request made, cancelling previous request."
      );
    }

    window.cancelRequest = axios.CancelToken.source(); // Yeni bir Cancel Token oluştur

    try {
      const response = await axiosInstance.get(url, {
        cancelToken: window.cancelRequest.token, // İptal tokenini istek ile gönder
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor,
          Accounts: selectedBranchesString,
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      const totalItems = response.data.count;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
      setUsers(response.data.results);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled:", error.message);
      } else {
        setCurrentPage(1);
        console.error("API isteği sırasında hata oluştu:", error);
      }
    }
  };

  React.useEffect(() => {
    if (searchedTerm) {
      getUsers(searchedTerm, currentPage);
    } else {
      getUsers(null, currentPage);
    }
  }, [selectedBranchesString, dispatch, searchedTerm, currentPage]);

  useEffect(() => {
    if (selectedBranchesString) {
      setCurrentPage(1);
    }
  }, [selectedBranchesString]);
  React.useEffect(() => {
    setSearchTerm(searchedTerm);
  }, [searchedTerm]);

  const handleOpenModal = (type, user) => {
    if (type === "add") {
      dispatch(
        openModal(
          <AddUserModal setUsers={setUsers} setSearchedTerm={setSearchedTerm} />
        )
      );
    } else if (type === "edit") {
      dispatch(
        openModal(
          <UpdateUserModal
            user={user}
            setUsers={setUsers}
            searchedTerm={searchedTerm}
            setSearchedTerm={setSearchedTerm}
          />
        )
      );
    } else if (type === "remove") {
      dispatch(
        openModal(
          <RemoveModal
            type={"user"}
            item={user}
            getUsers={() => getUsers(searchedTerm, currentPage)}
          />
        )
      );
    } // Swal.fire({
    //   icon: "info",
    //   title: "Coming Soon!",
    //   showConfirmButton: false,
    //   showCloseButton: true,
    // });
  };

  const settingsHandlerFunction = (agentId) => {
    if (settingsHandler === agentId) {
      setSettingsHandler("");
    } else {
      setSettingsHandler(agentId);
    }
  };

  const handleClickOutside = (event) => {
    if (settingsRef.current && !settingsRef.current.contains(event.target)) {
      setSettingsHandler("");
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const theme = useTheme();
  const isMobileSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileMd = useMediaQuery(
    theme.breakpoints.up("sm") && theme.breakpoints.down("lg")
  );

  const handleSeeDetails = (user) => {
    // Dispatch an action to open the modal, with the FilterModal component as the payload
    dispatch(
      openModal(<AddUserTableModal data={user} columns={tableItemsLg} />)
    );
  };

  const handleSearchUser = (searchTerm) => {
    setSearchedTerm(searchTerm);
  };

  useEffect(() => {
    if (searchTerm) {
      setSearchControl(true);
    } else {
      setSearchControl(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    dispatch(setPageType("add-user"));
  }, []);
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <Box
          component="form"
          onSubmit={(e) => (e.preventDefault(), handleSearchUser(searchTerm))}
          autoComplete="off"
          sx={{ position: "relative" }}
        >
          <TextField
            sx={{
              height: "56px",
              width: { xs: "100%", lg: "fit-content" },
            }}
            label={"Search User"}
            variant="outlined"
            // onChange={(e) => setSearchTerm(e.target.value)}
            onChange={(e) => {
              const value = e.target.value;
              // Sadece harfler ve boşluk karakterine izin ver
              // if (/^[a-zA-Z\s]*$/.test(value)) {
              if (/^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]*$/.test(value)) {
                setSearchTerm(value);
              }
            }}
            value={searchTerm}
          />
          {searchControl || searchedTerm ? (
            <CloseIcon
              onClick={() => (setSearchTerm(""), setSearchedTerm(""))}
              sx={{
                position: "absolute",
                top: "calc(50% - 12px)",
                right: "6px",
                cursor: "pointer",
              }}
            />
          ) : null}
        </Box>
        <Button
          disabled={!searchTerm}
          onClick={() => handleSearchUser(searchTerm)}
          sx={{ height: "52px", marginLeft: "1rem" }}
          variant="contained"
        >
          Search
        </Button>
        {
          <Button
            onClick={() => handleOpenModal("add")}
            variant="contained"
            color="primary"
            sx={{ marginLeft: "auto", display: { xs: "none", lg: "block" } }}
          >
            Add User
          </Button>
        }
      </Box>
      {
        <Button
          fullWidth
          onClick={() => handleOpenModal("add")}
          variant="contained"
          color="primary"
          sx={{
            marginLeft: "auto",
            display: { xs: "block", lg: "none" },
            marginBottom: "2rem",
          }}
        >
          Add User
        </Button>
      }
      {!loading ? (
        users?.length ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {isMobileSm
                          ? tableItemsSm?.map((item, idx) => {
                              return (
                                <TableCell
                                  key={item?.id}
                                  sx={{ minWidth: "120px" }}
                                  align="left"
                                >
                                  {item?.name}
                                </TableCell>
                              );
                            })
                          : isMobileMd
                          ? tableItemsMd?.map((item, idx) => {
                              return (
                                <TableCell
                                  key={item?.id}
                                  sx={{ minWidth: "120px" }}
                                  align="left"
                                >
                                  {item?.name}
                                </TableCell>
                              );
                            })
                          : tableItemsLg?.map((item, idx) => {
                              return (
                                <TableCell
                                  key={item?.id}
                                  sx={{ minWidth: "120px" }}
                                  align="left"
                                >
                                  {item?.name}
                                </TableCell>
                              );
                            })}

                        <TableCell
                          sx={{ minWidth: "120px" }}
                          align="right"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users?.map((user) => (
                        <TableRow
                          sx={{ position: "relative" }}
                          key={user.agent_id}
                        >
                          <TableCell align="left">#{user?.user_id}</TableCell>
                          <TableCell align="left">{user?.agent_name}</TableCell>

                          {!isMobileSm ? (
                            <>
                              <TableCell align="left">{user?.email}</TableCell>
                            </>
                          ) : null}

                          {!isMobileSm && !isMobileMd ? (
                            <>
                              {/* <TableCell align="left">{user?.agent_id}</TableCell> */}
                              <TableCell align="left">
                                {user?.branch_name}
                              </TableCell>
                            </>
                          ) : null}

                          <TableCell
                            align="right"
                            sx={{ display: { xs: "none", lg: "table-cell" } }}
                          >
                            <Button
                              onClick={() => handleOpenModal("edit", user)}
                              variant="contained"
                              color="warning"
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => handleOpenModal("remove", user)}
                              variant="contained"
                              color="error"
                              sx={{ marginLeft: "1rem" }}
                            >
                              Remove
                            </Button>
                          </TableCell>
                          <TableCell
                            sx={{ display: { xs: "table-cell", lg: "none" } }}
                          >
                            <Box sx={{ display: "flex" }}>
                              <TuneIcon
                                onClick={() =>
                                  settingsHandlerFunction(user?.user_id)
                                }
                              />
                              <Box
                                sx={{
                                  position: "relative",
                                  width: "fit-content",
                                  marginLeft: ".5rem",
                                }}
                                onClick={() => handleSeeDetails(user)}
                              >
                                <FormatListBulletedIcon />
                                <RemoveRedEyeIcon
                                  sx={{
                                    fontSize: "18px",
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-10px",
                                  }}
                                />
                              </Box>
                            </Box>
                          </TableCell>
                          {settingsHandler === user.user_id ? (
                            <Box
                              ref={settingsRef}
                              sx={{
                                position: "absolute",
                                top: "9px",
                                right: "110px",
                                background: "#efeeee",
                                padding: ".5rem",
                                borderRadius: ".25rem",
                                boxShadow: "0 0 3px #bfbfbf",
                              }}
                            >
                              <Button
                                onClick={() => handleOpenModal("edit", user)}
                                variant="contained"
                                color="warning"
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => handleOpenModal("remove", user)}
                                variant="contained"
                                color="error"
                                sx={{ marginLeft: "1rem" }}
                              >
                                Remove
                              </Button>
                            </Box>
                          ) : null}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  sx={{ mt: 10, display: "flex", justifyContent: "center" }}
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <NoDataFound />
        )
      ) : (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
          }}
        />
      )}
    </Box>
  );
}
