import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Pagination,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ComingSoonModal from "../Modal/ComingSoonModal/ComingSoonModal";
import {
  closeModal,
  openModal,
  setPageType,
} from "../../redux/actions/actions";
import AddUserModal from "../Modal/Settings/AddUserModal/AddUserModal";
import AddBranchModal from "../Modal/Settings/AddBranchModal/AddBranchModal";
import RemoveModal from "../Modal/Settings/RemoveModal/RemoveModal";
import UpdateBranchModal from "../Modal/Settings/UpdateBranchModal/UpdateBranchModal";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import BranchesListContext from "../../contexts/BranchesList/BranchesList";
import axios from "axios";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../NoDataFound";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Sample 1", 159, 6.0, 24, 4.0),
  createData("Sample 2", 237, 9.0, 37, 4.3),
  createData("Sample 3", 262, 16.0, 24, 6.0),
  createData("Sample 4", 305, 3.7, 67, 4.3),
  createData("Sample 5", 356, 16.0, 49, 3.9),
];

export default function AddBranchComponent() {
  const itemsPerPage = 10; // Her sayfada kaç öğe gösterileceğini belirleyin
  const [totalPages, setTotalPages] = React.useState(0);
  const settingsRef = React.useRef();
  const [searchedTerm, setSearchedTerm] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [settingsHandler, setSettingsHandler] = React.useState("");
  const [searchControl, setSearchControl] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1); // Şu anki sayfayı saklamak için bir değişken
  const { branches, setBranches } = React.useContext(BranchesListContext);

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const dispatch = useDispatch();
  const handleOpenModal = (type, branch) => {
    if (type === "add") {
      dispatch(openModal(<AddBranchModal />));
    } else if (type === "edit") {
      dispatch(openModal(<UpdateBranchModal branch={branch} />));
    } else if (type === "remove") {
      dispatch(openModal(<RemoveModal type={"branch"} branch={branch} />));
    } // Swal.fire({
    //   icon: "info",
    //   title: "Coming Soon!",
    //   showConfirmButton: false,
    //   showCloseButton: true,
    // });
  };

  const getBranches = async (searchedTerm, page) => {
    setLoading(true);
    // let url = `${process.env.REACT_APP_BASE_URL}auth/branches`;
    // let url = "https://new-multi-account-development.up.railway.app/auth/users";
    let url = `${process.env.REACT_APP_BASE_URL}auth/branch_list?page=${page}`;

    if (searchedTerm) {
      url = `${url}&agent_name=${searchedTerm}`;
    } else {
      url = `${url}`;
    }
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    if (window.cancelRequest) {
      window.cancelRequest.cancel(
        "New request made, cancelling previous request."
      );
    }

    window.cancelRequest = axios.CancelToken.source(); // Yeni bir Cancel Token oluştur

    try {
      const response = await axiosInstance.get(url, {
        cancelToken: window.cancelRequest.token, // İptal tokenini istek ile gönder
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor,
          Accounts: selectedBranchesString,
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      const totalItems = response.data.count;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
      setBranches(response.data.results);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled:", error.message);
      } else {
        setCurrentPage(1);
        console.error("API isteği sırasında hata oluştu:", error);
      }
    }
  };

  React.useEffect(() => {
    if (searchedTerm) {
      getBranches(searchedTerm, currentPage);
    } else {
      getBranches(null, currentPage);
    }
  }, [selectedBranchesString, dispatch, searchedTerm, currentPage]);

  const settingsHandlerFunction = (agentId) => {
    if (settingsHandler === agentId) {
      setSettingsHandler("");
    } else {
      setSettingsHandler(agentId);
    }
  };

  const handleClickOutside = (event) => {
    if (settingsRef.current && !settingsRef.current.contains(event.target)) {
      setSettingsHandler("");
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchBranch = (searchTerm) => {
    setSearchedTerm(searchTerm);
  };

  React.useEffect(() => {
    if (searchTerm) {
      setSearchControl(true);
    } else {
      setSearchControl(false);
    }
  }, [searchTerm]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    // Burada ek olarak, URL'de sayfa numarasını güncelleyebilirsiniz.

    if (value !== 1) {
      navigate(`?page=${value}`);
      getBranches(searchedTerm, value);
    } else {
      navigate(``);
    }
  };

  React.useEffect(() => {
    if (selectedBranchesString) {
      setCurrentPage(1);
    }
  }, [selectedBranchesString]);

  React.useEffect(() => {
    dispatch(setPageType("add-branch"));
  }, []);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <Box
          component="form"
          onSubmit={(e) => (e.preventDefault(), handleSearchBranch(searchTerm))}
          autoComplete="off"
          sx={{ position: "relative" }}
        >
          <TextField
            sx={{
              height: "56px",
              width: { xs: "100%", lg: "fit-content" },
            }}
            label={"Search User"}
            variant="outlined"
            // onChange={(e) => setSearchTerm(e.target.value)}
            onChange={(e) => {
              const value = e.target.value;
              // Sadece harfler ve boşluk karakterine izin ver
              // if (/^[a-zA-Z\s]*$/.test(value)) {
              if (/^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]*$/.test(value)) {
                setSearchTerm(value);
              }
            }}
            value={searchTerm}
          />
          {searchControl || searchedTerm ? (
            <CloseIcon
              onClick={() => (setSearchTerm(""), setSearchedTerm(""))}
              sx={{
                position: "absolute",
                top: "calc(50% - 12px)",
                right: "6px",
                cursor: "pointer",
              }}
            />
          ) : null}
        </Box>
        <Button
          disabled={!searchTerm}
          onClick={() => handleSearchBranch(searchTerm)}
          sx={{ height: "52px", marginLeft: "1rem" }}
          variant="contained"
        >
          Search
        </Button>
      </Box>

      {
        <Button
          fullWidth
          onClick={() => handleOpenModal("add")}
          variant="contained"
          color="primary"
          sx={{
            marginLeft: "auto",
            display: { xs: "none", lg: "none" },
            marginBottom: "2rem",
          }}
        >
          Add Branch
        </Button>
      }

      {!loading ? (
        branches?.length ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Branch ID</TableCell>
                      <TableCell align="left">Branch Name</TableCell>
                      <TableCell align="left">Branch Agent Email</TableCell>
                      <TableCell align="left">Students</TableCell>
                      {/* <TableCell align="right"></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {branches?.map((branch, idx) => (
                      <TableRow
                        sx={{ position: "relative" }}
                        key={branch.agent_id}
                      >
                        <TableCell align="left">#{idx + 1}</TableCell>
                        <TableCell align="left">{branch?.agent_name}</TableCell>
                        <TableCell align="left">{branch?.email}</TableCell>
                        {/* <TableCell align="left">{branch?.agent_id}</TableCell> */}
                        <TableCell align="left">
                          {branch?.students_count}
                        </TableCell>
                        {/* <TableCell
                  align="right"
                  sx={{ display: { xs: "none", lg: "table-cell" } }}
                >
                  <Button
                    onClick={() => handleOpenModal("edit", branch)}
                    variant="contained"
                    color="warning"
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => handleOpenModal("remove", branch)}
                    variant="contained"
                    color="error"
                    sx={{ marginLeft: "1rem" }}
                  >
                    Remove
                  </Button>
                </TableCell> */}
                        {/* <TableCell sx={{ display: { xs: "table-cell", lg: "none" } }}>
                  <TuneIcon
                    onClick={() => settingsHandlerFunction(branch?.agent_id)}
                  />
                </TableCell> */}
                        {settingsHandler === branch.agent_id ? (
                          <Box
                            ref={settingsRef}
                            sx={{
                              position: "absolute",
                              top: "9px",
                              right: "61px",
                              background: "#efeeee",
                              padding: ".5rem",
                              borderRadius: ".25rem",
                              boxShadow: "0 0 3px #bfbfbf",
                            }}
                          >
                            <Button
                              onClick={() => handleOpenModal("edit", branch)}
                              variant="contained"
                              color="warning"
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => handleOpenModal("remove", branch)}
                              variant="contained"
                              color="error"
                              sx={{ marginLeft: "1rem" }}
                            >
                              Remove
                            </Button>
                          </Box>
                        ) : null}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid item xs={12}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  sx={{ mt: 10, display: "flex", justifyContent: "center" }}
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <NoDataFound />
        )
      ) : (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
          }}
        />
      )}
    </Box>
  );
}
