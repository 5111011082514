import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MeetingRoomSharpIcon from "@mui/icons-material/MeetingRoomSharp";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { LoginContext } from "../contexts/loginContext";
import axios from "axios";
import { Box, Typography, createTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DescriptionIcon from "@mui/icons-material/Description";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { setTokens } from "../redux/actions/tokenActions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTheme } from "../styles/theme";
import styled from "@emotion/styled";
import MuiListItemIcon from "@mui/material/ListItemIcon"; // Import with an alias
import PersonIcon from "@mui/icons-material/Person";
import { openModal } from "../redux/actions/actions";
import ComingSoonModal from "./Modal/ComingSoonModal/ComingSoonModal";
import StorefrontIcon from "@mui/icons-material/Storefront";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import Swal from "sweetalert2";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import "../assets/css/animations.css";
const ListItemIcon = styled(MuiListItemIcon)({
  color: "#ffffff !important", // Apply white color dynamically
});

export function MainListItems({ open, setOpen }) {
  const myRequestsItems = [
    {
      name: "My Requests",
      url: "/myrequests",
    },
    {
      name: "Customer Support",
      url: "/support-customer",
    },
    // {
    //   name: "Residence Change",
    //   url: "/residence-change",
    // },
    // {
    //   name: "Maintenance",
    //   url: "/maintenance-request",
    // },
    // {
    //   name: "Room Change",
    //   url: "/room-change-request",
    // },
  ];

  const settingsItems = [
    {
      id: "add-user",
      name: "Add User",
      url: "/add-user",
    },
    {
      id: "add-branch",
      name: "Add Branch",
      url: "/add-branch",
    },
  ];

  const branchesArray = useSelector((state) => state?.branches?.branches);
  const agencyArray = useSelector((state) => state?.branches?.agency);
  const profile = useSelector((state) => state.profile.profile); // Redux state'inden lightMode değerini çekin

  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const isMobile = window.innerWidth < 768;
  const myRequestsRef = React.useRef();
  const isCurrentUrlInRequests = myRequestsItems.some((item) =>
    window.location.href.includes(item.url)
  );

  const isCurrentUrlInSettings = settingsItems.some((item) =>
    window.location.href.includes(item.url)
  );

  const [requestsDropdown, setRequestsDropdown] = React.useState(
    isCurrentUrlInRequests
  );
  const [settingsDropdown, setSettingsDropdown] = React.useState(
    isCurrentUrlInSettings
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token); // Filtrelenmiş ürünleri al

  const logOutFunc = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    Swal.fire({
      icon: "info",
      title: "Logging Out...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/logout`,
        {
          refresh: token,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        }
      );
      dispatch(setTokens(null, null));
      localStorage.removeItem("accessToken");
      localStorage.removeItem("loginUser");
      Swal.close();
      navigate("/login");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // Sunucunun döndürdüğü hata yanıtı
        console.log("Error response:", error.response.data);
        console.log("Error status:", error.response.status);
        console.log("Error headers:", error.response.headers);
      } else {
        // Hata yanıtı alınamazsa
        console.log("Error message:", error.message);
      }
    }
  };

  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  React.useEffect(() => {
    setDropdownStyles((prev) => ({
      ...prev,
      background: lightMode
        ? getTheme().palette.customRed.extraDark
        : "#303030",
    }));
    setSettingsDropdownStyles((prev) => ({
      ...prev,
      background: lightMode
        ? getTheme().palette.customRed.extraDark
        : "#303030",
    }));
  }, [lightMode]);

  // React.useEffect(() => {
  //   if (myRequestsRef.current) {
  //     if (open) {
  //       if (requestsDropdown) {
  //         myRequestsRef.current.style.height = "max-content";
  //         myRequestsRef.current.style.visibility = "visible";
  //         myRequestsRef.current.style.transition = "height .4s";
  //         myRequestsRef.current.style.background = lightMode
  //           ? `${getTheme().palette.customRed.extraDark}`
  //           : "#303030";
  //       } else {
  //         myRequestsRef.current.style.height = 0;
  //         myRequestsRef.current.style.visibility = "hidden";
  //         myRequestsRef.current.style.transition = "height .4s";
  //       }
  //     } else {
  //       myRequestsRef.current.style.height = 0;
  //       myRequestsRef.current.style.visibility = "hidden";
  //     }
  //   }
  // }, [requestsDropdown, open]);

  const handleOpenModal = () => {
    // Dispatch an action to open the modal, with the FilterModal component as the payload
    // dispatch(openModal(<ComingSoonModal />));
    Swal.fire({
      icon: "info",
      title: "Coming Soon!",
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const [dropdownStyles, setDropdownStyles] = React.useState({
    background: lightMode
      ? `${getTheme().palette.customRed.extraDark}`
      : "#303030",
  });

  const [settingsDropdownStyles, setSettingsDropdownStyles] = React.useState({
    background: lightMode
      ? `${getTheme().palette.customRed.extraDark}`
      : "#303030",
  });

  React.useEffect(() => {
    if (requestsDropdown) {
      setDropdownStyles({
        display: "block",
        background: lightMode
          ? `${getTheme().palette.customRed.extraDark}`
          : "#303030",
      });
    } else {
      setDropdownStyles({
        display: "none",
      });
    }
  }, [requestsDropdown]);

  React.useEffect(() => {
    if (settingsDropdown) {
      setSettingsDropdownStyles({
        display: "block",
        background: lightMode
          ? `${getTheme().palette.customRed.extraDark}`
          : "#303030",
      });
    } else {
      setSettingsDropdownStyles({
        display: "none",
      });
    }
  }, [settingsDropdown]);

  return (
    <React.Fragment>
      <ListItemButton
        sx={{
          "&:hover": {
            background:
              window.location.pathname === "/"
                ? lightMode
                  ? "#3e0404"
                  : "#3b3a3a"
                : lightMode
                ? "#7b0000"
                : "#3a3a3a",
          },
          background:
            window.location.pathname === "/"
              ? lightMode
                ? "#570404"
                : "#404040"
              : "unset",
        }}
        href="/"
      >
        <ListItemIcon>
          <DashboardIcon sx={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>

      <ListItemButton
        sx={{
          "&:hover": {
            background: window.location.pathname.includes("/myinvoices")
              ? lightMode
                ? "#3e0404"
                : "#3b3a3a"
              : lightMode
              ? "#7b0000"
              : "#3a3a3a",
          },
          background: window.location.href.includes("/myinvoices")
            ? lightMode
              ? "#570404"
              : "#404040"
            : "unset",
        }}
        href="/myinvoices"
      >
        <ListItemIcon>
          <DescriptionIcon sx={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText primary="My Invoices" />
      </ListItemButton>

      <ListItemButton
        sx={{
          "&:hover": {
            background: window.location.pathname.includes("/quotes")
              ? lightMode
                ? "#3e0404"
                : "#3b3a3a"
              : lightMode
              ? "#7b0000"
              : "#3a3a3a",
          },
          background: window.location.href.includes("/quotes")
            ? lightMode
              ? "#570404"
              : "#404040"
            : "unset",
        }}
        href="/quotes"
      >
        <ListItemIcon>
          <ReceiptLongIcon sx={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText primary="Quotes" />
      </ListItemButton>

      <ListItemButton
        sx={{
          borderBottom: requestsDropdown && open && "1px solid #ffffff",
          // backgroundColor: requestsDropdown && open && "rgba(0, 0, 0, 0.04)",
        }}
        onClick={() => {
          if (open) {
            setRequestsDropdown(!requestsDropdown);
          }
        }}
      >
        <ListItemIcon>
          <PersonAddAltIcon sx={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText primary="My Requests" />
        <ListItemIcon sx={{ justifyContent: "flex-end" }}>
          {requestsDropdown ? (
            <ArrowDropUpIcon sx={{ color: "#ffffff" }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: "#ffffff" }} />
          )}
        </ListItemIcon>
      </ListItemButton>

      {requestsDropdown ? (
        <Box
          sx={dropdownStyles}
          // sx={{
          //   height: "0",
          //   visibility: "hidden",
          //   transition: "height .4s",
          // }}
        >
          {myRequestsItems.map((item, idx) => {
            const isUrlMatched = window.location.href.includes(item.url);
            return (
              <ListItemButton
                disabled={
                  item.name === "Residence Change" ||
                  item.name === "Maintenance" ||
                  item.name === "Customer Support" ||
                  item.name === "Room Change"
                }
                href={
                  item.name === "Residence Change" ||
                  item.name === "Maintenance" ||
                  item.name === "Customer Support" ||
                  item.name === "Room Change"
                    ? ``
                    : `${item.url}`
                }
                onClick={() =>
                  (item.name === "Residence Change" ||
                    item.name === "Maintenance" ||
                    item.name === "Customer Support" ||
                    item.name === "Room Change") &&
                  handleOpenModal()
                }
                key={`${item}-${idx}`}
                sx={{
                  "&:hover": {
                    background: isUrlMatched
                      ? lightMode
                        ? "#3e0404"
                        : "#3b3a3a"
                      : lightMode
                      ? "#570404"
                      : "#3a3a3a",
                  },
                  background: isUrlMatched
                    ? lightMode
                      ? "#570404"
                      : "#404040"
                    : "unset",
                  cursor:
                    item.name === "Residence Change" ||
                    item.name === "Maintenance" ||
                    item.name === "Room Change" ||
                    item.name === "Customer Support"
                      ? "pointer !important"
                      : "pointer",
                  pointerEvents:
                    item.name === "Residence Change" ||
                    item.name === "Maintenance" ||
                    item.name === "Room Change" ||
                    item.name === "Customer Support"
                      ? "auto !important"
                      : "auto",
                }}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={`${item.name}`} />
              </ListItemButton>
            );
          })}
        </Box>
      ) : null}

      <ListItemButton
        sx={{
          "&:hover": {
            background: window.location.pathname.includes(
              "/additional-services"
            )
              ? lightMode
                ? "#3e0404"
                : "#3b3a3a"
              : lightMode
              ? "#7b0000"
              : "#3a3a3a",
          },
          background: window.location.href.includes("/additional-services")
            ? lightMode
              ? "#570404"
              : "#404040"
            : "unset",
        }}
        href="/additional-services"
      >
        <ListItemIcon>
          <RoomServiceIcon sx={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText primary="Additional Services" />
        <ListItemText
          sx={{
            maxWidth: "fit-content",
            marginLeft: ".5rem",
            animation: " blinker 1s linear infinite",
            backgroundColor: "#ffffff",
            padding: ".25rem .5rem",
            borderRadius: "2rem",
          }}
        >
          <Typography
            sx={{
              color: "#444444",
            }}
            component="span"
            variant="body2"
            fontWeight={500}
          >
            New
          </Typography>
        </ListItemText>
      </ListItemButton>

      <ListItemButton
        sx={{
          "&:hover": {
            background: window.location.pathname.includes("/tickets")
              ? lightMode
                ? "#3e0404"
                : "#3b3a3a"
              : lightMode
              ? "#7b0000"
              : "#3a3a3a",
          },
          background: window.location.href.includes("/tickets")
            ? lightMode
              ? "#570404"
              : "#404040"
            : "unset",
        }}
        href="/tickets"
      >
        <ListItemIcon>
          <ConfirmationNumberIcon sx={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText primary="Tickets" />
      </ListItemButton>

      <ListItemButton
        sx={{
          "&:hover": {
            background: window.location.pathname.includes("/shop")
              ? lightMode
                ? "#3e0404"
                : "#3b3a3a"
              : lightMode
              ? "#7b0000"
              : "#3a3a3a",
          },
          background: window.location.href.includes("/shop")
            ? lightMode
              ? "#570404"
              : "#404040"
            : "unset",
        }}
        href="/shop"
      >
        <ListItemIcon>
          <StorefrontIcon sx={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText primary="Shop" />
      </ListItemButton>

      <ListItemButton
        sx={{
          "&:hover": {
            background: window.location.pathname.includes("/mybookings")
              ? lightMode
                ? "#3e0404"
                : "#3b3a3a"
              : lightMode
              ? "#7b0000"
              : "#3a3a3a",
          },
          background: window.location.href.includes("/mybookings")
            ? lightMode
              ? "#570404"
              : "#404040"
            : "unset",
        }}
        href="/mybookings"
      >
        <ListItemIcon>
          <BookOnlineIcon sx={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText primary="My Bookings" />
      </ListItemButton>

      {/* {branchesArray?.length && branchesArray?.length > 1 ? (
        <ListItemButton href="/mycommissions">
          <ListItemIcon>
            <HandshakeIcon sx={{ color: "#ffffff" }} />
          </ListItemIcon>
          <ListItemText primary="My Commissions" />
        </ListItemButton>
      ) : null} */}
      {profile?.account_type === "main_branch" &&
      profile?.agency_type !== "Institution" ? (
        // {profile?.account_type === "main_branch" ? (
        <ListItemButton
          sx={{
            "&:hover": {
              background: window.location.pathname.includes("/mycommissions")
                ? lightMode
                  ? "#3e0404"
                  : "#3b3a3a"
                : lightMode
                ? "#7b0000"
                : "#3a3a3a",
            },
            background: window.location.href.includes("/mycommissions")
              ? lightMode
                ? "#570404"
                : "#404040"
              : "unset",
          }}
          href="/mycommissions"
        >
          <ListItemIcon>
            <HandshakeIcon sx={{ color: "#ffffff" }} />
          </ListItemIcon>
          <ListItemText primary="My Commissions" />
        </ListItemButton>
      ) : null}

      <ListItemButton
        sx={{
          "&:hover": {
            background: window.location.pathname.includes("/room-finder")
              ? lightMode
                ? "#3e0404"
                : "#3b3a3a"
              : lightMode
              ? "#7b0000"
              : "#3a3a3a",
          },
          background: window.location.href.includes("/room-finder")
            ? lightMode
              ? "#570404"
              : "#404040"
            : "unset",
        }}
        href="/room-finder"
      >
        <ListItemIcon>
          <MeetingRoomSharpIcon sx={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText primary="Room Finder" />
      </ListItemButton>

      {/* <ListItemButton href="/settings">
        <ListItemIcon>
          <ManageAccountsIcon sx={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton> */}

      {profile?.account_type !== "user" ? (
        <>
          <ListItemButton
            sx={{
              borderBottom: settingsDropdown && open && "1px solid #ffffff",
              // backgroundColor: requestsDropdown && open && "rgba(0, 0, 0, 0.04)",
            }}
            onClick={() => {
              if (open) {
                setSettingsDropdown(!settingsDropdown);
              }
            }}
          >
            <ListItemIcon>
              <ManageAccountsIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>

            <ListItemText primary="Settings" />
            <ListItemIcon sx={{ justifyContent: "flex-end" }}>
              {settingsDropdown ? (
                <ArrowDropUpIcon sx={{ color: "#ffffff" }} />
              ) : (
                <ArrowDropDownIcon sx={{ color: "#ffffff" }} />
              )}
            </ListItemIcon>
          </ListItemButton>

          {settingsDropdown ? (
            <Box
              sx={settingsDropdownStyles}
              // sx={{
              //   height: "0",
              //   visibility: "hidden",
              //   transition: "height .4s",
              // }}
            >
              {settingsItems.map((item, idx) => {
                const isUrlMatched = window.location.href.includes(item.url);
                return (
                  <>
                    {/* <ListItemButton
                      href={`${item.url}`}
                      key={`${item}-${idx}`}
                      sx={{
                        "&:hover" : {
                          background: window.location.pathname.includes("myinvoices") ? lightMode ? "#3e0404" : "#3b3a3a" : lightMode ? "#7b0000" : "#3a3a3a"
                        },
                        background: isUrlMatched
                          ? lightMode
                            ? "#570404"
                            : "#404040"
                          : "unset",
                        cursor: "pointer",
                        pointerEvents: "auto",
                      }}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText primary={`${item.name}`} />
                    </ListItemButton> */}
                    {profile?.account_type === "sub_branch" ? (
                      item?.id !== "add-branch" ? (
                        <ListItemButton
                          href={`${item.url}`}
                          key={`${item}-${idx}`}
                          sx={{
                            "&:hover": {
                              background: isUrlMatched
                                ? lightMode
                                  ? "#3e0404"
                                  : "#3b3a3a"
                                : lightMode
                                ? "#570404"
                                : "#3a3a3a",
                            },
                            background: isUrlMatched
                              ? lightMode
                                ? "#570404"
                                : "#404040"
                              : "unset",
                            cursor: "pointer",
                            pointerEvents: "auto",
                          }}
                        >
                          <ListItemIcon></ListItemIcon>
                          <ListItemText primary={`${item.name}`} />
                        </ListItemButton>
                      ) : null
                    ) : (
                      <ListItemButton
                        href={`${item.url}`}
                        key={`${item}-${idx}`}
                        sx={{
                          "&:hover": {
                            background: isUrlMatched
                              ? lightMode
                                ? "#3e0404"
                                : "#3b3a3a"
                              : lightMode
                              ? "#570404"
                              : "#3a3a3a",
                          },
                          background: isUrlMatched
                            ? lightMode
                              ? "#570404"
                              : "#404040"
                            : "unset",
                          cursor: "pointer",
                          pointerEvents: "auto",
                        }}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={`${item.name}`} />
                      </ListItemButton>
                    )}
                  </>
                );
              })}
            </Box>
          ) : null}
        </>
      ) : null}

      {/* <ListItemButton href="/shop">
        <ListItemIcon>
          <CloudDownloadIcon sx={{color: '#ffffff'}} />
        </ListItemIcon>
        <ListItemText primary="Shop" />
      </ListItemButton> */}

      {/* <ListItemButton href="/user-guide">
        <ListItemIcon>
          <MenuBookIcon sx={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText primary="User Guide" />
      </ListItemButton> */}

      <ListItemButton
        href={`/user-guide`}
        sx={{
          "&:hover": {
            background: window.location.pathname.includes("/user-guide")
              ? lightMode
                ? "#3e0404"
                : "#3b3a3a"
              : lightMode
              ? "#7b0000"
              : "#3a3a3a",
          },
          background: window.location.href.includes("/user-guide")
            ? lightMode
              ? "#570404"
              : "#404040"
            : "unset",
          cursor: "pointer !important",
          pointerEvents: "auto !important",
        }}
      >
        <ListItemIcon>
          <MenuBookIcon sx={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText primary={`User Guide`} />
      </ListItemButton>
      {isMobile ? (
        <>
          <ListItemButton
            href={`${!token.accessToken ? `/login` : ``}`}
            onClick={() => token.accessToken && logOutFunc()}
          >
            <ListItemIcon>
              {token.accessToken ? (
                <LogoutIcon sx={{ color: "#ffffff" }} />
              ) : (
                <LoginIcon sx={{ color: "#ffffff" }} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={`${token.accessToken ? `Log Out` : `Log In`}`}
            />
          </ListItemButton>

          <ListItemButton
            sx={{
              "&:hover": {
                background: window.location.pathname.includes("/profile")
                  ? lightMode
                    ? "#3e0404"
                    : "#3b3a3a"
                  : lightMode
                  ? "#7b0000"
                  : "#3a3a3a",
              },
              background: window.location.href.includes("/profile")
                ? lightMode
                  ? "#570404"
                  : "#404040"
                : "unset",
              cursor: "pointer !important",
              pointerEvents: "auto !important",
            }}
            href={`${!token.accessToken ? `/login` : `/profile`}`}
          >
            <ListItemIcon>
              <PersonIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            <ListItemText primary={`Profile`} />
          </ListItemButton>
        </>
      ) : null}
    </React.Fragment>
  );
}
