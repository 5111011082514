import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const loadMap = (
  mapRef,
  locations,
  mapContainer,
  lng,
  lat,
  zoom,
  setDisabledControl,
  setLng,
  setLat,
  setZoom
) => {
  if (locations.length && localStorage.getItem("lightMode")) {
    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/outdoors-v12",
      center: [lng, lat],
      projection: "mercator", //new
      zoom: zoom,
    });

    mapRef.current.on("load", () => {
      const bigRectangle = [
        [-180, -85],
        [180, -85],
        [180, 85],
        [-180, 85],
        [-180, -85],
      ];

      setDisabledControl(false);
    });

    mapRef.current.on("move", () => {
      setLng(mapRef.current.getCenter().lng.toFixed(4));
      setLat(mapRef.current.getCenter().lat.toFixed(4));
      setZoom(mapRef.current.getZoom().toFixed(2));
    });

    const handleResizeAndOrientationChange = () => {
      setTimeout(() => {
        mapRef.current.resize();
      }, 200);
    };

    window.addEventListener("resize", handleResizeAndOrientationChange);
    window.addEventListener(
      "orientationchange",
      handleResizeAndOrientationChange
    );

    return () => {
      window.removeEventListener("resize", handleResizeAndOrientationChange);
      window.removeEventListener(
        "orientationchange",
        handleResizeAndOrientationChange
      );
    };
  }
};
