import { Box, Button, Link, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SilverBadge from "../../../assets/images/badges/silver.svg";
import PlatinumBadge from "../../../assets/images/badges/platinum.svg";
import GoldBadge from "../../../assets/images/badges/gold.svg";
import BronzeBadge from "../../../assets/images/badges/bronze.svg";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../axiosInstance";
import axios from "axios";

export default function ProgressBar({ barType }) {
  const [currentpercentage, setCurrentPercentage] = useState(0);
  const [currentBadge, setCurrentBadge] = useState(null);
  const [targetBadge, setTargetBadge] = useState(null);

  const profile = useSelector((state) => state.profile);
  const [dataShow, setDataShow] = useState(false);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const [loading, setLoading] = useState(false);
  const getBadgeDetails = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}dashboard/badge`;

    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor,
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      if (response?.data?.target_badge?.percentage > 100) {
        setCurrentPercentage(100);
      } else {
        setCurrentPercentage(response?.data?.target_badge?.percentage);
      }
      setCurrentBadge(response.data.current_badge);
      setTargetBadge(response.data.target_badge);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled:", error.message);
      } else {
        console.error("API isteği sırasında hata oluştu:", error);
      }
    }
  };

  useEffect(() => {
    getBadgeDetails();
  }, []);

  const navigate = useNavigate();
  return (
    <Box sx={{ width: "100%", cursor: "pointer", minHeight: "80px" }}>
      {currentBadge && targetBadge ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              left: "15px",
            }}
          >
            {currentBadge ? (
              <Tooltip
                title={currentBadge}
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -14],
                        },
                      },
                    ],
                  },
                }}
              >
                <img
                  style={{
                    cursor: "pointer",
                    borderRadius: "10rem",
                    boxShadow: "0 0 5px #cecece",
                    zIndex: "9",
                    background: lightMode ? "#ffffff" : "#000000",
                  }}
                  width={"40px"}
                  src={
                    currentBadge?.toLocaleLowerCase().includes("gold")
                      ? GoldBadge
                      : currentBadge?.toLocaleLowerCase().includes("silver")
                      ? SilverBadge
                      : currentBadge?.toLocaleLowerCase().includes("platinum")
                      ? PlatinumBadge
                      : currentBadge?.toLocaleLowerCase().includes("bronze")
                      ? BronzeBadge
                      : null
                  }
                />
              </Tooltip>
            ) : null}
            <Box
              onMouseEnter={() => !dataShow && setDataShow(true)}
              onMouseLeave={() => dataShow && setDataShow(false)}
              sx={{
                width: "100%",
                height: "15px",
                position: "relative",
                background: lightMode ? "#efefef" : "#000000",
                borderRadius: "3rem",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                transition: "width .4s",
                display: "flex",
                flexDirection: "column",
                boxShadow: "inset 0 1px 2px rgba(0,0,0,.1)",
                left: "-10px",
                "&::before": {
                  content: '""',
                  boxShadow: "inset 0 1px 2px rgb(0 0 0 / 23%)",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "15px",
                  borderRadius: "3rem",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  transition: "width .4s",
                  width: `${currentpercentage}%`,
                  background:
                    "linear-gradient(90deg, rgb(108 0 0) 0%, rgb(128 9 9) 35%, rgb(148 0 0) 100%)",
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: "calc(50% - 6px)",
                  left: `calc(${currentpercentage}% - 14px)`,
                  transition: "left .4s",
                  height: "12px",
                  width: "12px",
                  borderRadius: "100%",
                  boxShadow: "0 0 5px #000000",
                  background: lightMode ? "#ffffff" : "#000000",
                },
              }}
            >
              {dataShow ? (
                <Box
                  sx={{
                    width: "61px",
                    height: "100px",
                    background: "#800000",
                    borderRadius: "3rem",
                    zIndex: 9,
                    left: `calc(${currentpercentage}% - 39px)`,
                    position: "relative",
                    bottom: "60px",
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0 1px 2px black",
                    zIndex: 9,

                    "&:before": {
                      content: '""',
                      position: "absolute",
                      bottom: "-30px",
                      zIndex: 0,
                      left: "calc(50% - 1px)",
                      width: "2px",
                      height: "30px",
                      borderBottomLeftRadius: "1rem",
                      borderBottomRightRadius: "1rem",
                      boxShadow: "0 1px 2px black",
                      backgroundColor: "#800000",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      width: "fit-content",
                      textAlign: "center",
                      margin: "auto",
                      color: "#ffffff",
                    }}
                  >
                    %{currentpercentage}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            {targetBadge ? (
              <Tooltip
                title={targetBadge?.badge}
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -14],
                        },
                      },
                    ],
                  },
                }}
              >
                <img
                  style={{
                    position: "relative",
                    right: "30px",
                    cursor: "pointer",
                    borderRadius: "10rem",
                    boxShadow: "0 0 5px #cecece",
                    zIndex: "9",
                    background: lightMode ? "#ffffff" : "#000000",
                  }}
                  width={"40px"}
                  src={
                    targetBadge?.badge?.toLocaleLowerCase().includes("gold")
                      ? GoldBadge
                      : targetBadge?.badge
                          ?.toLocaleLowerCase()
                          .includes("silver")
                      ? SilverBadge
                      : targetBadge?.badge
                          ?.toLocaleLowerCase()
                          .includes("platinum")
                      ? PlatinumBadge
                      : targetBadge?.badge
                          ?.toLocaleLowerCase()
                          .includes("bronze")
                      ? BronzeBadge
                      : null
                  }
                />
              </Tooltip>
            ) : null}
          </Box>
          <Box sx={{ display: "flex", marginTop: "1rem" }}>
            {/* <Button
           variant="contained"
           onClick={() => setCurrentPercentage(currentpercentage + 10)}
         >
           Arttır
         </Button>
         <Button
           variant="contained"
           onClick={() => setCurrentPercentage(currentpercentage - 10)}
         >
           Azalt
         </Button> */}
            <Link
              sx={{
                marginLeft: "auto",
                marginRight: { xs: "auto", md: "0" },
                display: "block",
                textDecoration: "none",
                color: lightMode ? "#800000" : "#ffffff",
                position: "relative",
                right: { xs: "0", md: "20px" },
              }}
              onClick={() => navigate("/profile")}
            >
              More Info
            </Link>
          </Box>
        </>
      ) : null}
    </Box>
  );
}
