import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { closeModal } from "../../../../redux/actions/actions";
import { useDispatch } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axiosInstance from "../../../../axiosInstance";
import Swal from "sweetalert2";

export default function SendEmailModal({ quoteDetails }) {
  const [formValues, setFormValues] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const lettersRegex = /^[A-Za-z]+$/;

    if (name === "branch") {
      // name veya surname için sadece harflere izin ver
      if (value.match(lettersRegex) || value === "") {
        // Eğer value harflerden oluşuyorsa veya boş ise, güncelle
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      // Diğer karakterler girildiğinde herhangi bir işlem yapılmaz (böylece numara/özel karakter girişi engellenir)
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    // Basit doğrulama için örnekler
    const errors = {};

    // Email doğrulaması için basit bir regex kullanımı
    if (!/\S+@\S+\.\S+/.test(formValues?.email_address)) {
      errors.email_address = "Email is not valid";
    }

    // Daha fazla alan için benzer doğrulamalar eklenebilir

    return errors;
  };
  useEffect(() => {
    if (formValues?.email_address) {
      const errors = validateForm();
      setFormErrors(errors);
      // Eğer hata varsa, hataları göster ve fonksiyonu sonlandır
      if (Object.keys(errors).length > 0) {
        // Hataları kullanıcıya göstermek için bir mekanizma ekleyebilirsiniz
        // Örneğin, bir state kullanarak form alanlarının altında hata mesajları gösterebilirsiniz
        return; // Hatalar varsa, burada fonksiyonu sonlandır
      }
    }
  }, [formValues]);

  useEffect(() => {
    const originalMessage =
      "I hope you are doing well.\nA special quote has been prepared for you.\nClick the View quote details button for details.\nIf you have any questions, please don't hesitate to let me know.";
    // const formattedMessage = originalMessage.replace(
    //   /(\.|\?|\!)(\s)/g,
    //   "$1\n\n"
    // );

    setFormValues((prev) => ({
      ...prev,
      // email_address: quoteDetails?.quote?.student?.student_email,
      email_address: window.location.href.includes(
        "https://partners.londonist.co.uk"
      )
        ? quoteDetails?.quote?.student?.student_email
        : "",
      subject: "Londonist Student Accommodation: Quote Details",
      message: originalMessage,
    }));
  }, []);

  const handleSendEmail = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}email_quote`;
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    const formattedMessage = formValues.message.replace(/\n/g, "<br><br>");
    const htmlMessage = `<p style="color: #9b9ea2">${formattedMessage}</p>`;

    const appendForm = {
      quote_id: quoteDetails?.quote?.quote_id,
      subject: formValues?.subject,
      message: htmlMessage,
      email: formValues?.email_address,
    };


    setLoader(true);
    try {
      // Axios ile POST isteği yapılıyor
      const response = await axiosInstance.post(url, appendForm, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      // İstek başarılı olduğunda sunucudan dönen yanıtı göster
      setLoader(false);
      Swal.fire({
        icon: "success",
        title: response?.data?.message,
        showConfirmButton: false,
        showCloseButton: true,
      });
      handleClose();
    } catch (error) {
      // Hata durumunda hata mesajını göster
      setLoader(false);
      handleClose();
      Swal.fire({
        icon: "error",
        title: "An error occurred while sending email!",
        showConfirmButton: false,
        showCloseButton: true,
      });
      // dispatch(
      //   openModal(<ErrorModal data={error.response.data} />, "selectStudent")
      // );
      console.error("Error during the request:", error.message);
    }
  };
  return (
    <>
      {loader ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
          }}
        />
      ) : null}
      <Box sx={{ position: "relative" }}>
        <Box sx={{ position: "relative", opacity: loader ? ".4" : 1 }}>
          <TextField
            sx={{
              width: "100%",
              marginBottom: "1rem",
            }}
            value={formValues?.email_address || ""}
            onChange={handleChange}
            name="email_address"
            id="outlined-basic"
            label="Email Address"
            variant="outlined"
            type="email"
            required
            error={!!formErrors.email_address}
            helperText={formErrors.email_address || ""}
          />
          <TextField
            sx={{
              width: "100%",
              marginBottom: "1rem",
            }}
            value={formValues?.subject}
            onChange={handleChange}
            name="subject"
            id="outlined-basic"
            label="Subject"
            variant="outlined"
            type="text"
            required
          />
          <TextField
            sx={{
              width: "100%",
              marginBottom: "1rem",
            }}
            value={formValues?.message}
            onChange={handleChange}
            name="message"
            id="outlined-basic"
            label="Message"
            variant="outlined"
            type="text"
            multiline
            rows={3}
            required
          />
          <Button
            sx={{ marginTop: "1rem", height: "56px" }}
            onClick={handleSendEmail}
            disabled={
              !formValues?.email_address ||
              !formValues?.subject ||
              !formValues?.message ||
              formErrors.email_address
            }
            fullWidth
            variant="contained"
            color="primary"
          >
            Send
          </Button>
        </Box>
      </Box>
    </>
  );
}
