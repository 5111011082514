import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../axiosInstance";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { BarChart } from "@mui/x-charts";

export const BarChartComp = () => {
  const [salesData, setSalesData] = useState(null);
  const [chartDataType, setChartDataType] = useState("daily");
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [loading, setLoading] = useState(true);

  const getSalesData = async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let token = localStorage.getItem("loginUser");
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}dashboard/sales_chart`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            XRefreshToken: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      setSalesData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSalesData();
  }, []);

  useEffect(() => {
    if (salesData) {
      const processedData = processSalesData(salesData, chartDataType);
      setChartData(processedData);
    }
  }, [salesData, chartDataType]);

  const processSalesData = (data, type) => {
    let labels, seriesData;

    if (type === "daily" && data?.daily_sales.length) {
      labels = data?.daily_sales?.map((sale) => `${sale.day.toString()}`);
      seriesData = [
        {
          label: "Daily Sales",
          data: data?.daily_sales?.map((item) => item.total_sales),
          color: "#bf0000",
        },
      ];
    } else if (type === "daily" && !data?.daily_sales.length) {
      labels = [""];
      seriesData = [
        {
          label: "Daily Sales",
          data: [0],
          color: "#bf0000",
        },
      ];
    } else if (type === "monthly" && data?.monthly_sales.length) {
      labels = data?.monthly_sales?.map(
        (sale) => `${sale.month.toString().substring(0, 3)}`
      );
      seriesData = [
        {
          label: "Monthly Sales",
          data: data?.monthly_sales?.map((item) => item.total_sales),
          color: "#bf0000",
        },
      ];
    }

    return {
      labels,
      datasets: seriesData,
    };
  };

  const handleChange = (event) => {
    setChartDataType(event.target.value);
  };

  return (
    <Box
      sx={{
        minHeight: "300px",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{ width: "100%", textAlign: "start", marginBottom: "1.5rem" }}
          variant="h6"
          color="primary"
        >
          Sales
        </Typography>
        <FormControl sx={{ minWidth: "125px", marginLeft: "auto" }}>
          <InputLabel id="chart-type-select-label">Type</InputLabel>
          <Select
            labelId="chart-type-select-label"
            id="chart-type-select"
            value={chartDataType}
            label="Type"
            onChange={handleChange}
          >
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {!loading ? (
        <Box
          sx={{
            marginTop: "auto",
            marginBottom: "auto",
            overflow: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {chartData?.datasets?.length ? (
            // <BarChart
            //   height={300}
            //   series={chartData?.datasets?.map((dataset) => ({
            //     ...dataset,
            //     highlightScope: {
            //       highlighted: "series",
            //       faded: "global",
            //     },
            //   }))}
            //   xAxis={[{ scaleType: "band", data: chartData?.labels }]}
            // />
            <BarChart
              height={300}
              series={chartData?.datasets?.map((dataset) => ({
                ...dataset,
                highlightScope: {
                  highlighted: "series",
                  faded: "global",
                },
              }))}
              xAxis={[
                {
                  scaleType: "band",
                  data: chartData?.labels,
                  // Örnek bir döndürme özelliği (eğer mevcutsa)
                  tickLabelStyle: {
                    angle: 45,
                    textAnchor: "start",
                    fontSize: 10,
                  },
                },
              ]}
            />
          ) : null}
        </Box>
      ) : (
        <CircularProgress sx={{ margin: "auto" }} />
      )}
    </Box>
  );
};
