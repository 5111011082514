import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapResidenceDetails = ({ mapRef, locationDatas }) => {
  const mapContainer = useRef(null);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const [lng, setLng] = useState(locationDatas[0]?.lng);
  const [lat, setLat] = useState(locationDatas[0]?.lat);
  const [zoom, setZoom] = useState(17);
  const markersRef = useRef({}); // Marker'lar için bir ref objesi oluştur

  useEffect(() => {
    if (localStorage.getItem("lightMode")) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: lightMode
          ? "mapbox://styles/mapbox/outdoors-v12"
          : "mapbox://styles/mapbox/dark-v11",
        projection: "mercator",
        zoom: zoom,
        center: [lng, lat],
      });

      mapRef.current.dragPan.enable();
      mapRef.current.touchZoomRotate.enable();

      mapRef.current.on("move", () => {
        setLng(mapRef.current.getCenter().lng?.toFixed(4));
        setLat(mapRef.current.getCenter().lat?.toFixed(4));
        setZoom(mapRef.current.getZoom().toFixed(4));
      });

      // Tüm marker'ları temizle
      Object.keys(markersRef.current).forEach((key) => {
        markersRef.current[key].remove();
      });
      markersRef.current = {};

      // API'den dönen verilerle marker'ları ekleyin
      locationDatas.forEach((location, index) => {
        const marker = new mapboxgl.Marker({
          color: "#800000",
        })
          .setLngLat([location.lng, location.lat])
          .addTo(mapRef.current);

        markersRef.current[`marker-${index}`] = marker;
      });

      const handleResizeAndOrientationChange = () => {
        setTimeout(() => {
          mapRef.current.resize();
        }, 200); // 200ms gecikme ile resize metodunu çağırıyoruz.
      };

      window.addEventListener("resize", handleResizeAndOrientationChange);
      window.addEventListener(
        "orientationchange",
        handleResizeAndOrientationChange
      );

      // Component temizlendiğinde (unmount) olay dinleyicilerini kaldır
      return () => {
        window.removeEventListener("resize", handleResizeAndOrientationChange);
        window.removeEventListener(
          "orientationchange",
          handleResizeAndOrientationChange
        );
      };
    }
  }, [locationDatas, lightMode]);
  return (
    <Box
      ref={mapContainer}
      className="map-container"
      sx={{
        height: "500px",
        width: "100%",
        borderRadius: "1rem",
        overflow: "hidden",
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          visibility: "hidden",
          bottom: "0",
          width: "100%",
          height: "30px",
          background: "#000000",
        },
      }}
    ></Box>
  );
};

export default MapResidenceDetails;
