import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Map from "./Map";
import Locations from "./Locations";
import axiosInstance from "../../../../axiosInstance";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import SearchResidences from "./SearchResidences";
import ResidencesContext from "../../../../contexts/Residences/ResidencesContext";
import Swal from "sweetalert2";

export default function Residences({ open, setOpen, isMobile, setIsMobile }) {
  const {
    locations,
    setLocations,
    nearByUniversities,
    setNearByUniversities,
    selectedLocation,
    universityTabControl,
    setUniversityTabControl,
    setSelectedLocation,
    setSearchedResidence,
    setSearchTerm,
    disabledControl,
    setDisabledControl,
    publicType,
    setPublicType,
    selectedLocationNearBy,
    setSelectedLocationNearBy,
  } = useContext(ResidencesContext);
  const [loading, setLoading] = useState(false);

  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const mapRef = useRef(null);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const moveToLocation = (lng, lat, type) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [lng, lat],
        essential: true,
        zoom: type ? type : 10,
      });
      // mapRef.current.once("movestart", () => {
      //   setDisabledControl(true);
      // });
      // mapRef.current.once("moveend", () => {
      //   setDisabledControl(false);
      // });
    }
  };

  const getNearByUbiversities = async (type, selectedLocation) => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    setLoading(true);
    try {
      let url;
      if (selectedLocation?.university_id && type === "near_residences") {
        url = `near_residences?university_id=${selectedLocation?.university_id}`;
      } else {
        url = `near_universities?campus_id=${selectedLocation?.campus_id}`;
      }

      if (selectedLocation?.campus_id && type === "near_universities") {
        url = `near_universities?campus_id=${selectedLocation?.campus_id}`;
      } else {
        url = `near_residences?university_id=${selectedLocation?.university_id}`;
      }
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor,
          Accounts: selectedBranchesString,
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });
      if (type === "near_universities") {
        setPublicType(null);
        setNearByUniversities(response.data.universities);
      } else {
        setPublicType(null);
        setNearByUniversities(response.data.residences);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error sending data:", error.response);
    }
  };

  const getLocations = async (type) => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${type === "residence" ? `dashboard/residences` : `universities`}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor,
            Accounts: selectedBranchesString,
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        }
      );
      if (type === "residence") {
        setLocations(response.data);
      } else {
        setNearByUniversities(null);
        setSelectedLocation(null);
        setLocations(response.data?.universities);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error sending data:", error.response);
    }
  };

  // useEffect(() => {
  //   getLocations("residence");
  // }, [selectedBranchesString]);

  useEffect(() => {
    setSearchedResidence("");
    setSearchTerm("");
    setNearByUniversities(null);
    setSelectedLocation(null);
    // moveToLocation(-0.1278, 51.5074, 32);
    if (universityTabControl) {
      getLocations("university");
      setNearestPlaceType("residences");
    } else {
      getLocations("residence");
      setNearestPlaceType("universities");
    }
  }, [universityTabControl]);

  useEffect(() => {
    if (selectedLocation !== "" && !selectedLocation?.nearControl) {
      if (universityTabControl) {
        getNearByUbiversities("near_residences", selectedLocation);
      } else {
        getNearByUbiversities("near_universities", selectedLocation);
      }
    }
  }, [selectedLocation]);

  const mapComponent = useMemo(() => {
    if (locations?.length) {
      return (
        <>
          <Grid item xs={12} lg={8} sx={{ position: "relative" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "500px",
                height: "100%",
                // padding: "1rem",
                // background: lightMode ? "#fafafa" : "#292929",
                position: "relative",
              }}
            >
              {loading ? (
                <Box sx={{ height: "500px", width: "100%" }}>
                  <Skeleton
                    width={"100%"}
                    height={"100%"}
                    sx={{ transform: "scale(1,1)", borderRadius: "1rem" }}
                  />
                </Box>
              ) : (
                <>
                  <Map
                    mapRef={mapRef}
                    open={open}
                    setOpen={setOpen}
                    isMobile={isMobile}
                    setIsMobile={setIsMobile}
                  />
                  <SearchResidences
                    mapRef={mapRef}
                    moveToLocation={moveToLocation}
                  />
                </>
              )}
            </Box>
          </Grid>
        </>
      );
    }
    return null;
  }, [locations, open]);

  const titleRef = useRef();

  const getNearestLocations = async (type) => {
    setSelectedLocationNearBy(null);
    let locationBox;
    if (universityTabControl) {
      locationBox = `${
        parseFloat(selectedLocation?.university_latitude) - 0.01
      }, ${parseFloat(selectedLocation?.university_longitude) - 0.01}, ${
        parseFloat(selectedLocation?.university_latitude) + 0.01
      }, ${parseFloat(selectedLocation?.university_longitude) + 0.01}  `;
    } else {
      locationBox = `${
        parseFloat(selectedLocation?.campus_coordinates?.lat) - 0.01
      }, ${parseFloat(selectedLocation?.campus_coordinates?.lng) - 0.01}, ${
        parseFloat(selectedLocation?.campus_coordinates?.lat) + 0.01
      }, ${parseFloat(selectedLocation?.campus_coordinates?.lng) + 0.01}  `;
    }

    let url;
    if (type === "bus") {
      url = `
      https://overpass-api.de/api/interpreter?data=[out:json];node["highway"="bus_stop"](${locationBox});out body;
      `;
    } else if (type === "train") {
      url = `
      https://overpass-api.de/api/interpreter?data=[out:json];node["railway"="station"](${locationBox});out body;
      `;
    } else if (type === "market") {
      url = `
      https://overpass-api.de/api/interpreter?data=[out:json];node["shop"="supermarket"](${locationBox});out body;
      `;
    } else if (type === "pharmacie") {
      url = `
      https://overpass-api.de/api/interpreter?data=[out:json];node["amenity"="pharmacy"](${locationBox});out body;
      `;
    } else if (type === "restaurant") {
      url = `
        https://overpass-api.de/api/interpreter?data=[out:json];node["amenity"="restaurant"](${locationBox});out body;
        `;
    }

    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      const response = await axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });
      if (type === "bus") {
        setPublicType("bus");
      } else if (type === "train") {
        setPublicType("train");
      } else if (type === "market") {
        setPublicType("market");
      } else if (type === "pharmacie") {
        setPublicType("pharmacie");
      } else if (type === "restaurant") {
        setPublicType("restaurant");
      }
      if (!response?.data?.elements?.length) {
        setPublicType(null);
        Swal.fire({
          icon: "error",
          title: `No ${
            type === "bus"
              ? `Bus Stations`
              : type === "train"
              ? `Train Stations`
              : type === "market"
              ? `Supermarkets`
              : type === "pharmacie"
              ? `Pharmacies`
              : type === "restaurant"
              ? `Restaurants`
              : null
          } Found for ${
            universityTabControl
              ? `${selectedLocation?.university_title} `
              : `${selectedLocation?.campus_name} `
          }`,
          showConfirmButton: false,
          showCloseButton: true,
        });
      } else {
        setNearByUniversities(response?.data?.elements);
      }
    } catch (error) {
      console.error("API isteği sırasında hata oluştu:", error);
    }
  };
  useEffect(() => {
    if (nearByUniversities?.length) {
      setDisabledControl(false);
    }
  }, [nearByUniversities]);

  const customRedPalette = {
    light: "#ff0000", // En açık kırmızı tonu
    main: lightMode ? "#bf0000" : "#ffffff", // Ana kırmızı tonu
    dark: "#800000", // Koyu kırmızı tonu
    extraDark: "#600000", // Koyu kırmızı tonu
    hardDark: "#400000", // Bu kırmızı tonları ile kontrast oluşturacak metin rengi
  };
  const theme = createTheme({
    palette: {
      mode: lightMode ? "light" : "dark", // Modu Redux state'ine bağlayın
      primary: {
        main: customRedPalette.main, // Özelleştirilmiş ana renk
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            "&.Mui-disabled": {
              border: "none",
            },
          },
        },
      },
    },
  });

  const [nearestPlaceType, setNearestPlaceType] = React.useState(
    universityTabControl ? "residences" : "universities"
  );
  const handleChange = (event) => {
    setNearestPlaceType(event.target.value);
    if (
      event.target.value === "universities" ||
      event.target.value === "residences"
    ) {
      setSelectedLocationNearBy(null);
      getNearByUbiversities(
        universityTabControl ? "near_residences" : "near_universities",
        selectedLocation
      );
    } else if (event.target.value === "bus-stations") {
      getNearestLocations("bus");
    } else if (event.target.value === "train-stations") {
      getNearestLocations("train");
    } else if (event.target.value === "supermarkets") {
      getNearestLocations("market");
    } else if (event.target.value === "pharmacies") {
      getNearestLocations("pharmacie");
    } else if (event.target.value === "restaurants") {
      getNearestLocations("restaurant");
    }
  };

  return locations?.length ? (
    <ThemeProvider theme={theme}>
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "start",
                  position: "sticky",
                  top: "0",
                  left: "0",
                  zIndex: "99",
                  background: lightMode ? "#ffffff" : "#343434",
                }}
                variant="h6"
                color="primary"
              >
                Residence Locations
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
                borderBottom: "1px solid #efefef",
              }}
            >
              <Button
                sx={{
                  border: "none",
                  borderRadius: "0",
                  fontSize: "20px",
                  color: universityTabControl ? "black" : "#800000",
                  position: "relative",
                  "&:hover": {
                    border: "none !important",
                  },
                  "&:before": {
                    content: '""',
                    borderTopRightRadius: "1rem",
                    borderBottomRightRadius: "1rem",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    width: "100%",
                    height: "2px",
                    background: "#800000",
                    display: universityTabControl ? "none" : "block",
                  },
                }}
                disabled={loading || disabledControl}
                variant="outlined"
                onClick={() => (
                  setDisabledControl(true),
                  setUniversityTabControl(false),
                  setSelectedLocation(null),
                  setSelectedLocationNearBy(null)
                )}
              >
                {`Residences`}
              </Button>
              <Button
                sx={{
                  border: "none",
                  borderRadius: "0",
                  fontSize: "20px",
                  color: universityTabControl ? "#800000" : "black",
                  position: "relative",
                  "&:hover": {
                    border: "none !important",
                  },
                  "&:before": {
                    content: '""',
                    borderRadius: "1rem",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    width: "100%",
                    height: "2px",
                    background: "#800000",
                    display: universityTabControl ? "block" : "none",
                  },
                }}
                disabled={loading || disabledControl}
                variant="outlined"
                onClick={() => (
                  setDisabledControl(true),
                  setSelectedLocationNearBy(null),
                  setUniversityTabControl(true),
                  setSelectedLocation(null)
                )}
              >
                {`Universities`}
              </Button>
            </Box>
          </Grid>

          {mapComponent}

          <Grid
            item
            xs={12}
            lg={4}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {nearByUniversities?.length ? (
              <>
                <Typography
                  ref={titleRef}
                  variant="h5"
                  sx={{
                    marginBottom: "1rem",
                  }}
                >
                  Nearest Places for{" "}
                  <Typography>
                    {universityTabControl
                      ? `${selectedLocation?.university_title} `
                      : `${selectedLocation?.campus_name} `}
                  </Typography>
                </Typography>

                {nearestPlaceType ? (
                  <Box sx={{ minWidth: 120, marginBottom: "1rem" }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Nearest Place Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={nearestPlaceType}
                        value={
                          nearestPlaceType ||
                          (universityTabControl ? "residences" : "universities")
                        }
                        label="Nearest Place Type"
                        onChange={handleChange}
                      >
                        <MenuItem
                          sx={{
                            display: universityTabControl ? "block" : "none",
                          }}
                          value={"residences"}
                        >
                          Residences
                        </MenuItem>
                        <MenuItem
                          sx={{
                            display: universityTabControl ? "none" : "block",
                          }}
                          value={"universities"}
                        >
                          Universities
                        </MenuItem>
                        <MenuItem value={"bus-stations"}>Bus Stations</MenuItem>
                        <MenuItem value={"train-stations"}>
                          Train Stations
                        </MenuItem>
                        <MenuItem value={"supermarkets"}>Supermarkets</MenuItem>
                        <MenuItem value={"pharmacies"}>Pharmacies</MenuItem>
                        <MenuItem value={"restaurants"}>Restaurants</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                ) : null}

                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    borderBottom: "1px solid #efefef",
                    marginBottom: "1rem",
                    overflowX: "auto",
                    height: "50px",
                  }}
                >
                  <Button
                    sx={{
                      minWidth: "max-content",
                      "&:before": {
                        content: '""',
                        borderTopRightRadius: "1rem",
                        borderBottomRightRadius: "1rem",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "100%",
                        height: "2px",
                        background: "#800000",
                        display: publicType === null ? "block" : "none",
                      },
                      color:
                        nearByUniversities?.length && publicType === null
                          ? "#800000"
                          : "#000000",
                    }}
                    onClick={() => {
                      setSelectedLocationNearBy(null);
                      getNearByUbiversities(
                        universityTabControl
                          ? "near_residences"
                          : "near_universities",
                        selectedLocation
                      );
                    }}
                  >
                    {universityTabControl ? `Residences` : `Universities`}
                  </Button>

                  <Button
                    sx={{
                      minWidth: "max-content",
                      "&:before": {
                        content: '""',
                        borderTopRightRadius: "1rem",
                        borderBottomRightRadius: "1rem",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "100%",
                        height: "2px",
                        background: "#800000",
                        display: publicType === "bus" ? "block" : "none",
                      },
                      color:
                        nearByUniversities?.length && publicType === "bus"
                          ? "#800000"
                          : "#000000",
                    }}
                    onClick={() => getNearestLocations("bus")}
                  >
                    Bus Stations
                  </Button>

                  <Button
                    sx={{
                      minWidth: "max-content",
                      "&:before": {
                        content: '""',
                        borderTopRightRadius: "1rem",
                        borderBottomRightRadius: "1rem",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "100%",
                        height: "2px",
                        background: "#800000",
                        display: publicType === "train" ? "block" : "none",
                      },
                      color:
                        nearByUniversities?.length && publicType === "train"
                          ? "#800000"
                          : "#000000",
                    }}
                    onClick={() => getNearestLocations("train")}
                  >
                    Train Stations
                  </Button>

                  <Button
                    sx={{
                      minWidth: "max-content",
                      "&:before": {
                        content: '""',
                        borderTopRightRadius: "1rem",
                        borderBottomRightRadius: "1rem",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "100%",
                        height: "2px",
                        background: "#800000",
                        display: publicType === "market" ? "block" : "none",
                      },
                      color:
                        nearByUniversities?.length && publicType === "market"
                          ? "#800000"
                          : "#000000",
                    }}
                    onClick={() => getNearestLocations("market")}
                  >
                    Supermarkets
                  </Button>

                  <Button
                    sx={{
                      minWidth: "max-content",
                      "&:before": {
                        content: '""',
                        borderTopRightRadius: "1rem",
                        borderBottomRightRadius: "1rem",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "100%",
                        height: "2px",
                        background: "#800000",
                        display: publicType === "pharmacie" ? "block" : "none",
                      },
                      color:
                        nearByUniversities?.length && publicType === "pharmacie"
                          ? "#800000"
                          : "#000000",
                    }}
                    onClick={() => getNearestLocations("pharmacie")}
                  >
                    Pharmacies
                  </Button>

                  <Button
                    sx={{
                      minWidth: "max-content",
                      "&:before": {
                        content: '""',
                        borderTopRightRadius: "1rem",
                        borderBottomRightRadius: "1rem",
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "100%",
                        height: "2px",
                        background: "#800000",
                        display: publicType === "restaurant" ? "block" : "none",
                      },
                      color:
                        nearByUniversities?.length &&
                        publicType === "restaurant"
                          ? "#800000"
                          : "#000000",
                    }}
                    onClick={() => getNearestLocations("restaurant")}
                  >
                    Restaurants
                  </Button>
                </Box> */}
              </>
            ) : null}

            <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // background: lightMode ? "#fafafa" : "#292929",
                  height: locations?.length ? "auto" : "100%",
                  position: "relative",
                }}
              >
                <>
                  <Locations moveToLocation={moveToLocation} />
                </>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    </ThemeProvider>
  ) : (
    <CircularProgress />
  );
}
