import { Box, Checkbox, Typography, Button } from "@mui/material";
import { getTheme } from "../../../styles/theme";
import roomImg from "../../../assets/images/rooms/room.jpg";
import roomImg2 from "../../../assets/images/rooms/room2.jpg";
import PlaceIcon from "@mui/icons-material/Place";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import BedroomChildIcon from "@mui/icons-material/BedroomChild";
import dayjs from "dayjs";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

export default function Rooms({
  idx,
  data,
  formValues,
  selectedRooms,
  setSelectedRooms,
  bookingData,
  setBookingData,
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const checkIn = queryParams.get("checkIn");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 999);

  useEffect(() => {
    // Eğer sadece bir oda seçiliyse, instantBookingData'yı güncelle
    if (selectedRooms.length === 1) {
      // formValues'dan checkIn ve checkOut değerlerini alıyoruz

      const selectedRoomData = selectedRooms[0]; // Tek seçili oda

      // selectedRoomData'nın bir kopyasını oluşturuyoruz ve checkIn/checkOut bilgilerini ekliyoruz
      const instantBookingData = {
        ...selectedRoomData,
        checkIn: dayjs(formValues.checkIn)?.format("YYYY-MM-DD"),
        checkOut: dayjs(formValues.checkOut)?.format("YYYY-MM-DD"),
      };

      // startRequest için de benzer bir yapı kullanabilirsiniz, gerekiyorsa
      const startRequestData = {
        ...selectedRoomData,
        checkIn: dayjs(formValues.checkIn)?.format("YYYY-MM-DD"),
        checkOut: dayjs(formValues.checkOut)?.format("YYYY-MM-DD"),
        currency: formValues?.currency,
        numberOfStudents: formValues?.numberOfStudents,
        occupancy: formValues?.occupancy,
        additionalNote: formValues?.additionalNote,
        predicted_checkin: dayjs(formValues?.checkIn)?.format("YYYY-MM-DD"),
        predicted_checkout: dayjs(formValues?.checkOut)?.format("YYYY-MM-DD"),
        // Buraya startRequest'a özgü diğer alanlar eklenebilir
      };

      const startQuoteData = {
        options: [
          {
            name: `Option 1`,
            courses: [
              {
                course_id: selectedRoomData?.course_id,
                campus_id: selectedRoomData?.campus_id,
                option_course_name: selectedRoomData?.course_name,
                option_course_partner: selectedRoomData?.partner_name,
                option_course_campus: selectedRoomData?.campus_name,
                option_course_start_date: dayjs(formValues?.checkIn)?.format(
                  "YYYY-MM-DD"
                ),
                option_course_end_date: dayjs(formValues?.checkOut)?.format(
                  "YYYY-MM-DD"
                ),
                option_course_course_type: selectedRoomData?.type_name,
              },
            ],
          },
        ],
        // ...selectedRoomData,
      };
      setBookingData({
        // Burada instantBookingData'nızı nasıl oluşturmak istiyorsanız o şekilde doldurun
        instantBooking: instantBookingData,
        startRequest: startRequestData,
        startQuote: startQuoteData,
        // Diğer gerekli veriler...
      });
    } else {
      // Seçili odaların sayısı 1'den farklı ise, instantBookingData'yı temizle veya başka bir güncelleme yap
      if (selectedRooms.length > 1) {
        const startRequestData = {
          rooms: selectedRooms, // Seçili odaları bir dizi olarak tutuyoruz
          formDetails: {
            // Form verilerini ayrı bir nesne içinde tutuyoruz
            currency: formValues?.currency,
            numberOfStudents: formValues?.numberOfStudents,
            occupancy: formValues?.occupancy,
            additionalNote: formValues?.additionalNote,
            predicted_checkin: dayjs(formValues?.checkIn)?.format("YYYY-MM-DD"),
            predicted_checkout: dayjs(formValues?.checkOut)?.format(
              "YYYY-MM-DD"
            ),
          },
        };

        let options = [];

        selectedRooms?.forEach((room, idx) => {
          options.push({
            name: `Option ${idx + 1}`,
            courses: [
              {
                course_id: room?.course_id,
                campus_id: room?.campus_id,
                option_course_name: room?.course_name,
                option_course_partner: room?.partner_name,
                option_course_campus: room?.campus_name,
                option_course_start_date: dayjs(formValues?.checkIn)?.format(
                  "YYYY-MM-DD"
                ),
                option_course_end_date: dayjs(formValues?.checkOut)?.format(
                  "YYYY-MM-DD"
                ),
                option_course_course_type: room?.type_name,
              },
            ],
          });
        });

        const startQuoteData = {
          options,
        };

        setBookingData({
          startRequest: startRequestData,
          startQuote: startQuoteData,
        });
      } else {
        setBookingData({});
      }
    }
  }, [selectedRooms, formValues]); // selectedRooms değiştiğinde bu useEffect tetiklenecek

  // const handleChange = (e, roomData) => {
  //   if (e.target.checked) {
  //     setSelectedRooms((prevRooms) => [...prevRooms, roomData]);
  //   } else {
  //     setSelectedRooms((prevRooms) =>
  //       prevRooms.filter((room) => room !== roomData)
  //     );
  //   }
  // };
  const handleChange = (e, roomData) => {
    if (e.target.checked) {
      setSelectedRooms((prevRooms) => [...prevRooms, roomData]);
    } else {
      setSelectedRooms((prevRooms) =>
        prevRooms.filter((room) => room.room_id !== roomData.room_id)
      );
    }
  };

  // Resize event listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 999);
    };

    // Event listener'ı ekleyin
    window.addEventListener("resize", handleResize);

    // Cleanup function olarak event listener'ı kaldırın
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Bu useEffect'i sadece bileşen mount olduğunda çalıştırmak için boş bir bağımlılık dizisi verin.
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const isRoomSelected = (room, selectedRooms) => {
    return selectedRooms.some((selectedRoom) => {
      const { room_id, ...roomProps } = room;
      const { room_id: selectedRoomId, ...selectedRoomProps } = selectedRoom;
      return JSON.stringify(roomProps) === JSON.stringify(selectedRoomProps);
    });
  };

  return (
    <Box
      sx={{
        marginTop: "4rem",
        boxShadow: lightMode && "3px 3px 5px #dedede",
        padding: "1.25rem",
        background: lightMode
          ? data?.course_promotion_name
            ? "#0080001c"
            : "#f7f7f7"
          : data?.course_promotion_name
          ? "#0080001c"
          : "#292929",
        display: "flex",
        position: "relative",
        paddingBottom: { xs: "10rem", xl: "1.25rem" },
        flexDirection: { xs: "column", md: "row" },
        borderRadius: "1rem",
      }}
      key={idx}
    >
      <img
        src={
          data?.course_image
            ? data?.course_image.replace("devcrm", "devcrm1")
            : roomImg2
        }
        style={{
          width: isMobile ? "100%" : "220px",
          height: "220px",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
      {/* room objesindeki özelliklere göre içeriği güncelleyin */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: { xs: "1rem", md: "0" },
        }}
      >
        <Box
          sx={{
            marginLeft: { xs: "0", md: "2rem" },
            marginTop: "auto",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h5"
              sx={{ fontSize: "27px", lineHeight: "32px" }}
              color={"primary"}
            >
              {data.course_name}
            </Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}
            >
              <BedroomChildIcon
                sx={{ color: lightMode && "#bf0000", fontSize: "48px" }}
              />

              <Typography
                variant="h5"
                sx={{ fontSize: "27px", lineHeight: "32px" }}
                color={"primary"}
              >
                {data?.count > 20 ? 20 : data?.count}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontSize: "18px", lineHeight: "20px" }}
            >
              {data?.partner_name}
            </Typography>
            <Typography
              sx={{
                marginLeft: { xs: "0", md: "1rem" },
                marginRight: "1rem",
                marginTop: { xs: ".5rem", md: "0" },
                marginBottom: { xs: ".5rem", md: "0" },
                fontSize: "18px",
                lineHeight: "20px",
              }}
              variant="h6"
            >
              {data?.campus_name}
            </Typography>

            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "18px",
                lineHeight: "20px",
              }}
              variant="h6"
            >
              <PlaceIcon
                sx={{
                  position: "relative",
                  top: "-3px",
                  color: `#bf0000`,
                }}
              />
              {data?.campus_address}
            </Typography>
          </Box>

          <Box>
            <Box
              sx={{
                display: "flex",
                marginTop: { xs: ".5rem", md: "0" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "24px",
                  minWidth: { xs: "100px", md: "150px" },
                  width: { xs: "100px", md: "150px" },
                }}
                variant="h6"
                color={"primary"}
              >
                Type
              </Typography>

              <Typography
                sx={{
                  marginLeft: "1rem",
                  lineHeight: "24px",
                  fontSize: "18px",
                }}
                variant="h6"
              >
                {data?.type_name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: { xs: ".5rem", md: "0" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "24px",
                  minWidth: { xs: "100px", md: "150px" },
                  width: { xs: "100px", md: "150px" },
                }}
                variant="h6"
                color={"primary"}
              >
                Check/In
              </Typography>

              <Typography
                sx={{
                  marginLeft: "1rem",
                  lineHeight: "24px",
                  fontSize: "18px",
                }}
                variant="h6"
              >
                {formValues?.checkIn?.format("YYYY-MM-DD")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: { xs: ".5rem", md: "0" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "24px",
                  minWidth: { xs: "100px", md: "150px" },
                  width: { xs: "100px", md: "150px" },
                }}
                variant="h6"
                color={"primary"}
              >
                Duration
              </Typography>

              <Typography
                sx={{
                  marginLeft: "1rem",
                  lineHeight: "24px",
                  fontSize: "18px",
                }}
                variant="h6"
              >
                {data?.duration < 7
                  ? data?.duration > 1
                    ? data?.duration + ` days`
                    : data?.duration + ` day`
                  : data?.duration % 7 === 0
                  ? data?.duration / 7 === 1
                    ? data?.duration / 7 + ` week`
                    : data?.duration / 7 + ` weeks`
                  : Math.floor(data?.duration / 7) +
                    ` week${
                      Math.floor(data?.duration / 7) === 1 ? `` : `s`
                    } and ` +
                    (data?.duration % 7) +
                    ` day${data?.duration % 7 === 1 ? `` : `s`} `}
                {/* {data ? (data.duration / 7).toFixed(2) : `-`} week/s */}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: { xs: ".5rem", md: "0" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "24px",
                  minWidth: { xs: "100px", md: "150px" },
                  width: { xs: "100px", md: "150px" },
                }}
                variant="h6"
                color={"primary"}
              >
                Weekly
              </Typography>

              <Typography
                sx={{
                  marginLeft: "1rem",
                  lineHeight: "24px",
                  fontSize: "18px",
                }}
                variant="h6"
              >
                {data ? data?.price?.toFixed(2) : `-`} GBP/Week
              </Typography>
            </Box>
            <Button
              href={`/residences/${data?.campus_id}`}
              sx={{
                position: { xs: "absolute", xl: "relative" },
                bottom: { xs: "108px", xl: "0" },
                marginTop: { xs: 0, xl: "1rem" },
              }}
              variant="contained"
            >
              See Residence
            </Button>

            {/* <Box
              sx={{
                display: "flex",
                marginTop: { xs: ".5rem", md: "0" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "24px",
                  minWidth: { xs: "100px", md: "150px" },
                  width: { xs: "100px", md: "150px" },
                }}
                variant="h6"
                color={"primary"}
              >
                Dual Occupancy
              </Typography>

              <Typography
                sx={{
                  marginLeft: "1rem",
                  lineHeight: "24px",
                  fontSize: "18px",
                }}
                variant="h6"
              >
                50.00 GBP/Week
              </Typography>
            </Box> */}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            marginLeft: "auto",
            minHeight: "100%",
          }}
        >
          <Checkbox
            // checked={selectedRooms.includes(data)}
            checked={isRoomSelected(data, selectedRooms)}
            onChange={(e) => handleChange(e, data)}
            sx={{ padding: "0" }}
            {...label}
          />{" "}
          <Box
            sx={{
              position: "absolute",
              bottom: "1rem",
              left: { xs: "1.25rem", md: "unset" },
              maxWidth: { xs: "calc(100% - 40px)", md: "unset" },
            }}
          >
            {data?.course_promotion_discount_amount ? (
              <Typography
                sx={{
                  marginLeft: "auto",
                  marginTop: "auto",
                  width: "max-content",
                  color: "green",
                }}
                variant="h6"
              >
                {data?.course_promotion_discount_type === "fixed"
                  ? `${
                      ((data?.duration / 7) * data?.price).toFixed(2) -
                      data?.course_promotion_discount_amount
                    } GBP`
                  : `${(
                      (data?.duration / 7) * data?.price -
                      (data?.duration / 7) *
                        data?.price *
                        (data?.course_promotion_discount_amount / 100)
                    ).toFixed(2)} GBP`}
                {/* {((data?.duration / 7) * data?.discounted_price).toFixed(2)} GBP */}
              </Typography>
            ) : null}

            <Typography
              sx={{
                marginLeft: "auto",
                marginTop: "auto",
                width: "max-content",
                textDecoration: data?.course_promotion_discount_amount
                  ? "line-through"
                  : "none",
              }}
              variant="h6"
            >
              {data?.course_promotion_discount_type === "fixed"
                ? `${((data?.duration / 7) * data?.price).toFixed(2)} GBP`
                : `${((data?.duration / 7) * data?.price).toFixed(2)} GBP`}

              {/* {((data?.duration / 7) * data?.price).toFixed(2)} GBP */}
            </Typography>
            {data?.course_promotion_name ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "auto",
                  width: { xs: "fit-content", xl: "max-content" },
                  background: "#008b0087",
                  padding: ".25rem .75rem",
                  borderRadius: "2rem",
                }}
              >
                <LocalOfferIcon
                  sx={{
                    position: "relative",
                    top: "3px",
                    color: "#ffffff",
                    marginRight: ".25rem",
                    fontSize: "16px",
                  }}
                />
                <Typography
                  sx={{
                    color: "#ffffff",
                  }}
                  variant="body1"
                >
                  {data?.course_promotion_discount_type === "fixed"
                    ? `${data?.course_promotion_name} (${data?.course_promotion_discount_amount} GBP Discount)`
                    : `${data?.course_promotion_name} (%${data?.course_promotion_discount_amount} Discount)`}

                  {/* {data?.course_promotion_name} (%{data?.course_promotion_discount_amount} Discount) */}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
