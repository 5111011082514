import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../axiosInstance";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

export default function ButtonsDesktop({
  mobileCon,
  selectedRooms,
  setSelectedRooms,
  bookingData,
  setBookingData,
  setLoaderStart,
}) {
  const navigate = useNavigate();
  const instantBooking = bookingData ? bookingData.instantBooking : undefined;
  const startRequest = bookingData ? bookingData.startRequest : undefined;
  const startQuote = bookingData ? bookingData.startQuote : undefined;
  // const [loader, setLoader] = useState();

  const handleInstantBooking = () => {
    navigate("/instant-booking", { state: { instantBooking } });
  };
  const handleMyRequest = () => {
    navigate("/start-request", { state: { startRequest } });
  };

  const handleStartQuote = async () => {
    setLoaderStart(true);
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    let url = `${process.env.REACT_APP_BASE_URL}quotes`;

    // Önce formValues'dan 'price' değerlerini kaldır
    // const modifiedFormValues = {
    //   ...startQuote,
    //   options: startQuote.options.map((option) => ({
    //     ...option,
    //     accommodations: option.accommodations.map((accommodation) => ({
    //       ...accommodation,
    //       courses: accommodation.courses.map((course) => {
    //         const { price, campus_address, ...restOfCourse } = course; // 'price' değerini çıkart
    //         return restOfCourse;
    //       }),
    //     })),
    //   })),
    // };

    try {
      const response = await axiosInstance.post(url, startQuote, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });
      setLoaderStart(true);
      Swal.fire({
        icon: "success",
        title: "Quote Added Successfully!",
        showConfirmButton: false,
        showCloseButton: true,
      });
      navigate(`/quotes/${response?.data?.quote_id}/details`, {
        quoteDetails: {},
      });
    } catch (error) {
      setLoaderStart(false);
      console.error("Quote Eklenirken Hata oluştu!", error);
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.detail,
        showConfirmButton: false,
        showCloseButton: true,
      });
      return [];
    }
  };
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const handleOpenModal = () => {
    // Dispatch an action to open the modal, with the FilterModal component as the payload
    // dispatch(openModal(<ComingSoonModal />));
    Swal.fire({
      icon: "info",
      title: "Instant Booking is Temporary Unavailable!",
      showConfirmButton: false,
      showCloseButton: true,
    });
  };
  return (
    <>
      {/* {loader ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
          }}
        />
      ) : null} */}
      <Button
        variant="contained"
        color="primary"
        disabled={!selectedRooms.length}
        onClick={() => setSelectedRooms([])}
        sx={{
          display: "block",
          minHeight: "40px",
          marginBottom: mobileCon ? "1rem" : "0",
          width: mobileCon ? "100%" : "160px",
        }}
      >
        Clear Selections
      </Button>
      <Button
        onClick={handleStartQuote}
        variant="contained"
        color="info"
        sx={{
          display: "block",
          minHeight: "40px",
          marginLeft: mobileCon ? "0" : "1rem",
          marginBottom: mobileCon ? "1rem" : "0",
          width: mobileCon ? "100%" : "160px",
        }}
      >
        Start Quote
      </Button>
      {selectedRooms.length >= 1 && (
        <>
          {selectedRooms.length === 1 && (
            <Button
              onClick={handleInstantBooking}
              variant="contained"
              color="primary"
              // onClick={() => handleOpenModal()}
              sx={{
                display: "block",
                textAlign: "center",
                minHeight: "40px",
                marginLeft: mobileCon ? "0" : "1rem",
                width: mobileCon ? "100%" : "160px",
                // "&:hover": {
                //   color: "#b0b0b0",
                //   background: lightMode ? "#d0d0d0" : "#3b3a3a",
                // },
                // color: "#b0b0b0",
                // background: lightMode ? "#d0d0d0" : "#404040",
                // cursor: "pointer",
                // pointerEvents: "auto",
              }}
            >
              Instant Booking
            </Button>
          )}
          <Button
            onClick={handleMyRequest}
            variant="contained"
            color="primary"
            sx={{
              display: "block",
              textAlign: "center",
              minHeight: "40px",
              marginTop: mobileCon ? "1rem" : "0",
              marginLeft: mobileCon ? "0" : "1rem",
              width: mobileCon ? "100%" : "160px",
            }}
          >
            Start Request
          </Button>
        </>
      )}
    </>
  );
}
