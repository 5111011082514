import {
  Box,
  Card,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import conversionJSON from "./conversion.json";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { countryIdentificationControl } from "./countryIdentificationControls";

import { stateCodes } from "./stateCodes";
import axiosInstance from "../../../../axiosInstance";

function ConversionItem({ type, idx, conversion, lightMode, selectedValue }) {
  // conversion["@attributes"].type
  return (
    <Grid item xs={12} sm={6} xl={4} key={idx}>
      <Card
        sx={{
          cursor:
            conversion["@attributes"].locked === "true" ||
            (type === "single" &&
              conversion["@attributes"].type === "Bank Transfer")
              ? "default"
              : "pointer",
          display: "flex",
          alignItems: "center",
          marginBottom: "2rem",
          minHeight: "200px",
          position: "relative",
          boxShadow:
            conversion["@attributes"].locked === "true" ||
            (type === "single" &&
              conversion["@attributes"].type === "Bank Transfer" &&
              "none"),
          "&:before": {
            content: '""',
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: `${lightMode ? `#efefefc2` : `#686868e6`} `,
            zIndex: 1,
            display:
              conversion["@attributes"].locked === "true" ||
              (type === "single" &&
                conversion["@attributes"].type === "Bank Transfer")
                ? "block"
                : "none",
          },

          outline:
            selectedValue === conversion["@attributes"].type
              ? `3px solid ${lightMode ? `#F08049` : `#ffffff61`} `
              : "none",
          // border:
          //   selectedValue === conversion["@attributes"].type
          //     ? `3px solid ${
          //         lightMode ? `#F08049` : `#ffffff61`
          //       } `
          //     : "none",
        }}
      >
        <FormControlLabel
          sx={{
            margin: "0",
            height: "100%",
            width: "100%",
            padding: "1rem",
            minHeight: "200px",
          }}
          key={idx}
          value={conversion["@attributes"].type}
          disabled={
            conversion["@attributes"].locked === "true"
              ? true
              : type === "single" &&
                conversion["@attributes"].type === "Bank Transfer"
              ? true
              : false
          }
          control={<Radio />}
          label={
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid #efefef",
                  marginRight: "1rem",
                  width: "75px",
                  paddingRight: "1rem",
                }}
              >
                <img
                  src={conversion["@attributes"].logo}
                  style={{
                    maxWidth: "100px",
                    width: "100%",
                    marginRight: "1rem",
                  }}
                />
              </Box>
              <Box>
                <Typography
                  variant={"h6"}
                  sx={{
                    color:
                      conversion["@attributes"].locked === "true"
                        ? "grey"
                        : type === "single" &&
                          conversion["@attributes"].type === "Bank Transfer"
                        ? "grey"
                        : "#F08049",
                    borderBottom: "1px solid #efefef",
                    marginBottom: "1rem",
                  }}
                >
                  {conversion["@attributes"].type}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ marginRight: ".25rem" }} variant={"h6"}>
                    {conversion["@attributes"]?.currency}
                  </Typography>
                  <Typography variant={"h6"}>
                    {conversion.converted_amount}
                  </Typography>
                </Box>
                {conversion["@attributes"].locked === "true" ? (
                  <Box sx={{ lineHeight: "16px" }}>
                    <Typography
                      sx={{ zIndex: 9 }}
                      color="#F08049"
                      position={"relative"}
                      variant={"caption"}
                    >
                      <strong>
                        Reason: {conversion["@attributes"].lock_reason}
                      </strong>
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </Box>
          }
        />
      </Card>
    </Grid>
  );
}
export default function TransferMate({
  totalPrice,
  countriesInit,
  setPaymentMethod,
  setCountryCode,
  setPayerDocument,
  setState,
  identificationControl,
  setIdentificationControl,
  stateCodeControl,
  setStateCodeControl,
  chineseNameControl,
  setChineseNameControl,
  setChineseName,
  selectedStudent,
  paymentType,
}) {
  const [age, setAge] = useState("");

  const [conversion, setConversion] = useState(null);
  const [conversionInit, setConversionInit] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const lightMode = true;
  const [identificationValue, setIdentificationValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState(null);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeState = (event) => {
    setSelectedState(event.target.value);
  };

  const handleChangeChineseName = (event) => {
    setChineseName(event.target.value);
  };

  const handleChangeConversion = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (selectedState) {
      setState(selectedState);
    }
  }, [selectedState]);

  useEffect(() => {
    // US , AU, CA
    if (age) {
      // setLoading(true);
      setCountryCode(age);

      const control = countryIdentificationControl?.some((item, idx) => {
        return item?.includes(age);
      });

      setIdentificationControl(control);
      if (identificationControl) {
      }
      setIdentificationValue("");
      setSelectedValue("");
      setSelectedState("");
      setPayerDocument(null);
      setPaymentMethod(null);
      setState(null);

      if (age === "US" || age === "AU" || age === "CA") {
        setStateCodeControl(true);
      } else {
        setStateCodeControl(false);
      }
      if (age === "CN") {
        setChineseNameControl(true);
      } else {
        setChineseNameControl(false);
      }
    }
  }, [age]);

  useEffect(() => {
    setIdentificationValue("");
    setSelectedValue("");
    setSelectedState("");
    setPayerDocument(null);
    setPaymentMethod(null);
    setState(null);
    setIdentificationControl(false);
    setStateCodeControl(false);
  }, []);
  useEffect(() => {
    setPayerDocument(identificationValue);
  }, [identificationValue]);

  useEffect(() => {
    // console.log(age, "age here");
  }, [age]);

  useEffect(() => {
    if (countriesInit !== undefined) {
      setCountries(countriesInit);
    }
  }, [countriesInit]);

  // const { data: conversionInit } = useSwr(
  //   API.TransferMate.Conversion.Get.create(age, totalPrice || 1200)
  // );

  const getConversions = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}payment/transfermate/conversions?country_code=${age}&amount=${totalPrice}`;
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");

    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });

      setConversion(response.data?.result?.rates?.rate);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("API isteği sırasında hata oluştu:", error);
    }
  };
  useEffect(() => {
    if (age && totalPrice) {
      getConversions();
    }
  }, [age, totalPrice]);

  // useEffect(() => {
  //   console.log(conversionInit, "conversion init");
  //   if (conversionInit) {
  //     setConversion(conversionInit?.result?.rates?.rate);
  //     setLoading(false);
  //   }
  // }, [conversionInit]);

  useEffect(() => {
    if (selectedValue) {
      if (conversion?.length) {
        const myItem = conversion?.find((item, idx) => {
          return item["@attributes"]?.type === selectedValue;
        });
        setPaymentMethod(myItem["@attributes"]?.payment_method_id);
      } else {
        setPaymentMethod(conversion["@attributes"]?.payment_method_id);
      }
    }
  }, [selectedValue]);

  return countries ? (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: identificationControl
            ? { xs: "column", lg: "row" }
            : { xs: "column", sm: "row" },
          alignItems: "stretch",
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: identificationControl
              ? stateCodeControl || chineseNameControl
                ? { xs: "column", md: "row", lg: "column", xl: "row" }
                : { xs: "column", sm: "row", xl: "row" }
              : stateCodeControl || chineseNameControl
              ? { xs: "column", md: "row" }
              : { xs: "column", xl: "row" },
            padding: "1rem",
            alignItems: "flex-start",
            marginRight: { xs: "0", sm: "2rem" },
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              width: identificationControl
                ? stateCodeControl || chineseNameControl
                  ? { xs: "100%", xl: "50%" }
                  : { xs: "100%", xl: "300px" }
                : stateCodeControl
                ? "100%"
                : { xs: "100%", sm: "300px" },
            }}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                width: {
                  xs: "100%",
                  md:
                    stateCodeControl || chineseNameControl
                      ? identificationControl
                        ? "50%"
                        : "300px"
                      : "100%",
                  // md: identificationControl ? "fit-content" : "100%",
                },
              }}
            >
              <FormControl
                fullWidth
                disabled={
                  paymentType !== "invoice" &&
                  paymentType !== "booking" &&
                  !selectedStudent
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Payment Country
                </InputLabel>
                <Select
                  sx={{
                    minWidth: { xs: "100%", md: "150px" },
                    width:
                      identificationControl && !stateCodeControl
                        ? { xs: "100%", md: "100%" }
                        : { xs: "100%", md: "100%" },
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age || "Choose Payment Country"}
                  label="Payment Country"
                  onChange={handleChange}
                  defaultValue={"Choose Payment Country"}
                >
                  <MenuItem disabled value="Choose Payment Country">
                    Choose Payment Country
                  </MenuItem>
                  {countries?.result?.countries?.country?.length
                    ? countries?.result?.countries?.country?.map(
                        (country, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              value={country["@attributes"]?.code}
                            >
                              {country?.name}
                            </MenuItem>
                          );
                        }
                      )
                    : null}
                </Select>
              </FormControl>
            </Box>
            {stateCodeControl ? (
              <Box
                fullWidth
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: identificationControl
                    ? { xs: "1rem", sm: "0", xl: "auto" }
                    : { xs: "1rem", sm: "auto" },
                  marginLeft: { xs: "0", sm: "1rem", xl: "1rem" },
                  width: {
                    xs: "100%",
                    md: stateCodeControl
                      ? identificationControl
                        ? "50%"
                        : "300px"
                      : "100%",
                    // md: identificationControl ? "fit-content" : "100%",
                  },
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    sx={{
                      minWidth: { xs: "100%", md: "150px" },
                      width: identificationControl
                        ? { xs: "100%", md: "100%" }
                        : { xs: "100%", md: "300px" },
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedState || "Choose Country State"}
                    label="Choose Country"
                    onChange={handleChangeState}
                    defaultValue={"Choose Country State"}
                  >
                    <MenuItem disabled value="Choose Country State">
                      Choose Country State
                    </MenuItem>
                    {stateCodes?.length
                      ? stateCodes
                          .filter((state) => {
                            if (age === "AU")
                              return state.country_name === "Australia";
                            if (age === "US")
                              return (
                                state.country_name ===
                                "United States Of America"
                              );
                            if (age === "CA")
                              return state.country_name === "Canada";
                            return false;
                          })
                          ?.map((state, idx) => {
                            return (
                              <MenuItem key={idx} value={state?.code}>
                                {state?.state_name}
                              </MenuItem>
                            );
                          })
                      : null}
                  </Select>
                </FormControl>
              </Box>
            ) : null}
            {chineseNameControl ? (
              <Box
                fullWidth
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: identificationControl
                    ? { xs: "1rem", sm: "0", xl: "auto" }
                    : { xs: "1rem", sm: "auto" },
                  marginLeft: { xs: "0", sm: "1rem", xl: "1rem" },
                  width: {
                    xs: "100%",
                    md: chineseNameControl
                      ? identificationControl
                        ? "50%"
                        : "300px"
                      : "100%",
                    // md: identificationControl ? "fit-content" : "100%",
                  },
                }}
              >
                <TextField
                  onChange={handleChangeChineseName}
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                />
              </Box>
            ) : null}
          </Box>
          {identificationControl ? (
            <Box
              sx={{
                display: "flex",
                width:
                  stateCodeControl || chineseNameControl
                    ? { xs: "100%", xl: "50%" }
                    : "100%",
                // marginTop: { xs: "1rem", md: "auto" },
                marginTop: identificationControl
                  ? stateCodeControl || chineseNameControl
                    ? { xs: "1rem", md: "0", lg: "1rem", xl: "auto" }
                    : { xs: "1rem", sm: "0", xl: "auto" }
                  : { xs: "1rem", md: "auto" },
                marginLeft:
                  stateCodeControl || chineseNameControl
                    ? { xs: "0", sm: "1rem", lg: "0", xl: "1rem" }
                    : { xs: "0", sm: "1rem", lg: "1rem", xl: "1rem" },
                // marginLeft: { xs: "0", md: "1rem" },
              }}
            >
              <TextField
                fullWidth
                value={identificationValue}
                onChange={(e) => setIdentificationValue(e.target.value)}
                label="Identification Number (Required for some countries)"
                id="fullWidth"
              />
            </Box>
          ) : null}
        </Card>
      </Box>

      {!loading ? (
        conversion ? (
          <Box
            sx={{ marginTop: "3rem", display: "flex", flexDirection: "column" }}
          >
            <FormControl component="fieldset">
              <FormLabel
                sx={{
                  fontSize: "24px",
                  borderBottom: "1px solid #efefef",
                  paddingBottom: ".5rem",
                  marginBottom: "2rem",
                }}
                component="legend"
              >
                Select Payment Method
              </FormLabel>
              <RadioGroup
                value={selectedValue}
                onChange={handleChangeConversion}
              >
                <Grid container spacing={2}>
                  {conversion?.length ? (
                    <>
                      {/* {paymentMethods
                .filter((paymentMethod) =>
                  bookingId ? paymentMethod.name !== "transfermate" : true
                )
                .map((paymentMethod, idx) => { */}
                      {conversion
                        .filter(
                          (conversion) =>
                            conversion["@attributes"].type !== "Bank Transfer"
                        )
                        .map((conversion, idx) => (
                          <ConversionItem
                            type={"multiple"}
                            idx={idx}
                            conversion={conversion}
                            lightMode={lightMode}
                            selectedValue={selectedValue}
                          />
                        ))}
                    </>
                  ) : (
                    <ConversionItem
                      type={"single"}
                      conversion={conversion}
                      lightMode={lightMode}
                      selectedValue={selectedValue}
                    />
                  )}
                </Grid>
              </RadioGroup>
            </FormControl>
          </Box>
        ) : null
      ) : (
        <CircularProgress />
      )}
    </Box>
  ) : (
    <CircularProgress />
  );
}
